import {GET_FAQS, GET_FAQS_SUCCESS, GET_FAQS_FAIL, RESET_FAQS } from "../actions/faqs"

const initialState = {
  faqs: {
    data: [],
    loading: false,
    error: null,
    success: false
  }
}

const faqsReducer = (state = initialState, action) => {
  switch(action.type) {
    case GET_FAQS:
      return {
        ...state,
        faqs: {
          ...state.faqs,
          loading: true,
          success: false,
          error: null
        }
      }
    case GET_FAQS_SUCCESS:
      return {
        ...state,
        faqs: {
          ...state.faqs,
          data: action.payload?.data || [],
          loading: false,
          success: true,
          error: null
        }
      }
    case GET_FAQS_FAIL:
      return {
        ...state,
        faqs: {
          ...state.faqs,
          loading: false,
          success: false,
          error: action.payload
        }
      }
    case RESET_FAQS:
      return {
        ...state,
        faqs: {
          ...state.faqs,
          loading: false,
          success: false,
          error: null
        }
      }
    default:
      return state;
  }
}

export default faqsReducer;