import React, { useEffect, useState } from "react";
import Banner from "../components/Banner/Banner";
import Navbar from "../components/Custom/Navbar";
import { makeStyles } from "@mui/styles";
import Usecase from "../components/Usecase/Usecase";
import FAQ from "../components/Custom/FAQ";
import Testimonial from "../components/Custom/Testimonial";
import Footer from "../components/Custom/Footer";
import BlogSection from "../components/Blog/BlogSection";
import TableData from "../components/TableData";
import MobileAppPromotion from "../components/Custom/MobileAppPromotion";
import OfferingProducts from "../components/Custom/OfferingProducts";
import { bindActionCreators } from "redux";
import * as FaqsActions from "../redux/actions/faqs";
import * as BlogsActions from "../redux/actions/blogs";
import * as TestimonialsActions from "../redux/actions/testimonials";
import { connect } from "react-redux";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    padding: 0,
    border:"none",
  },
  bannerContainer: {
    background: "#ffffff",
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.primary,
  },
  usecaseContainer: {
    background: "#ffffff",
    textAlign: "center",
    position: "relative",
    color: theme.palette.text.primary,
  },
  promotionContainer: {
    marginTop: "30px",
    marginBottom: "100px",
    margin: "auto",
    width: "70vw",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      margin: 0,
      width: "100%",
    },
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      margin: 0,
      width: "100%",
    },
  },
}));
const Home = ({
  getFaqs,
  getTestimonials,
  getBlogs,
  faqs,
  testimonials,
  blogs,
  isDepositClicked,
  setIsDepositClicked,
}) => {
  const classes = useStyles();

  const [faqsData, setFaqsData] = useState([]);
  const [blogsData, setBlogsData] = useState([]);
  const [testimonialsData, setTestimonialsData] = useState([]);

  // call all the neccessary apis
  useEffect(() => {
    getFaqs();
    getTestimonials();
    getBlogs();
  }, []);

  useEffect(() => {
    if (faqs?.success) {
      setFaqsData(faqs?.data);
    }

    if (blogs?.success) {
      setBlogsData(blogs?.data);
    }

    if (testimonials?.success) {
      const chunkSize = 3;
      const data = testimonials?.data.reduce((result, item, index) => {
        const chunkIndex = Math.floor(index / chunkSize);
        if (!result[chunkIndex]) {
          result[chunkIndex] = []; // Start a new chunk
        }
        result[chunkIndex].push(item);
        return result;
      }, []);

      setTestimonialsData(data);
    }
  }, [
    faqs?.success,
    faqs?.error,
    blogs?.success,
    blogs?.error,
    testimonials?.success,
    testimonials?.error,
  ]);

  return (
    <div className={classes.mainContainer} id="home">
      <div className={classes.bannerContainer}>
        <Banner setIsDepositClicked={setIsDepositClicked} />
      </div>
      <div>
        <OfferingProducts isDepositClicked={isDepositClicked} />
      </div>
      <div className={classes.usecaseContainer} id="calculators">
        <Usecase />
      </div>
      <div style={{ marginBottom: "30px" }}>
        <Testimonial testimonials={testimonialsData} />
      </div>
      <div
        style={{ marginTop: "10px", marginBottom: "50px", padding: "0px 10%" }}
      >
        <FAQ faqs={faqsData} />
      </div>
      <div style={{ marginTop: "30px", marginBottom: "50px" }} id="blogs">
        <BlogSection all={false} blogs={blogsData} />
      </div>
      <div className={classes.promotionContainer}>
        <MobileAppPromotion />
      </div>
    </div>
  );
};

Home.propTypes = {
  faqs: PropTypes.object.isRequired,
  getFaqs: PropTypes.func.isRequired,
  getTestimonials: PropTypes.func.isRequired,
  getBlogs: PropTypes.func.isRequired,
};

const mapStateToProps = ({
  faqs: { faqs },
  testimonials: { testimonials },
  blogs: { blogs },
}) => {
  return {
    faqs,
    testimonials,
    blogs,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getFaqs: bindActionCreators(FaqsActions.getFaqs, dispatch),
  getTestimonials: bindActionCreators(
    TestimonialsActions.getTestimonials,
    dispatch
  ),
  getBlogs: bindActionCreators(BlogsActions.getBlogs, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
