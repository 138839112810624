import React, { useEffect, useState } from "react";
import {
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Box,
  IconButton,
  Modal,
  Grid,
  useMediaQuery,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import * as XLSX from "xlsx";
import DownloadIcon from "@mui/icons-material/Download";
import CloseIcon from "@mui/icons-material/Close";

const useStyles = makeStyles((theme) => ({
  reportContainer: {
    marginTop: "40px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
  },
  report: {
    background: "#ffffff",
    width: "80vw",
    padding: "30px",
    maxWidth: "100%",
    margin: "auto",
    maxHeight: "90vh",
    borderRadius: "8px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      width: "95vw",
      padding: "15px",
    },
  },
  tableContainer: {
    maxHeight: "70vh",
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      width: "8px",
    },
    "&::-webkit-scrollbar-track": {
      background: "#f1f1f1",
      borderRadius: "8px",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#1976d2",
      borderRadius: "8px",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      background: "#115293",
    },
  },
  tableHeader: {
    backgroundColor: "#1976d2",
  },
  tableCell: {
    fontWeight: "bold",
    color: "#000",
  },
  reportTitle: {
    color: "#FF5722",
    fontWeight: "bold",
    textAlign: "center",
    fontSize: "1.5rem",
    margin: "1.5rem 0",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.2rem",
    },
  },
  icon: {
    padding: "4px",
    fontSize: "2.5rem",
    borderRadius: "50%",
    color: "#fff",
    backgroundColor: "blue",
    "&:hover": {
      backgroundColor: "#FF5722",
      color: "#fff",
    },
  },
  closeIcon: {
    cursor: "pointer",
    color: "#ff0000",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    outline: 0,
  },
  modalBackdrop: {
    backdropFilter: "blur(5px)",
  },
  flexHead: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  
  downloadName:{
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}));

const MonthlyInvestmentReport = ({
  productType,
  principal,
  monthlyDeposit,
  interestRate,
  depositMonths,
  setIsModalOpen,
}) => {
  const classes = useStyles();
  const [reportData, setReportData] = useState([]);
  const [open, setOpen] = useState(true);
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));

  useEffect(() => {
    const calculateReportData = () => {
      let data = [];
      const monthlyInterestRate = interestRate / (12 * 100);
      const startDate = new Date();
      let currentPrincipal = parseFloat(principal).toFixed(2);
      let fixedPrinciple = currentPrincipal;
      let opening = 0;
      let totalInterestEarnedForRD = 0;
      let monthlyAmountForDD = (principal * 365) / 12;
      let monthlyInterestForDD = monthlyAmountForDD * monthlyInterestRate;
      let cumulativeInterestForDD = 0;
      let closingBalanceForDD = 0;
      let maturityAmount = 0;
      let totalCummulativeInterest = 0;
      let totalPrincipleForDD = 0;

      for (let month = 1; month <= depositMonths; month++) {
        if (
          productType === "Fixed Deposit" ||
          productType == "Monthly Income Scheme"
        ) {
          let interestEarned = (fixedPrinciple * monthlyInterestRate).toFixed(2);

          let totalPayment = (parseFloat(currentPrincipal) + parseFloat(interestEarned)).toFixed(2);
          const reportDate = new Date(startDate);

          reportDate.setMonth(startDate.getMonth() + month - 1);

          data.push({
            month,
            date: reportDate.toLocaleDateString(),
            principal: parseFloat(fixedPrinciple).toFixed(2),
            interestEarned: interestEarned,
            totalPayment: month === depositMonths ? totalPayment : parseFloat(fixedPrinciple).toFixed(2),
          });

          currentPrincipal = (parseFloat(currentPrincipal) + parseFloat(interestEarned)).toFixed(2);
        } else if (productType === "Recurring Deposit") {
          let RD = parseFloat(monthlyDeposit).toFixed(2);
          let totalPayment = (parseFloat(opening) + parseFloat(RD)).toFixed(2);
          let interestEarned = (totalPayment * monthlyInterestRate).toFixed(2);

          const reportDate = new Date(startDate);
          reportDate.setMonth(startDate.getMonth() + month - 1);

          data.push({
            month,
            opening: parseFloat(opening).toFixed(2),
            date: reportDate.toLocaleDateString(),
            principal: RD,
            interestEarned: interestEarned,
            totalPayment: month === depositMonths ? (parseFloat(totalPayment) + parseFloat(totalInterestEarnedForRD)).toFixed(2) : totalPayment,
          });

          opening = (parseFloat(opening) + parseFloat(monthlyDeposit)).toFixed(2);
          totalInterestEarnedForRD += parseFloat(interestEarned);
        } else if (productType === "Daily Deposit") {
          cumulativeInterestForDD = (parseFloat(cumulativeInterestForDD) + parseFloat(monthlyInterestForDD)).toFixed(2);
          totalCummulativeInterest = (parseFloat(totalCummulativeInterest) + parseFloat(cumulativeInterestForDD)).toFixed(2);
          totalPrincipleForDD = (parseFloat(totalPrincipleForDD) + parseFloat(monthlyAmountForDD)).toFixed(2);

          const reportDate = new Date(startDate);
          reportDate.setMonth(startDate.getMonth() + month - 1);

          if (month === depositMonths) {
            maturityAmount = (parseFloat(cumulativeInterestForDD) + parseFloat(totalPrincipleForDD)).toFixed(2);
          }

          let monthlyDepositAndInterest = (parseFloat(monthlyAmountForDD) + parseFloat(monthlyInterestForDD)).toFixed(2);
          closingBalanceForDD = (parseFloat(closingBalanceForDD) + parseFloat(monthlyDepositAndInterest)).toFixed(2);

          data.push({
            month,
            monthlyDeposit: parseFloat(monthlyAmountForDD).toFixed(2),
            date: reportDate.toLocaleDateString(),
            maturityAmount: parseFloat(maturityAmount).toFixed(2),
            monthlyInterest: parseFloat(monthlyInterestForDD).toFixed(2),
            cummulativeInterest: parseFloat(cumulativeInterestForDD).toFixed(2),
            closingBalance: parseFloat(closingBalanceForDD).toFixed(2),
          });
        }
        setReportData(data);
      }
    };

    calculateReportData();
  }, [principal, monthlyDeposit, interestRate, depositMonths, productType]);

  const downloadReport = () => {
    const ws = XLSX.utils.json_to_sheet(reportData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Investment Report");
    XLSX.writeFile(wb, "Investment_Report.xlsx");
  };

  return (
    <Modal
      open={open}
      onClose={() => setIsModalOpen(false)}
      className={classes.modal}
      BackdropProps={{ className: classes.modalBackdrop }}
    >
      <Container maxWidth="100%" className={classes.reportContainer}>
        <Box className={classes.report}>
          <Grid container className={classes.flexHead}>
            <Grid item xs={4}>
              <IconButton
                className={classes.downloadButton}
                onClick={downloadReport}
              >
                <Typography variant="h6" className={classes.downloadName} fontWeight={"bold"}>
                  Download
                </Typography>
                &nbsp;
                <DownloadIcon className={classes.icon} />
              </IconButton>
            </Grid>
            <Grid item             xs={4} 
            style={{ textAlign: "center" }}>
              <Typography variant={isMobile ? "h6" : "h5"} className={classes.reportTitle}>
                {productType}
              </Typography>
            </Grid>
            <Grid item xs={4} style={{ textAlign: "right" }}>
              <IconButton
                className={classes.closeIcon}
                onClick={() => setIsModalOpen(false)}
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
          <TableContainer
            component={Paper}
            className={classes.tableContainer}
          >
            <Table stickyHeader>
              <TableHead className={classes.tableHeader}>
                <TableRow>
                  <TableCell
                    className={classes.tableCell}
                    style={{ textAlign: "center" }}
                  >
                    Month
                  </TableCell>
                  <TableCell
                    className={classes.tableCell}
                    style={{ textAlign: "center" }}
                  >
                    Date
                  </TableCell>
                  {productType === "Daily Deposit" ? (
                    <>
                      <TableCell
                        className={classes.tableCell}
                        style={{ textAlign: "center" }}
                      >
                        Monthly Deposit
                      </TableCell>
                      <TableCell
                        className={classes.tableCell}
                        style={{ textAlign: "center" }}
                      >
                        Interest Earned (₹)
                      </TableCell>
                      <TableCell
                        className={classes.tableCell}
                        style={{ textAlign: "center" }}
                      >
                        Cummulative Interest
                      </TableCell>
                      <TableCell
                        className={classes.tableCell}
                        style={{ textAlign: "center" }}
                      >
                        Closing Balance
                      </TableCell>
                      <TableCell
                        className={classes.tableCell}
                        style={{ textAlign: "center" }}
                      >
                        Maturity Amount
                      </TableCell>
                    </>
                  ) : (
                    <>
                      {productType === "Recurring Deposit" && (
                        <TableCell
                          className={classes.tableCell}
                          style={{ textAlign: "center" }}
                        >
                          Opening
                        </TableCell>
                      )}
                      <TableCell
                        className={classes.tableCell}
                        style={{ textAlign: "center" }}
                      >
                        {productType === "Recurring Deposit"
                          ? "RD"
                          : "Principal (₹)"}
                      </TableCell>
                      <TableCell
                        className={classes.tableCell}
                        style={{ textAlign: "center" }}
                      >
                        Interest Earned (₹)
                      </TableCell>
                      <TableCell
                        className={classes.tableCell}
                        style={{ textAlign: "center" }}
                      >
                        Total Amount (₹)
                      </TableCell>
                    </>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {reportData.map((row) => (
                  <TableRow key={row.month}>
                    <TableCell style={{ textAlign: "center" }}>
                      {row.month}
                    </TableCell>
                    <TableCell style={{ textAlign: "center" }}>
                      {row.date}
                    </TableCell>
                    {productType === "Recurring Deposit" && (
                      <TableCell style={{ textAlign: "center" }}>
                        {parseFloat(row.opening).toFixed(2)}
                      </TableCell>
                    )}
                    {productType === "Daily Deposit" ? (
                      <>
                        <TableCell style={{ textAlign: "center" }}>
                          {parseFloat(row.monthlyDeposit).toFixed(2)}
                        </TableCell>
                        <TableCell style={{ textAlign: "center" }}>
                          {parseFloat(row.monthlyInterest).toFixed(2)}
                        </TableCell>
                        <TableCell style={{ textAlign: "center" }}>
                          {parseFloat(row.cummulativeInterest).toFixed(2)}
                        </TableCell>
                        <TableCell style={{ textAlign: "center" }}>
                          {parseFloat(row.closingBalance).toFixed(2)}
                        </TableCell>
                        <TableCell style={{ textAlign: "center" }}>
                          {parseFloat(row.maturityAmount).toFixed(2)}
                        </TableCell>
                      </>
                    ) : (
                      <>
                        <TableCell style={{ textAlign: "center" }}>
                          {parseFloat(row.principal).toFixed(2)}
                        </TableCell>
                        <TableCell style={{ textAlign: "center" }}>
                          {parseFloat(row.interestEarned).toFixed(2)}
                        </TableCell>
                        <TableCell style={{ textAlign: "center" }}>
                          {parseFloat(row.totalPayment).toFixed(2)}
                        </TableCell>
                      </>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Container>
    </Modal>
  );
};

export default MonthlyInvestmentReport;

