export const GET_TESTIMONIALS = 'GET_TESTIMONIALS'
export const GET_TESTIMONIALS_SUCCESS = 'GET_TESTIMONIALS_SUCCESS'
export const GET_TESTIMONIALS_FAIL = 'GET_TESTIMONIALS_FAIL'
export const RESET_TESTIMONIALS = 'RESET_TESTIMONIALS'

export const getTestimonials = (payload) => ({
  type: GET_TESTIMONIALS,
  payload
})

export const getTestimonialsSuccess = (data) => ({
  type: GET_TESTIMONIALS_SUCCESS,
  paylaod: data
})

export const getTestimonialsFail = (error) => ({
  type: GET_TESTIMONIALS_FAIL,
  payload: error
})

export const resetTestimonials = () => ({
  type: RESET_TESTIMONIALS
})