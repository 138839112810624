import { ADMIN_LOGIN, ADMIN_LOGIN_FAIL, ADMIN_LOGIN_SUCCESS, LOGIN_WITH_OTP, LOGIN_WITH_OTP_FAIL, LOGIN_WITH_OTP_SUCCESS, LOGOUT, RESET_AUTH, VERIFY_OTP, VERIFY_OTP_FAIL, VERIFY_OTP_SUCCESS } from "../actions/auth"

const initialState = {
  auth: {
    data: [],
    loading: false,
    error: null,
    success: false
  }
}

const authReducer = (state = initialState, action) => {
  switch(action.type) {
    case LOGIN_WITH_OTP:
      return {
        ...state,
        auth: {
          ...state.auth,
          loading: true,
          success: false,
          error: null
        }
      }
    case LOGIN_WITH_OTP_SUCCESS:
      return {
        ...state,
        auth: {
          ...state.auth,
          ...action.payload,
          loading: false,
          success: true,
          error: null
        }
      }
    case LOGIN_WITH_OTP_FAIL:
      return {
        ...state,
        auth: {
          ...state.auth,
          loading: false,
          success: false,
          error: action.payload
        }
      }
    case VERIFY_OTP:
      return {
        ...state,
        auth: {
          ...state.auth,
          loading: true,
          success: false,
          error: null
        }
      }
    case VERIFY_OTP_SUCCESS:
      return {
        ...state,
        auth: {
          ...state.auth,
          data: {...state.auth.data, ...action.payload?.data, otp_verfied: true},
          loading: false,
          success: true,
          error: null
        }
      }
    case VERIFY_OTP_FAIL:
      return {
        ...state,
        auth: {
          ...state.auth,
          data: {...state.auth.data, otp_verfied: false},
          loading: false,
          success: false,
          error: action.payload
        }
      }
    case RESET_AUTH:
      return {
        ...state,
        auth: {
          ...state.auth,
          loading: false,
          error: null,
          success: false
        }
      }
    case LOGOUT:
      return {
        ...state,
        auth: {
          data: [],
          loading: false,
          error: null,
          success: false
        }
      }
      case ADMIN_LOGIN:
        return {
          ...state,
          auth: {
            ...state.auth,
            loading: true,
            success: false,
            error: null,
          },
        };
      case ADMIN_LOGIN_SUCCESS:
        return {
          ...state,
          auth: {
            ...state.auth,
            data: action.payload?.data,
            loading: false,
            success: true,
            error: null,
          },
        };
      case ADMIN_LOGIN_FAIL:
        return {
          ...state,
          auth: {
            ...state.auth,
            loading: false,
            success: false,
            error: action.payload,
          },
        };
    default:
      return state;
  }
}

export default authReducer;