import axios from "axios";
import store from "./redux/store"; // Import your Redux store

// const BASE_URL = "http://localhost:5000/api";
const BASE_URL = "https://finlandhub.com/api";
// const BASE_URL = "https://aabf-2402-a00-405-b07a-2d7f-fdb4-4ece-674d.ngrok-free.app/api";

// Function to get the token from the Redux state
export const getToken = () => {
  return localStorage.getItem("token");
};

const doPost = async (endpoint, data, options = {}) => {
  try {
    const token = getToken();
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }
    const response = await axios.post(`${BASE_URL}/${endpoint}`, data, options);
    return response.data;
  } catch (error) {
    throw error.response?.data || error;
  }
};

const doGet = async (endpoint, options = {}) => {
  try {
    const token = getToken();
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }
    const response = await axios.get(`${BASE_URL}/${endpoint}`, options);
    return response.data;
  } catch (error) {
    throw error.response?.data || error;
  }
};

const doUpdate = async (endpoint, data, options = {}) => {
  try {
    const token = getToken();
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }
    const response = await axios.put(`${BASE_URL}/${endpoint}`, data, options);
    return response.data;
  } catch (error) {
    throw error.response?.data || error;
  }
};

const doDelete = async (endpoint, options = {}) => {
  try {
    const token = getToken();
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }
    const response = await axios.delete(`${BASE_URL}/${endpoint}`, options);
    return response.data;
  } catch (error) {
    throw error.response?.data || error;
  }
};

export { doPost, doGet, doUpdate, doDelete };
