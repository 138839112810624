import React, { useMemo, useState, useEffect } from "react";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";

import {
  Box,
  Button,
  IconButton,
  Tooltip,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  TextField,
} from "@mui/material";
import {
  QueryClientProvider,
  useMutation,
  QueryClient,
  useQueryClient,
} from "@tanstack/react-query";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { doDelete, doGet, doUpdate } from "../request"; // Import doGet and doUpdate for API calls
import { useNavigate } from "react-router-dom"; // Import useNavigate
import toast from "react-hot-toast";

const fetchUsers = async ({ limit, offset, search }) => {
  const response = await doGet(
    `/user/?limit=${limit}&offset=${offset}&search=${search}`
  );

  return {
    users: response.data.users.map((user) => ({
      id: user.id,
      memberId: user.memberId,
      name: user.name,
      surname: user.surname,
      fatherName: user.fatherName,
      maritalStatus: user.maritalStatus,
      dob: user.dob,
      gender: user.gender,
      countryCode: user.countryCode,
      phoneNumber: user.phoneNumber,
      email: user.email,
      profession: user.profession,
      panNumber: user.panNumber,
      aadharNumber: user.aadharNumber,
      occupation: user.occupation,
      status: user.status || "pending",
    })),
    totalCount: response.data.count, // Ensure your API returns totalCount
  };
};

const TableComponent = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate(); // Initialize useNavigate
  const [searchText, setSearchText] = useState("");
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 5 });
  const [users, setUsers] = useState([]);
  const [totalCount, setTotalCount] = useState(0); // To store total count of users
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const loadUsers = async () => {
    setIsLoading(true);
    setIsError(false);
    try {
      const response = await fetchUsers({
        limit: pagination.pageSize,
        offset: pagination.pageIndex * pagination.pageSize,
        search: searchText,
      });
      setUsers(response.users);
      setTotalCount(response.totalCount);
    } catch (error) {
      if (error?.statusCode == 403) {
        navigate("/admin/login");
        window.scrollTo({ top: 0, behavior: "smooth" });
      } else if (error?.statusCode == 400) {
        toast.error(error.message, {
          duraion: 4000,
        });
      }
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadUsers();
  }, [pagination, searchText]);

  const { mutateAsync: updateUser } = useMutation({
    mutationFn: async (user) => {
      if (["approved", "rejected"].includes(user.status)) {
        await doUpdate(`/user/status`, {
          status: user.status,
          userId: user.id,
        });
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries("users");
      loadUsers();
    },
  });

  const { mutateAsync: deleteUser } = useMutation({
    mutationFn: async (userId) => {
      await doDelete(`/user/${userId}`, { method: "DELETE" }); // API call to delete user
    },
    onSuccess: () => {
      queryClient.invalidateQueries("users");
      loadUsers();
    },
  });

  const openDeleteConfirmModal = (row) => {
    if (window.confirm("Are you sure you want to delete this user?")) {
      deleteUser(row.original.id);
    }
  };

  const handleChangeStatus = async (user, status) => {
    await updateUser({ ...user, status });
  };

  const handleSearchChange = (event) => {
    setSearchText(event.target.value);
  };

  const columns = useMemo(
    () => [
      { accessorKey: "id", header: "ID", enableEditing: false, size: 80 },
      { accessorKey: "memberId", header: "Member ID" },
      { accessorKey: "name", header: "Name" },
      { accessorKey: "surname", header: "Surname" },
      { accessorKey: "fatherName", header: "Father Name" },
      { accessorKey: "maritalStatus", header: "Marital Status" },
      { accessorKey: "dob", header: "Date of Birth" },
      { accessorKey: "gender", header: "Gender" },
      { accessorKey: "countryCode", header: "Country Code" },
      { accessorKey: "phoneNumber", header: "Phone Number" },
      { accessorKey: "email", header: "Email" },
      { accessorKey: "profession", header: "Profession" },
      { accessorKey: "panNumber", header: "PAN Number" },
      { accessorKey: "aadharNumber", header: "Aadhar Number" },
      { accessorKey: "occupation", header: "Occupation" },
      {
        accessorKey: "status",
        header: "Status",
        Cell: ({ cell }) => (
          <FormControl fullWidth>
            <InputLabel>Status</InputLabel>
            <Select
              value={cell.row.original.status}
              label="Status"
              onChange={(e) =>
                handleChangeStatus(cell.row.original, e.target.value)
              }
            >
              <MenuItem value="pending">Pending</MenuItem>
              <MenuItem value="approved">Approved</MenuItem>
              <MenuItem value="rejected">Rejected</MenuItem>
            </Select>
          </FormControl>
        ),
      },
    ],
    []
  );

  const table = useMaterialReactTable({
    columns,
    data: users || [],
    createDisplayMode: "modal",
    editDisplayMode: "modal",
    enableEditing: true,
    getRowId: (row) => row.id,
    muiToolbarAlertBannerProps: isError
      ? { color: "error", children: "Error loading data" }
      : undefined,
    muiTableContainerProps: {
      sx: { minHeight: "500px" },
    },
    renderRowActions: ({ row, table }) => (
      <Box sx={{ display: "flex", gap: "1rem" }}>
        <Tooltip title="Delete">
          <IconButton color="error" onClick={() => openDeleteConfirmModal(row)}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="View">
          <IconButton
            onClick={() =>{
              navigate(`/admin/dashboard/profile/${row.original.id}`) 
              window.scrollTo({ top: 0, behavior: "smooth" });}
            }
          >
            <VisibilityIcon />
          </IconButton>
        </Tooltip>
      </Box>
    ),
    renderTopToolbarCustomActions: () => (
      <Box sx={{ display: "flex", gap: "1rem", padding: "20px" }}>
        <TextField
          label="Search"
          variant="outlined"
          value={searchText}
          onChange={handleSearchChange}
          sx={{ marginRight: "20px" }}
        />
        <Button
          sx={{ fontSize: "0.8rem", height: "40px" }}
          variant="outlined"
          onClick={() => {
            window.scrollTo({ top: 0, behavior: "smooth" });
            navigate("/admin/user/create");
          }}
        >
          Create New User
        </Button>
      </Box>
    ),
    manualPagination: true,
    onPaginationChange: setPagination,
    rowCount: totalCount,
    state: {
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isLoading,
    },
  });

  return <MaterialReactTable table={table} />;
};

const queryClient = new QueryClient();

const TableComponentWithProviders = () => (
  <QueryClientProvider client={queryClient}>
    <Box padding={"30px"}>
      <TableComponent />
    </Box>
  </QueryClientProvider>
);

export default TableComponentWithProviders;
