import {GET_BLOGS, GET_BLOGS_SUCCESS, GET_BLOGS_FAIL, RESET_BLOGS } from "../actions/blogs"

const initialState = {
  blogs: {
    data: [],
    loading: false,
    error: null,
    success: false
  }
}

const blogsReducer = (state = initialState, action) => {
  switch(action.type) {
    case GET_BLOGS:
      return {
        ...state,
        blogs: {
          ...state.blogs,
          loading: true,
          success: false,
          error: null
        }
      }
    case GET_BLOGS_SUCCESS:
      return {
        ...state,
        blogs: {
          ...state.blogs,
          data: action.payload?.data,
          loading: false,
          success: true,
          error: null
        }
      }
    case GET_BLOGS_FAIL:
      return {
        ...state,
        blogs: {
          ...state.blogs,
          loading: false,
          success: false,
          error: action.payload
        }
      }
    case RESET_BLOGS:
      return {
        ...state,
        blogs: {
          ...state.blogs,
          loading: false,
          success: false,
          error: null
        }
      }
    default:
      return state;
  }
}

export default blogsReducer;