import {takeEvery, put, call} from "redux-saga/effects"
import {  doGet, doPost } from '../../request'
import {all} from "redux-saga/effects";
import { GET_LEADS, GET_LEADS_FAIL, GET_LEADS_SUCCESS } from "../actions/leads";
import toast from "react-hot-toast";

function* getLeads({payload}) {
  try {
    const url = `leads?type=${payload?.product}&offset=${payload?.offset}&limit=${payload?.limit}&search=${payload?.search ? payload.search : ""}`
    const response = yield call(doGet, url)
    yield put({ type: GET_LEADS_SUCCESS, payload: response })
  } catch (error) {
    
    if(error?.statusCode == 403){
      window.location.href = "/admin/login";
    }else if(error?.statusCode == 400){
        toast.error(error.message, {
          duraion: 4000
        }); 
    }
    yield put({ type: GET_LEADS_FAIL, payload: error })
  }
}

export default function* leadsWatcher() {
  yield all([
    takeEvery(GET_LEADS, getLeads),
  ]);
}
