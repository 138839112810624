import { margin } from "@mui/system";
import React from "react";

const images = {
  "Current Account": "/banner/Current Account.png",
  "Saving Account": "/banner/Saving Account.png",
  "Fixed Deposit": "/banner/Fixed Deposit.png",
  "Daily Deposit": "/banner/Daily Deposit.png",
  "Recurring Deposit": "/banner/Recurring Deposit.png",
  "Monthly Income Scheme": "/banner/Monthly Income Scheme.svg",
  "Loan Against Deposit": "/banner/Loan Against Deposit.jpg",
  "Gold Loan": "/banner/Gold Loan.jpg",
  "Business Loan": "/banner/Business Loan.svg",
  "Vehicle Loan": "/banner/Vehicle Loan.svg",
  "Personal Loan": "/banner/Personal Loan.svg",
};

const styles = {
  
  "Current Account": {
    height: "100%",
    width: "100%",
  },
  "Saving Account": {
    height: "100%",
    width: "100%",
  },
  "Fixed Deposit": {
    height: "100%",
    width: "100%",
  },
  "Daily Deposit": {
          height: "100%",
          width: "100%",
   },
  "Recurring Deposit": {
          height: "100%",
          width: "100%",
  },
  "Monthly Income Scheme": {
          height: "100%",
          width: "100%",
          objectFit: "cover",
          objectPosition: "70% 30%"
        },
  "Loan Against Deposit": {
          height: "100%",
          width: "100%",
          objectFit: "cover",
        },
  "Gold Loan": {
          height: "100%",
          width: "100%",
          objectFit: "cover",
          objectPosition: "55% 55%"
        },
  "Business Loan": {
          height: "100%",
          width: "100%",
          objectFit: "cover",
          objectPosition: "bottom"
        },
  "Vehicle Loan": {
          height: "100%",
          width: "100%",
          objectFit: "cover",
          objectPosition: "25% 75%"
        },
  "Personal Loan": {
          height: "100%",
          width: "100%",
          objectFit: "cover",
        },
}

const ProductDetailBanner = ({ title }) => {
  const imageUrl = images[title] || "/banner/default-banner.png"; // Fallback image if title not found

  return (
      <img
        src={imageUrl}
        style={styles[title]}
        alt={`${title} Banner`}
      />
  );
};

export default ProductDetailBanner;
