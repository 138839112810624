import {takeEvery, put, call} from "redux-saga/effects"
import { doDelete, doGet, doPost } from '../../request'
import {all} from "redux-saga/effects";
import { GET_BLOGS, GET_BLOGS_FAIL, GET_BLOGS_SUCCESS } from "../actions/blogs";

function* getBlogs() {
  try {
    const url = 'blogs'
    const response = yield call(doGet, url)
    yield put({ type: GET_BLOGS_SUCCESS, payload: response })
  } catch (error) {
    yield put({ type: GET_BLOGS_FAIL, payload: error })
  }
}

export default function* blogsWatcher() {
  yield all([
    takeEvery(GET_BLOGS, getBlogs),
  ]);
}
