import { Box, TextField, Typography, FormHelperText } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import LoadMoreButton from "../components/Custom/LoadMoreButton";
import * as AuthActions from "../redux/actions/auth";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    padding: "16px",
    boxSizing: "border-box",
  },
  formContainer: {
    width: "100%",
    maxWidth: "400px",
    padding: "32px",
    borderRadius: "12px",
    border: "1px solid #0561AB",
    boxShadow: theme.shadows[10],
    backgroundColor: "#fff",
    [theme.breakpoints.down("sm")]: {
      padding: "24px",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "16px",
    },
  },
  title: {
    textAlign: "center",
    fontWeight: "bold",
    fontSize: "1.5rem",
    marginBottom: theme.spacing(10),
    color: "#0561AB",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.2rem",
      marginBottom: theme.spacing(8),
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "1rem",
      marginBottom: theme.spacing(6),
    },
  },
  form: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
  },
  otpContainer: {
    display: "flex",
    justifyContent: "space-between",
    gap: theme.spacing(1),
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
  },
  otpInput: {
    width: "48px",
    height: "48px",
    textAlign: "center",
    fontSize: "20px",
    borderRadius: "8px",
    border: "1px solid grey",
    outline: "none",
    [theme.breakpoints.down("sm")]: {
      width: "40px",
      height: "40px",
      fontSize: "18px",
    },
    [theme.breakpoints.down("xs")]: {
      width: "32px",
      height: "32px",
      fontSize: "16px",
    },
  },
  link: {
    textAlign: "center",
    marginTop: theme.spacing(1),
  },
  sendOtpButton: {
    marginTop: theme.spacing(2),
  },
  btnContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  errorText: {
    color: theme.palette.error.main,
  },
}));

const LoginWithOTP = ({
  auth,
  loginWithOtp,
  resetAuth,
  verifyOtp,
  setIsLoggedIn,
}) => {
  const classes = useStyles();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [otp, setOtp] = useState(new Array(6).fill(""));
  const [otpSent, setOtpSent] = useState(false);
  const [errors, setErrors] = useState({
    phoneNumber: "",
    otp: "",
  });

  const navigate = useNavigate();

  const handlePhoneNumberChange = (e) => {
    const value = e.target.value;
    if (/^\d{0,10}$/.test(value)) {
      setPhoneNumber(value);
      setErrors((prev) => ({ ...prev, phoneNumber: "" }));
    }
  };

  const handleOtpChange = (e, index) => {
    const { target } = e;
    const { value } = target;
  
    // Ensure the entered value is a digit
    if (/^\d$/.test(value)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);
  
      // Automatically move to the next input field
      if (index < 5 && value !== "") {
        document.getElementById(`otp-input-${index + 1}`).focus();
      }
    } else if (value === "") {
      // Clear input when deleting the content
      const newOtp = [...otp];
      newOtp[index] = "";
      setOtp(newOtp);
    }
  };
const handleKeyDown = (e, index) => {
  if (e.key === "Backspace") {
    // Clear the current input and move to the previous one
    if (otp[index] === "" && index > 0) {
      document.getElementById(`otp-input-${index - 1}`).focus();
    }
  }
};

  const validatePhoneNumber = () => {
    if (phoneNumber.length < 10) {
      setErrors((prev) => ({ ...prev, phoneNumber: "Phone number must be 10 digits" }));
      return false;
    }
    return true;
  };

  const validateOtp = () => {
    if (otp.some((digit) => digit === "")) {
      setErrors((prev) => ({ ...prev, otp: "Please enter all OTP digits" }));
      return false;
    }
    return true;
  };

  const handleSendOtp = () => {
    if (validatePhoneNumber()) {
      loginWithOtp({ phoneNumber });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateOtp()) {
      verifyOtp({ phoneNumber, otp: otp.join("") });
    }
  };

  useEffect(() => {
    if (auth?.success) {
      localStorage.setItem("token", auth?.data?.token);
      localStorage.setItem("role", auth?.data?.role);
      setOtpSent(true);
    }

    if (auth?.error) {
      console.log(auth?.error);
      // Handle error here
    }

    resetAuth();

    if (auth?.data?.otp_verfied) {
      localStorage.setItem("otp_verified", "true");
      localStorage.setItem("role", auth?.data.role);
      setIsLoggedIn(true);
      window.scrollTo({ top: 0, behavior: "smooth" });
      navigate("/");
    }
  }, [auth?.success, auth?.error, auth?.data?.otp_verfied]);

  useEffect(() => {
    const otpVerified = localStorage.getItem("otp_verified");
    if (otpVerified === "true") {
      navigate("/");
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [navigate]);

  return (
    <Box className={classes.root}>
      <Box className={classes.formContainer}>
        <p className={classes.title}>Login / Register</p>
        <form className={classes.form} onSubmit={handleSubmit}>
          <TextField
            variant="outlined"
            label="Phone Number"
            type="tel"
            fullWidth
            required
            value={phoneNumber}
            onChange={handlePhoneNumberChange}
            inputProps={{
              maxLength: 10,
              pattern: "\\d*",
            }}
            InputProps={{
              startAdornment: <span style={{ marginRight: 8 }}>+91</span>,
            }}
            error={!!errors.phoneNumber}
            helperText={errors.phoneNumber}
          />
          <Box className={classes.otpContainer}>
          {otp?.map((data, index) => (
            <input
              key={index}
              type="text"
              id={`otp-input-${index}`}
              name="otp"
              maxLength="1"
              className={classes.otpInput}
              value={otp[index]}
              onChange={(e) => handleOtpChange(e, index)}
              onKeyDown={(e) => handleKeyDown(e, index)}
              disabled={!otpSent}
            />
          ))}
          </Box>
          <FormHelperText className={classes.errorText}>{errors.otp}</FormHelperText>
          <Box className={classes.btnContainer}>
            {!otpSent ? (
              <LoadMoreButton name="Send OTP" onClick={handleSendOtp} />
            ) : (
              <LoadMoreButton name="Sign in" onClick={handleSubmit} />
            )}
          </Box>
        </form>
      </Box>
    </Box>
  );
};

LoginWithOTP.propTypes = {
  auth: PropTypes.object.isRequired,
  loginWithOtp: PropTypes.func.isRequired,
  resetAuth: PropTypes.func.isRequired,
  verifyOtp: PropTypes.func.isRequired,
  setIsLoggedIn: PropTypes.func.isRequired,
};

const mapStateToProps = ({ auth: { auth } }) => {
  return {
    auth,
  };
};

const mapDispatchToProps = (dispatch) => ({
  loginWithOtp: bindActionCreators(AuthActions.loginWithOtp, dispatch),
  resetAuth: bindActionCreators(AuthActions.resetAuth, dispatch),
  verifyOtp: bindActionCreators(AuthActions.verifyOtp, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginWithOTP);
