import { Box } from "@mui/material";
import React from "react";

const Logo = ({ onClick }) => {
  return (
    <Box
      sx={{
        display: { xs: "none", md: "flex" },
        alignItems: "center",
        mr: 2,
        cursor: "pointer",
      }}
      onClick={onClick}
    >
      <img
        src="/logo.png"
        alt="logo"
        style={{ height: "50px"}}
      />
      {/* <Typography
        variant="h6"
        noWrap
        component="a"
        href="#app-bar-with-responsive-menu"
        sx={{
          fontFamily: "monospace",
          fontWeight: 700,
          color: "inherit",
          textDecoration: "none",
        }}
      >
        <span style={{ color: "#0561ab" }}>FIN</span>
        <span style={{ color: "#333132" }}>LAND</span>
        <sup style={{ color: "#333132", fontSize: "10px", letterSpacing: 0 }}>
          TM
        </sup>
      </Typography> */}
    </Box>
  );
};

export default Logo;
