import React from "react";
import { CardContent, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import ButtonOrangeShadow from "../Custom/ButtonOrangeShadow";
import LoadMoreButton from "../Custom/LoadMoreButton";

const useStyles = makeStyles((theme) => ({
  bannerWrapper: {
    position: "relative",
    height: "100%",
    overflow: "hidden",
  },
  backgroundImage: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    objectFit: "cover",
    opacity: 0.3,
    zIndex: 1,
  },
  mainContainer: {
    color: theme.palette.text.primary,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    minHeight: "75vh",
    width: "100%",
    position: "relative",
    zIndex: 2,
    "@media (max-width: 1200px)": {
      width: "100%",
    },
    "@media (max-width: 500px)": {
      width: "100%",
    },
  },
  card: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    maxWidth: "800px",
    borderRadius: theme.shape.borderRadius,
    overflow: "hidden",
    "@media (min-width: 800px)": {
      flexDirection: "row",
    },
  },
  imageContainer: {
    position: "relative",
    width: "100%",
    height: "45vh",
    overflow: "hidden",
    marginBottom: theme.spacing(3),
    "@media (max-width: 1200px)": {
      height: "40vh",
    },
    "@media (max-width: 500px)": {
      height: "30vh",
    },
  },
  img: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    objectPosition: "center",
    opacity: 1,
  },
  buttonContainer: {
    display: "flex",
    gap: theme.spacing(5),
    justifyContent: "center",
    alignItems: "center",
    padding: theme.spacing(2),
    flexWrap: "wrap",
    "@media (max-width: 500px)": {
      gap: theme.spacing(2),
      padding: theme.spacing(1),
    },
  },
}));

const InvestmentLoanCard = ({ setIsDepositClicked }) => {
  const classes = useStyles();

  const handleImageLoad = (e) => {
    e.target.classList.add("loaded");
  };

  const handleClick = (isDeposit) => {
    setIsDepositClicked(isDeposit);
    const element = document.getElementById("offeringProduct");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div className={classes.bannerWrapper}>
      <img
        src="bannerPattern2.png"
        alt="Background"
        className={classes.backgroundImage}
      />
      <div className={classes.mainContainer}>
        <Box className={classes.card}>
          <CardContent>
            <Box className={classes.imageContainer}>
              <img
                className={classes.img}
                src="banner-removebg.png"
                alt="Investment Loan"
                onLoad={handleImageLoad}
              />
            </Box>
          </CardContent>
        </Box>
        <div className={classes.buttonContainer}>
          <ButtonOrangeShadow
            name={"Start Deposit"}
            color={"#0561AB"}
            handleClick={() => handleClick(true)}
          />
          <LoadMoreButton
            name={"Apply Loan →"}
            onClick={() => handleClick(false)}
          />
        </div>
      </div>
    </div>
  );
};

export default InvestmentLoanCard;
