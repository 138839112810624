import React from "react";
import { makeStyles } from "@mui/styles";
import Button from "@mui/material/Button";

const useStyles = makeStyles({
  button: {
    appearance: "none",
    border: "none",
    background: "none",
    color: "#0f1923",
    cursor: "pointer",
    position: "relative",
    padding: "8px",
    marginBottom: "20px",
    textTransform: "uppercase",
    fontWeight: "bold",
    fontSize: "14px",
    transition: "all .15s ease",
    "&::before, &::after": {
      content: '""',
      display: "block",
      position: "absolute",
      right: 0,
      left: 0,
      height: "calc(50% - 5px)",
      border: "1px solid #7D8082",
      transition: "all .15s ease",
    },
    "&::before": {
      top: 0,
      borderBottomWidth: 0,
    },
    "&::after": {
      bottom: 0,
      borderTopWidth: 0,
    },
    "&:active, &:focus": {
      outline: "none",
    },
    "&:active::before, &:active::after": {
      right: "3px",
      left: "3px",
    },
    "&:active::before": {
      top: "3px",
    },
    "&:active::after": {
      bottom: "3px",
    },
    "&:hover": {
      color: "#0f1923",
      "& $buttonSl": {
        width: "calc(100% + 15px)",
      },
      "& $buttonLg::after": {
        backgroundColor: "#fff",
      },
    },
  },
  buttonLg: {
    position: "relative",
    display: "block",
    padding: "10px 20px",
    color: "#fff",
    backgroundColor: "#0561AB",
    overflow: "hidden",
    boxShadow: "inset 0px 0px 0px 1px transparent",
    "&::before": {
      content: '""',
      display: "block",
      position: "absolute",
      top: 0,
      left: 0,
      width: "2px",
      height: "2px",
      backgroundColor: "#0f1923",
    },
    "&::after": {
      content: '""',
      display: "block",
      position: "absolute",
      right: 0,
      bottom: 0,
      width: "4px",
      height: "4px",
      backgroundColor: "#0f1923",
      transition: "all .2s ease",
    },
  },
  buttonSl: {
    display: "block",
    position: "absolute",
    top: 0,
    bottom: "-1px",
    left: "-8px",
    width: 0,
    backgroundColor: "#ff4655",
    transform: "skew(-15deg)",
    transition: "all .2s ease",
  },
  buttonText: {
    position: "relative",
  },
});

const CustomButton = ({ name, onClick }) => {
  const classes = useStyles();

  return (
    <Button className={classes.button} onClick={onClick}>
      <span className={classes.buttonLg}>
        <span className={classes.buttonSl}></span>
        <span className={classes.buttonText}>{name}</span>
      </span>
    </Button>
  );
};

export default CustomButton;
