import React, { useEffect, useState } from "react";
import { Box, Button, TextField, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import ButtonBlue from "./ButtonBlue";
import toast from 'react-hot-toast';
import { doPost } from "../../request";

const useStyles = makeStyles((theme) => ({
  container: {
    color: theme.palette.text.primary,
    display: "flex",
    padding: "10px 30px",
    backgroundColor: "#ffffff",
    justifyContent: "flex-end",
    width: "50vw",
    flexDirection: "column",
    gap: 3,
    boxShadow: "0px 7px 19px 0px rgba(222,213,206,1)",
    "@media (max-width: 500px)": {
      width: "90vw",
    },
    "@media (max-width: 1200px)": {
      width: "90vw",
    },
    position: "relative",
  },
  bannerHeader: {
    position: "absolute",
    top: -20,
    left: "50%",
    transform: "translateX(-50%)",
    padding: 10,
    borderRadius: 10,
    backgroundColor: "#FF683E",
    color: "#ffffff",
  },
  bannerHeaderTitle: {
    fontFamily: "sans-serif",
    fontSize: "14px",
    fontWeight: "bold",
  },
  sectionOne: {
    display: "flex",
    marginTop: 50,
    justifyContent: "center",
    gap: 20,
    alignItems: "center",
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column !important',
      gap: 10,
    }
  },
  sectionTwo: {
    display: "flex",
    justifyContent: "center",
    gap: 50,
    alignItems: "center",
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column !important',
      gap: 10,
    }
  },
  sectionTwoInnerContainer: {
    display: "flex",
    gap: 10,
    alignItems: "center",
  },
  sectionThree: {
    display: "flex",
    gap: 10,
    marginTop: 20,
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column !important',
      gap: 10,
    }
  },
  description: {
    flex: 2,
  }
}));

const CTA = ({ productDetailData, category,title }) => {
  const classes = useStyles();
  const [formData, setFormData] = useState({
    productId: productDetailData?.id,
    name: '',
    phoneNumber: '',
    email: '',
  });

  useEffect(() => {
    setFormData({ ...formData, productId: productDetailData?.id });
  }, [productDetailData]);

  const handleInputChange = (e) => {
    const { id, value } = e.target;

    if (id === "phoneNumber") {
      const phoneNumber = value.replace(/\D/g, '').slice(0, 10);  // Allow only digits, limit to 10 characters
      setFormData({ ...formData, phoneNumber });
    } else if (id === "email") {
      setFormData({ ...formData, email: value });
    } else {
      setFormData({ ...formData, [id]: value });
    }
  };

  const handleSubmit = async () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (formData.phoneNumber.length !== 10) {
      toast.error("Please enter a valid 10-digit mobile number.", {
        duration: 4000,
      });
      return;
    }

    if (!emailRegex.test(formData.email)) {
      toast.error("Please enter a valid email address.", {
        duration: 4000,
      });
      return;
    }

    const payload = {
      productId: formData.productId,
      name: formData.name,
      phoneNumber: formData.phoneNumber,
      email: formData.email,
    };

    try {
      const response = await doPost('/leads', payload);
      if (response.status) {
        toast.success(response.message, {
          duration: 4000
        });
      } else {
        toast.error(response.message, {
          duration: 4000
        });
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <Box className={classes.container}>
      <div className={classes.bannerHeader}>
        <p className={classes.bannerHeaderTitle}>{category === "investment" ? "Open" : "Apply"} {productDetailData?.title} Online</p>
      </div>
      <div className={classes.sectionOne}>
        <TextField
          required
          id="phoneNumber"
          label="Mobile Number"
          size="small"
          sx={{ width: { xs: '100%', md: 250 } }}
          value={formData.phoneNumber}
          onChange={handleInputChange}
          inputProps={{ maxLength: 10 }}
        />
        <TextField
          required
          id="name"
          label="Name"
          size="small"
          sx={{ width: { xs: '100%', md: 250 } }}
          value={formData.name}
          onChange={handleInputChange}
        />
        <TextField
          required
          id="email"
          label="Email"
          size="small"
          sx={{ width: { xs: '100%', md: 250 } }}
          value={formData.email}
          onChange={handleInputChange}
        />
      </div>
      <div className={classes.sectionTwo}>
        {/* Optional additional sections can go here */}
      </div>
      <div className={classes.sectionThree}>
        <div className={classes.description}>
          <Typography style={{ fontSize: "12px", color: "grey" }}>
            By continuing, you agree to the Terms and Conditions of The Finland Co Operative 
            Credit Society Ltd. Our representatives to Call/Email/SMS/WhatsApp
            you regarding your application.
          </Typography>
        </div>
        <Button onClick={handleSubmit}>
          <ButtonBlue name={["Current Account","Saving Account"].includes(title) ? "Open Now" :  category === "investment" ? "Invest Now" : "Apply Now"} />
        </Button>
      </div>
    </Box>
  );
};

export default CTA;
