import React from "react";
import PropTypes from "prop-types";
import { Tabs, Tab, Box, Button, Select, MenuItem } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  tabsContainer: {
    borderRadius: "20px",
    overflow: "hidden",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  tabs: {
    backgroundColor: "#ffffff",
    "& .MuiTabs-indicator": {
      backgroundColor: "transparent",
    },
    [theme.breakpoints.down('lg')]: {
      display: "none !important"
    }
  },
  buttonTabs: {
    [theme.breakpoints.up('lg')]: {
      display: "none !important"
    }
  },
  tab: {
    borderRadius: "20px",
    backgroundColor: "#ffffff",
    color: "#000000",
    transition: "all 0.3s ease-in-out",
    "&:hover": {
      backgroundColor: "#FF683E",
      color: "#ffffff",
    },
    padding: "10px 20px",
  },
}));

const ProductDetailTabs = ({ currentTab, onTabChange, tabs }) => {
  const classes = useStyles();


  const handleTabChange = (event, newValue) => {
    onTabChange(newValue);
  };

  return (
    // <Box className={classes.tabsContainer}>
    //   </Box>
    <>
      <Tabs
        value={currentTab}
        onChange={handleTabChange}
        className={classes.tabs}
      >
        {tabs.map((tab, index) => (
          <Tab
            sx={{
              "&.Mui-selected": {
                color: "white",
                backgroundColor: "#FF683E",
              },
            }}
            
            style={{fontSize: "14px", padding: "0 30px" }}
            key={index}
            label={tab?.toUpperCase()?.split("-")?.join(" ")}
            className={classes.tab}
          />
        ))}
      </Tabs>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={currentTab}
        label="Age"
        onChange={(e) => { handleTabChange(e, e?.target?.value ?? 0) }}
        className={classes.buttonTabs}
      >
        {tabs.map((item, index) => {
          return <MenuItem value={index}>{item?.toUpperCase()?.split("-")?.join(" ")}</MenuItem>
        })}
      </Select>
    </>
  );
  {/* <div className={classes.buttonTabs}>
    {tabs.map((tab, index) => (
      <Button
        style={{
          borderRadius: "20px",
          backgroundColor: "#ffffff",
          color: "#000000",
          transition: "all 0.3s ease-in-out",
          "&:hover": {
            backgroundColor: "#FF683E",
            color: "#ffffff",
          },
          padding: "10px 20px",
        }}
        key={index}
        onClick={(e) => { handleTabChange(e, index) }}
      >{tab}</Button>
    ))}
  </div> */}

};

ProductDetailTabs.propTypes = {
  currentTab: PropTypes.number.isRequired,
  onTabChange: PropTypes.func.isRequired,
  tabs: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default ProductDetailTabs;
