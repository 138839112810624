import React, { useEffect, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import Popover from "@mui/material/Popover";
import Paper from "@mui/material/Paper";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Menu from "@mui/material/Menu";
import ButtonBlue from "./ButtonBlue";
import Logo from "./Logo";
import { useNavigate } from "react-router-dom";
import { keyframes } from "@mui/material";
import { bindActionCreators } from "redux";
import * as authActions from "../../redux/actions/auth";
import { connect } from "react-redux";

const shineAnimation = keyframes`
  from {
    background-position: 0 0;
  }
  to {
    background-position: 100% 0;
  }
`;

const pages = [
  { name: "Home", id: "home" },
  {
    name: "Deposits",
    id: "deposits",
    subtabs: [
      "Saving Account",
      "Current Account",
      "Fixed Deposit",
      "Recurring Deposit",
      "Monthly Income Scheme",
      "Daily Deposit",
    ],
  },
  {
    name: "Loans",
    id: "loans",
    subtabs: [
      "Gold Loan",
      "Vehicle Loan",
      "Loan Against Deposit",
      "Personal Loan",
      "Business Loan",
    ],
  },
  { name: "Calculators", id: "calculators" },
  {
    name: "Forms", id: "forms",
    subtabs: ["Account opening form", "Share application form", "Cash Deposit Slip", "Customer Request Form", "Deposit Opening Form", "NEFT/RTGS Form"]
  },
  { name: "Blogs", id: "blogs" },
  { name: "About Us", id: "aboutUs" },
  { name: "Contact Us", id: "contactUs" },
];

const settings = ["Account", "Home", "Logout"];
const adminSettings = ["Home","Dashboard","Leads","Enquries","Logout"];

function Navbar({ setIsDepositClicked, isLoggedIn, logout,auth, setIsLoggedIn,role }) {

  const [anchorElUser, setAnchorElUser] = useState(null);
  const [anchorElDropdown, setAnchorElDropdown] = useState(null);
  const [openDropdown, setOpenDropdown] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const [mobileOpenDropdown, setMobileOpenDropdown] = useState(null);
  const [profile,setProfile] = useState(auth.data?.profile?.document_url);
  const [name,setName] = useState(auth.data?.name);
  const navigate = useNavigate();

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = (setting) => {
    if(role === "User"){
      if (setting === settings[0]) {
        window.scrollTo({ top: 0, behavior: "smooth" });
        navigate("/profile");
      } else if (setting === settings[1]) {
        window.scrollTo({ top: 0, behavior: "smooth" });
        navigate("/");
      } else if (setting === settings[2]) {
        localStorage.removeItem("token");
        localStorage.removeItem("otp_verified");
        localStorage.removeItem("role");
        localStorage.clear()
        setIsLoggedIn(false);
        logout();
        window.scrollTo({ top: 0, behavior: "smooth" });
        navigate("/login");
      }
      setAnchorElUser(null);
    }

    if(role === "Admin"){
      if (setting === adminSettings[0]) {
        window.scrollTo({ top: 0, behavior: "smooth" });
        navigate("/");
      } else if (setting === adminSettings[1]) {
        window.scrollTo({ top: 0, behavior: "smooth" });
        navigate("/admin/dashboard");
      } 
      else if(setting === adminSettings[2]){
        window.scrollTo({ top: 0, behavior: "smooth" });
        navigate("/admin/dashboard/leads");
      }
      else if(setting === adminSettings[3]){
        window.scrollTo({ top: 0, behavior: "smooth" });
        navigate("/admin/dashboard/enquries");
      }
      else if (setting === adminSettings[4]) {
        localStorage.removeItem("token");
        localStorage.removeItem("otp_verified");
        localStorage.removeItem("role");
        localStorage.clear()
        setIsLoggedIn(false);
        logout();
        window.scrollTo({ top: 0, behavior: "smooth" });
        navigate("/admin/login");
      }
      setAnchorElUser(null);
    }
  };

  const handleTabClick = (page, subtab) => {
    if (subtab) {
      navigate(`/${subtab?.toLowerCase()?.replace(/\s/g, "-")}`);
    } else {
      if (page.name === "Home") {
        window.scrollTo({ top: 0, behavior: "smooth" });
        navigate(`/#${page.id}`);
      } else if (page.name === "Calculators" || page.name === "Blogs") {
        navigate(`/#${page.id}`);
        setTimeout(() => {
          const element = document.getElementById(page.id.toLowerCase());
          if (element) {
            element.scrollIntoView({ behavior: "smooth" });
          }
        }, 100);
      } else if (page.name === "Deposits" || page.name === "Loans") {
        window.scrollTo({ top: 0, behavior: "smooth" });
        navigate("/");
        setIsDepositClicked(page.name === "Deposits");
      } else if (page.name === "About Us") {
        navigate("/about");
        window.scrollTo({ top: 0, behavior: "smooth" });
      } else if (page.name === "Contact Us") {
        navigate("/contact");
        window.scrollTo({ top: 0, behavior: "smooth" });
      } else {
        navigate("/");
        window.scrollTo({ top: 0, behavior: "smooth" });
        const element = document.getElementById("offeringProduct");
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      }
    }
    setOpenDropdown(null);
  };

  const handleMouseEnter = (event, page) => {
    setAnchorElDropdown(event.currentTarget);
    setOpenDropdown(page.name);
  };

  const handleMouseLeave = () => {
    setAnchorElDropdown(null);
    setOpenDropdown(null);
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMobileDropdownToggle = (page) => {
    setMobileOpenDropdown(mobileOpenDropdown === page.name ? null : page.name);
  };

  useEffect(()=>{

  })

  const onFormClick = (url) => {
    const pdfUrl = url;
    const name = url.split("/").pop();
    const link = document.createElement("a");
    link.href = pdfUrl;
    link.download = name + ".pdf"; // specify the filename
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={Boolean(mobileMoreAnchorEl)}
      onClose={handleMobileMenuClose}
    >
      {pages.map((page) => (
        <div key={page.name}>
          <MenuItem
            onClick={() => {
              if (!page.subtabs) {
                handleMobileMenuClose();
                handleTabClick(page);
              } else {
                handleMobileDropdownToggle(page);
              }
            }}
          >
            <Typography textAlign="center">{page.name}</Typography>
            {page.subtabs && (
              <IconButton>
                <ExpandMoreIcon />
              </IconButton>
            )}
          </MenuItem>
          {page.subtabs && mobileOpenDropdown === page.name && (
            <div>
              {page.subtabs.map((subtab) => (
                <MenuItem
                  key={subtab}
                  onClick={() => {
                    handleMobileMenuClose();
                    page.name === "Forms" ? onFormClick(`forms/${subtab}.pdf`) : 
                    handleTabClick(page, subtab);
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    {page.name !== "Forms" && 
                    <img
                      src={`icons/${subtab}.svg`}
                      height={"25px"}
                      width={"25px"}
                      alt={subtab}
                    />
                    }
                    <Typography textAlign="center">{subtab}</Typography>
                  </div>
                </MenuItem>
              ))}
            </div>
          )}
        </div>
      ))}
    </Menu>
  );

  return (
    <AppBar
      position="static"
      sx={{
        backgroundColor: "#fff",
        color: "black",
        fontWeight: "bolder",
        boxShadow: "none",
        position: "sticky",
        top: 0,
        zIndex: 100,
      }}
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          {/* Large Screen Logo */}
          <Box
            sx={{ display: { xs: "none", md: "flex" }, mr: { xs: 1, sm: 0 } }}
          >
            <Logo
              onClick={() => {
                navigate("/#home");
              }}
            />
          </Box>

          {/* Open Mobile Menu Icon */}
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            {renderMobileMenu}
          </Box>

          {/* Mobile Screen Logo */}
          <Box
            sx={{
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              width: "150px",
            }}
          >
            <Logo />
          </Box>

          {/* Navigation Bar For large Display */}
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", md: "flex" },
              justifyContent: "center",
            }}
          >
            {pages.map((page) => (
              <div key={page.name} onMouseLeave={handleMouseLeave}>
                <Button
                  onClick={() => handleTabClick(page)}
                  onMouseEnter={(event) => handleMouseEnter(event, page)}
                  style={{ cursor: "pointer" }}
                  sx={{
                    my: 2,

                    color: "#0561AB",
                    display: "flex",
                    fontWeight: "bold",
                    "&:hover": {
                      backgroundColor: "#f1f1f1",
                      color: "#FF5252",
                    },
                    position: "relative",
                    fontSize: { xs: "13px", lg: "16px" },
                    padding: { xs: 0, md: 0.5, lg: 1 },
                  }}
                >
                  {page.name}
                  {page.subtabs && <ExpandMoreIcon sx={{ ml: 1 }} />}
                </Button>
                {page.subtabs && (
                  <Popover
                    id={`${page.id}-popover`}
                    anchorEl={anchorElDropdown}
                    open={openDropdown === page.name}
                    onClose={handleMouseLeave}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    PaperProps={{
                      style: {
                        border: "1px solid #ddd",
                        borderRadius: "10px",
                        marginTop: "10px",
                      },
                    }}
                    disableRestoreFocus
                  >
                    <Paper elevation={3}>
                      {page.subtabs.map((subtab) => (
                        <MenuItem
                          key={subtab}
                          onClick={() => {
                            page.name === "Forms" ? onFormClick(`forms/${subtab}.pdf`) : handleTabClick(page, subtab) 
                          }                            
                          }
                          sx={{
                            justifyContent: "flex-start",
                            padding: "12px 17px",
                            position: "relative",
                            cursor: "pointer",
                            overflow: "hidden",
                            "&:hover": {
                              background:
                                "linear-gradient(90deg, #f1f1f1, #ffffff, #f1f1f1)",
                              backgroundSize: "200% 100%",
                              animation: `${shineAnimation} 1.5s linear infinite`,
                              color: "#FF5252",
                            },
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              gap: "10px",
                            }}
                          >
                            {page.name !== "Forms" && 
                            <img
                              src={`icons/${subtab}.svg`}
                              height={"25px"}
                              width={"25px"}
                              alt={subtab}
                            />
                            }
                            <Typography textAlign="center">{subtab}</Typography>
                          </div>
                        </MenuItem>
                      ))}
                    </Paper>
                  </Popover>
                )}
              </div>
            ))}
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              {isLoggedIn && auth?.data?.token ? (
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  {/* to do profile image show */}
                  <img alt="" style={{height:40,width:40,objectFit:"contain", borderRadius:"50%"}} src={profile ? profile : `https://ui-avatars.com/api/?name=${name ? name : "A"}&background=0D8ABC&color=fff`} />
                </IconButton>
              ) : (
                <>
                  <ButtonBlue
                    name={"Login"}
                    style={{
                      padding: "5px 30px",
                    }}
                    onClick={() => {
                      navigate("/login");
                    }}
                  />
                </>
              )}
            </Tooltip>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {role === "User" && settings.map((setting) => (
                <MenuItem
                  key={setting}
                  onClick={() => handleCloseUserMenu(setting)}
                  sx={{ justifyContent: "center" }}
                >
                  <Typography textAlign="center">{setting}</Typography>
                </MenuItem>
              ))}

              {role === "Admin" && adminSettings.map((setting) => (
                <MenuItem
                  key={setting}
                  onClick={() => handleCloseUserMenu(setting)}
                  sx={{ justifyContent: "center" }}
                >
                  <Typography textAlign="center">{setting}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}


const mapStateToProps = ({ auth: { auth } }) => {
  return {
    auth,
  };
};

const mapDispatchToProps = (dispatch) => ({
  logout: bindActionCreators(authActions.logout, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
