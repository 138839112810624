import {
  GET_LEADS,
  GET_LEADS_SUCCESS,
  GET_LEADS_FAIL,
  RESET_LEADS,
} from "../actions/leads";

const initialState = {
  leads: {
    data: [],
    loading: false,
    error: null,
    success: false,
  },
};

const leadsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_LEADS:
      return {
        ...state,
        leads: {
          ...state.leads,
          loading: true,
          success: false,
          error: null,
        },
      };
    case GET_LEADS_SUCCESS:
      return {
        ...state,
        leads: {
          ...state.leads,
          data: action.payload?.data,
          loading: false,
          success: true,
          error: null,
        },
      };
    case GET_LEADS_FAIL:
      return {
        ...state,
        leads: {
          ...state.leads,
          loading: false,
          success: false,
          error: action.payload,
        },
      };
    case RESET_LEADS:
      return {
        ...state,
        leads: {
          data: [],
          loading: false,
          success: false,
          error: null,
        },
      };
    default:
      return state;
  }
};

export default leadsReducer;
