import React from "react";
import { makeStyles } from "@mui/styles";
import {
  Container,
  Grid,
  Button,
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DownloadAppStore from "./DownloadAppStore";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "#ffffff",
    color: "#0561AB",
    marginBottom: theme.spacing(9),
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  content: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    color: "#000",
    padding: "30px",
    "@media (max-width: 600px)": {
      padding: "20px",
    },
  },
  headline: {
    fontWeight: "bold",
    color: "#000",
    fontSize: "2rem",
    lineHeight: 1.2,
    "@media (max-width: 600px)": {
      fontSize: "1.5rem !important",
    },
  },
  description: {
    marginBottom: theme.spacing(2),
    fontSize: "1rem",
    color: "#000",
    lineHeight: 1.5,
    "@media (max-width: 600px)": {
      fontSize: "0.875rem",
    },
  },
  feature: {
    display: "flex",
    color: "#000",
    alignItems: "center",
  },
  checkIcon: {
    color: "#000",
    marginRight: theme.spacing(1),
  },
  buttons: {
    marginTop: theme.spacing(2),
    display: "flex",
    gap: theme.spacing(3),
    flexWrap: "wrap",
    "@media (max-width: 600px)": {
      gap: theme.spacing(2),
      flexDirection: "column",
    },
  },
  phoneMockup: {
    width: "70%",
    "@media (max-width: 600px)": {
      width: "100%",
    },
  },
  flexContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "@media (max-width: 960px)": {
      flexDirection: "column",
    },
  },
}));

const BankingComponent = () => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Grid
        container
        alignItems="center"
        spacing={4}
        className={classes.flexContainer}
      >
        <Grid item xs={12} md={6}>
          <div className={classes.content}>
            <Typography variant="h3" mb={4} className={classes.headline}>
              One app.
              <br />
              One banking.
            </Typography>
            <Typography variant="body1" className={classes.description}>
            Download our app from Google Play Store and experience seamless banking.
            </Typography>
            <List className={classes.features}>
              {[
                "Instant transactions",
                "Instant Account Statement",
                "24* 7 Funds transfer IMPS/RTGS/NEFT",
                "Saving accounts",
                "Utility Bills, Credit Card payments facilities",
                "Scan & Pay on QR Code",
              ].map((feature, index) => (
                <ListItem key={index} className={classes.feature}>
                  <ListItemIcon>
                    <CheckCircleIcon className={classes.checkIcon} />
                  </ListItemIcon>
                  <ListItemText primary={feature} />
                </ListItem>
              ))}
            </List>
            <div className={classes.buttons}>
              <DownloadAppStore />
            </div>
          </div>
        </Grid>
        <Grid item xs={12} display={"flex"} md={6}>
          <img
            src="promotion.webp"
            alt="Phone Mockup"
            className={classes.phoneMockup}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default BankingComponent;
