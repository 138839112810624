import {takeEvery, put, call} from "redux-saga/effects"
import {  doGet } from '../../request'
import {all} from "redux-saga/effects";
import { GET_FAQS, GET_FAQS_FAIL, GET_FAQS_SUCCESS } from "../actions/faqs";

function* getFaqs() {
  try {
    const url = 'faqs'
    const response = yield call(doGet, url)
    yield put({ type: GET_FAQS_SUCCESS, payload: response })
  } catch (error) {
    yield put({ type: GET_FAQS_FAIL, payload: error })
  }
}

export default function* faqsWatcher() {
  yield all([
    takeEvery(GET_FAQS, getFaqs),
  ]);
}
