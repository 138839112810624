export const GET_FAQS = 'GET_FAQS'
export const GET_FAQS_SUCCESS = 'GET_FAQS_SUCCESS'
export const GET_FAQS_FAIL = 'GET_FAQS_FAIL'
export const RESET_FAQS = 'RESET_FAQS'

export const getFaqs = (payload) => ({
  type: GET_FAQS,
  payload
})

export const getFaqsSuccess = (data) => ({
  type: GET_FAQS_SUCCESS,
  paylaod: data
})

export const getFaqsFail = (error) => ({
  type: GET_FAQS_FAIL,
  payload: error
})

export const resetFaqs = () => ({
  type: RESET_FAQS
})