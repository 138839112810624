import React, { useState } from 'react'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { makeStyles } from '@mui/styles';

const useStyle = makeStyles((theme) => ({
    pdfIcon: {
        display: 'block',
        [theme.breakpoints.down("lg")]: {
            display: 'none'
        }
    },
    urlIcon: {
        display: 'block',
        [theme.breakpoints.up("lg")]: {
            display: 'none'
        }
    }
}))

const ImageModel = ({ file, style }) => {
    let fileUrl = 'https://placehold.co/Image';
    try {
        fileUrl = (file != null && typeof file === "object") ? URL.createObjectURL(file) : file;
    } catch (error) {
        console.log("error :- Faild To Create Object File To URL")
    }
    const [showPdf, setShowPdf] = useState(false);
    const classes = useStyle();
    const mimeType = file?.type || file?.type;

    if (!file) return null;
    if (mimeType === "application/pdf" || fileUrl?.includes('.pdf')) {
        return (
            <>
                {showPdf && <div onClick={() => {
                    setShowPdf(false);
                }} style={{ backdropFilter: 'blur(2px)', position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, height: '100vh', width: '100vw', zIndex: 2147483647, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <embed
                        src={fileUrl}
                        type="application/pdf"
                        style={{ margin: "10px", width: '50vw', height: '100vh' }}
                    />
                </div>}
                <a className={classes.urlIcon} href={fileUrl} target='_blank'>
                    <RemoveRedEyeIcon style={{ colur: 'blue' }} />
                </a>
                <div className={classes.pdfIcon} onClick={() => { setShowPdf(true); }}>
                    <RemoveRedEyeIcon style={{ colur: 'blue' }} />
                </div>
            </>
        );
    } else {
        return (
            <>
               {showPdf && <div onClick={() => {
                    setShowPdf(false);
                }} style={{ backdropFilter: 'blur(2px)', position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, height: '100vh', width: '100vw', zIndex: 2147483647, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <img
                        src={fileUrl}
                        onClick={() => { setShowPdf(false) }}
                        alt="Document Preview"
                        style={{cursor: 'zoom-out',maxHeight:'90vh',maxWidth:'90vw' }}
                    />
                </div>}
                <img
                    src={fileUrl}
                    onClick={() => { setShowPdf(true) }}
                    alt="Document Preview"
                    style={style ?? { maxHeight: "200px", maxWidth: "200px", margin: "10px", cursor: 'pointer' }}
                />
            </>
        );
    }
}

export default ImageModel