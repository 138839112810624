import React from "react";
import Modal from "react-modal";
import MonthlyInvestmentReport from "./MonthlyInvestmentReport";
import MonthlyLoanReport from "./MonthlyLoanReport";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    width: "70%",
    maxHeight: "90vh",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.7)",
  },
};

Modal.setAppElement("#root");

const ReportModal = ({
  isOpen,
  onRequestClose,
  reportProps,
  setIsModalOpen,
  isInvestment,
}) => (
  <Modal
    isOpen={isOpen}
    onRequestClose={onRequestClose}
    style={customStyles}
    contentLabel="Monthly Investment Report"
  >
    <div style={{ position: "relative" }}>
      {isInvestment ? (
        <MonthlyInvestmentReport
          {...reportProps}
          setIsModalOpen={setIsModalOpen}
        />
      ) : (
        <MonthlyLoanReport {...reportProps} setIsModalOpen={setIsModalOpen} />
      )}
    </div>
  </Modal>
);

export default ReportModal;
