import React from "react";
import { makeStyles } from "@mui/styles";
import EligibilityCard from "../Custom/EligibilityCard";

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: "center",
    fontFamily: "sans-serif",
  },
  firstContainer: {
    margin: "30px 0",
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },
  secondContainer: {
    display: "flex",
    flexWrap: "wrap",
    gap: "20px",
    justifyContent: "center",
    margin: "40px 0",
  },
}));
const Eligibility = ({ eligibility,category,title }) => {
  const classes = useStyles();

  //   const eligibilityCards = [
  //     {
  //         iconUrl: 'investment-icon.png',
  //         name: 'Resident Individuals',
  //     },
  //     {
  //         iconUrl: 'investment-icon.png',
  //         name: 'Partnership Firms',
  //     },
  //     {
  //         iconUrl: 'investment-icon.png',
  //         name: 'Companies',
  //     },
  //     {
  //         iconUrl: 'investment-icon.png',
  //         name: 'Hindu Undivided Families (HUFs)',
  //     },
  //     {
  //         iconUrl: 'investment-icon.png',
  //         name: 'Trusts and Foundations',
  //     }
  //   ]

  return (
    <div className={classes.root}>
      <div className={classes.firstContainer}>
        <h3>{title} Eligibility</h3>
        <p>Who can {["Current Account","Saving Account"].includes(title) ? "open" : category === "investment" ? "invest" : "apply"} in a {title} with us?</p>
      </div>
      <div className={classes.secondContainer}>
        {eligibility?.map((card) => (
          <EligibilityCard iconUrl={card.iconUrl} name={card.name} />
        ))}
      </div>
    </div>
  );
};

export default Eligibility;
