import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import { Box, TextField, Typography } from "@mui/material";
import { bindActionCreators } from "redux";
import * as enquiryActions from "../redux/actions/enquiry"; // Update path if necessary
import dayjs from "dayjs";

const TableComponent = ({ getEnquiries, resetEnquiries, enquiries }) => {
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 });
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    const offset = pagination.pageIndex * pagination.pageSize;
    if (!isNaN(offset) && !isNaN(pagination.pageSize)) {
      getEnquiries({ offset, limit: pagination.pageSize, search: searchQuery });
    }
  }, [pagination.pageIndex, pagination.pageSize, getEnquiries, resetEnquiries, searchQuery]);

  const filteredEnquiries = useMemo(() => {
    return enquiries?.success ? enquiries.data.enquiries : [];
  }, [enquiries]);

  const searchedEnquiries = useMemo(() => {
    if (!searchQuery) return filteredEnquiries;
    return filteredEnquiries.filter(enquiry =>
      enquiry.firstName.toLowerCase().includes(searchQuery.toLowerCase()) ||
      enquiry.lastName.toLowerCase().includes(searchQuery.toLowerCase()) ||
      enquiry.phoneNumber.toLowerCase().includes(searchQuery.toLowerCase()) ||
      enquiry.email.toLowerCase().includes(searchQuery.toLowerCase()) ||
      enquiry.message?.toLowerCase().includes(searchQuery.toLowerCase())
    );
  }, [filteredEnquiries, searchQuery]);

  const columns = useMemo(() => [
    { accessorKey: "firstName", header: "First Name" },
    { accessorKey: "lastName", header: "Last Name" },
    { accessorKey: "phoneNumber", header: "Phone Number" },
    { accessorKey: "email", header: "Email" },
    { accessorKey: "message", header: "Message" },
    {
      accessorKey: "created_at",
      header: "Created At",
      Cell: ({ cell }) => dayjs(cell.getValue()).format("YYYY-MM-DD HH:mm:ss")
    },
    {
      accessorKey: "updated_at",
      header: "Updated At",
      Cell: ({ cell }) => dayjs(cell.getValue()).format("YYYY-MM-DD HH:mm:ss")
    }
  ], []);

  const table = useMaterialReactTable({
    columns,
    data: searchedEnquiries,
    manualPagination: true,
    onPaginationChange: setPagination,
    rowCount: enquiries?.data?.count || 0,
    state: { pagination }
  });

  return (
    <Box padding="30px">
      <Box sx={{ display: "flex", gap: "1rem", paddingBottom: "20px" }}>
        <TextField
          label="Search"
          variant="outlined"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          sx={{ marginLeft: "auto", width: "300px" }}
        />
      </Box>
      {searchedEnquiries.length > 0 ? (
        <MaterialReactTable table={table} />
      ) : (
        <Typography>No enquiries found.</Typography>
      )}
    </Box>
  );
};

const EnquiryTable = ({ getEnquiries, resetEnquiries, enquiries }) => (
  <Box padding="30px">
    <TableComponent
      getEnquiries={getEnquiries}
      resetEnquiries={resetEnquiries}
      enquiries={enquiries}
    />
  </Box>
);

const mapStateToProps = (state) => {

    return {
  enquiries: state.enquiries ? state.enquiries.enquiries : {
    data: [],
    loading: false,
    error: null,
    success: false
  }
}
};

const mapDispatchToProps = (dispatch) => ({
  getEnquiries: bindActionCreators(enquiryActions.getEnquiries, dispatch),
  resetEnquiries: bindActionCreators(enquiryActions.resetEnquiries, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(EnquiryTable);
