import {GET_PRODUCT_DETAIL, GET_PRODUCT_DETAIL_SUCCESS, GET_PRODUCT_DETAIL_FAIL, RESET_PRODUCT_DETAIL  } from "../actions/productDetail"

const initialState = {
  productDetail: {
    data: [],
    loading: false,
    error: null,
    success: false
  }
}

const productDetailReducer = (state = initialState, action) => {
  switch(action.type) {
    case GET_PRODUCT_DETAIL:
      return {
        ...state,
        productDetail: {
          ...state.productDetail,
          loading: true,
          success: false,
          error: null
        }
      }
    case GET_PRODUCT_DETAIL_SUCCESS:
      return {
        ...state,
        productDetail: {
          ...state.productDetail,
          data: action.payload?.data,
          loading: false,
          success: true,
          error: null
        }
      }
    case GET_PRODUCT_DETAIL_FAIL:
      return {
        ...state,
        productDetail: {
          ...state.productDetail,
          loading: false,
          success: false,
          error: action.payload
        }
      }
    case RESET_PRODUCT_DETAIL:
      return {
        ...state,
        productDetail: {
          ...state.productDetail,
          loading: false,
          success: false,
          error: null
        }
      }
    default:
      return state;
  }
}

export default productDetailReducer;