import {
  ENQUIRIES_FETCH_SUCCESS,
  ENQUIRIES_RESET,
  GET_ENQUIRIES,
  ENQUIRIES_FETCH_FAIL
} from "../actions/enquiry";

const initialState = {
  enquiries: {
    data: [],
    loading: false,
    error: null,
    success: false
  }
};

const enquiryReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ENQUIRIES:
      return {
        ...state,
        enquiries: {
          ...state.enquiries,
          loading: true,
          success: false,
          error: null
        }
      };

    case ENQUIRIES_FETCH_SUCCESS:
      return {
        ...state,
        enquiries: {
          data: action.payload.data || [], // Ensure `data` is directly accessed
          loading: false,
          success: true,
          error: null
        }
      };

    case ENQUIRIES_FETCH_FAIL:
      return {
        ...state,
        enquiries: {
          ...state.enquiries,
          loading: false,
          success: false,
          error: action.payload || "An error occurred"
        }
      };

    case ENQUIRIES_RESET:
      return {
        ...state,
        enquiries: initialState.enquiries
      };

    default:
      return state;
  }
};

export default enquiryReducer;
