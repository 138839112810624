import {takeEvery, put, call} from "redux-saga/effects"
import {  doGet, doPost } from '../../request'
import {all} from "redux-saga/effects";
import { GET_PRODUCT_DETAIL, GET_PRODUCT_DETAIL_SUCCESS, GET_PRODUCT_DETAIL_FAIL } from "../actions/productDetail";

function* getProductDetail({payload: product}) {
  try {
    const url = `products?type=${product}`
    const response = yield call(doGet, url)
    yield put({ type: GET_PRODUCT_DETAIL_SUCCESS, payload: response })
  } catch (error) {
    yield put({ type: GET_PRODUCT_DETAIL_FAIL, payload: error })
  }
}

export default function* productDetailWatcher() {
  yield all([
    takeEvery(GET_PRODUCT_DETAIL, getProductDetail),
  ]);
}
