import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CTA from "../components/Custom/CTA";
import { makeStyles } from "@mui/styles";
import ProductDetailBanner from "../components/Custom/ProductDetailBanner";
import ProductDetailTabs from "../components/Custom/ProductDetailTabs";
import Overview from "../components/ProductDetails/Overview";
import Features from "../components/ProductDetails/Features";
import Calculator from "../components/ProductDetails/Calculator";
import HowToInvest from "../components/ProductDetails/HowToInvest";
import Eligibility from "../components/ProductDetails/Eligibility";
import Faqs from "../components/ProductDetails/Faqs";
import * as ProductDetailActions from "../redux/actions/productDetail";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

const useStyles = makeStyles((theme) => ({
  sectionOne: {
    height: "50vh",
    backgroundColor: "#000000",
    position: "relative",
  },
  sectionTwo: {
    height: "45vh",
    width: '100vw',
    margin: "auto",
    backgroundColor: "#ffffff",
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-end",
    marginBottom: "30px",
  },
  productDetailInnerBanner: {
    position: "absolute",
    bottom: -170,
    left: 0,
    transform: "translateX(50%)",
    [theme.breakpoints.down("lg")]: {
      transform: "translateX(5%)",
    },
  },
  sectionTwoInnerContainer: {
    padding: "20px 3%",
    margin: "auto",
    // maxHeight: "100vh",
    // overflowY: "scroll",
    scrollbarWidth: "none",
    "-ms-overflow-style": "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    stickyPos: {
      position: "sticky",
      top: 100000,
    },
  },
}));

const ProductDetail = ({
  productDetail,
  getProductDetail,
  resetProductDetail,
}) => {
  const { product } = useParams();
  const classes = useStyles();
  const [currentTab, setCurrentTab] = useState(0);
  const [productDetailData, setProductDetailData] = useState({});
  const navigate = useNavigate();
  const productCategory = {
    investment: ["fixed-deposit", "monthly-income-scheme",'current-account','saving-account', "recurring-deposit","daily-deposit"],
    loan: ["personal-loan", "business-loan","loan-against-deposit", "gold-loan","vehicle-loan"],
  };

  if(!productCategory.investment.includes(product) && !productCategory.loan.includes(product)){
    navigate("/");
  }

  // Find the category for the provided product
  let category;
  for (const [cat, prods] of Object.entries(productCategory)) {
    if (prods.includes(product)) {
      category = cat;
      break;
    }
  }

  useEffect(() => {
    getProductDetail(product);
    window.scrollTo({ top: 0, behavior: "smooth" });

    return () => resetProductDetail(); // Reset product detail on component unmount
  }, [getProductDetail, product, resetProductDetail]);

  useEffect(() => {
    if (productDetail?.success) {
      setProductDetailData(productDetail?.data[0]);
    }
  }, [productDetail]);

  const handleTabChange = (newTab) => {
    setCurrentTab(newTab);
  };

  const defaultTabs = [
    "Overview",
    "Features",
    `${product} Calculator`,
    `${["Current Account","Saving Account"].includes(productDetailData?.title) ? "How to open" :category === "investment" ? "How to invest" : "How to apply"}`,
    "Eligibility",
    "FAQs",
  ];

  let tabs = [];

  switch (product) {
    case "fixed-deposit":
      tabs = [...defaultTabs];
      break;
    case "current-account":
    case "saving-account":
    case "loan-against-deposit":
      tabs = [...defaultTabs.filter((t) => !t.includes(`${product} Calculator`))];
      break;
    default:
      tabs = [...defaultTabs];
      break;
  }

  console.log(tabs);
  
    // Conditional rendering of content based on the selected tab
    const tabComponents = {
      Overview: (
        <div>
          <Overview
            overview={{
              title: productDetailData?.overviewTitle,
              description: productDetailData?.overviewDescription,
            }}
          />
        </div>
      ),
      Features: (
        <div>
          <Features
            features={productDetailData?.Features}
            title={productDetailData?.title}
          />
        </div>
      ),
      Calculator: (
        <div>
          <Calculator product={product} />
        </div>
      ),
      "How to open": (
        <div>
          <HowToInvest 
            title={productDetailData?.title}
          category={category} steps={productDetailData?.Steps} />
        </div>
      ),
      "How to invest": (
        <div>
          <HowToInvest 
            title={productDetailData?.title}
           category={category} steps={productDetailData?.Steps} />
        </div>
      ),
      "How to apply": (
        <div>
          <HowToInvest  
            title={productDetailData?.title}
          
          category={category} steps={productDetailData?.Steps} />
        </div>
      ),
      Eligibility: (
        <div>
          <Eligibility
            category={category}
            title={productDetailData?.title}
            eligibility={productDetailData?.EligibilityCriteria}
          />
        </div>
      ),
      FAQs: (
        <div>
          <Faqs faqs={productDetailData?.FAQs} />
        </div>
      ),
    };
    
    const renderTabContent = () => {
      const currentTabLabel = tabs[currentTab];
      const cleanedTabLabel = currentTabLabel.replace(`${product} `, ""); // Removes the product prefix from the tab label if necessary
      return tabComponents[cleanedTabLabel] || null;
    };

  return (
    <>
      <div className={classes.sectionOne}>
        <ProductDetailBanner 
          title={productDetailData?.title}
          banner={productDetailData?.bannerUrl} />
        <div className={classes.productDetailInnerBanner}>
          <CTA  
          title={productDetailData?.title}
          category={category} 
          productDetailData={productDetailData} />
        </div>
      </div>

      <div className={classes.sectionTwo}>
        <ProductDetailTabs
          currentTab={currentTab}
          onTabChange={handleTabChange}
          tabs={tabs}
        />
      </div>

      <div className={classes.sectionTwoInnerContainer}>
        {renderTabContent()}
      </div>
    </>
  );
};

ProductDetail.propTypes = {
  productDetail: PropTypes.object.isRequired,
  getProductDetail: PropTypes.func.isRequired,
  resetProductDetail: PropTypes.func.isRequired,
};

const mapStateToProps = ({ productDetail: { productDetail } }) => {
  return {
    productDetail,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getProductDetail: bindActionCreators(
    ProductDetailActions.getProductDetail,
    dispatch
  ),
  resetProductDetail: bindActionCreators(
    ProductDetailActions.resetProductDetail,
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductDetail);
