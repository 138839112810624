export const GET_LEADS = 'GET_LEADS'
export const GET_LEADS_SUCCESS = 'GET_LEADS_SUCCESS'
export const GET_LEADS_FAIL = 'GET_LEADS_FAIL'
export const RESET_LEADS = 'RESET_LEADS'

export const getLeads = (payload) => ({
  type: GET_LEADS,
  payload
})

export const getLeadsSuccess = (data) => ({
  type: GET_LEADS_SUCCESS,
  paylaod: data
})

export const getLeadsFail = (error) => ({
  type: GET_LEADS_FAIL,
  payload: error
})

export const resetLeads = () => ({
  type: RESET_LEADS
})
