/** @jsxImportSource @emotion/react */
import { Button } from "@mui/material";
import React from "react";
import EastIcon from "@mui/icons-material/East";
import styled from "@emotion/styled";

// Styled Button with different styles for outlined and text variants
const StyledButton = styled(Button)`
  border-radius: 16px;
  padding: 10px 20px;
  color: #ffffff;
  cursor: pointer;
  outline: none;
  font-weight: bold;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;

  &.outlined {
    background: linear-gradient(135deg, #0047ab 30%, #1e90ff 100%);
    &:hover {
      background: linear-gradient(135deg, #1e90ff 30%, #0047ab 100%);
      box-shadow: 0 2px 10px rgba(0, 71, 171, 0.5);
    }
  }

  &.text {
    &:hover {
      color: #1e90ff;
    }
  }
`;

const ButtonBlue = ({ name, withArrow = false, onClick, style }) => {
  return !withArrow ? (
    <StyledButton
      style={{ margin: "0 7px", ...style }}
      variant="outlined"
      onClick={onClick}
      className="outlined"
    >
      {name}
    </StyledButton>
  ) : (
    <StyledButton
      style={{ color: "black", margin: "0 7px", ...style }}
      variant="text"
      onClick={onClick}
      className="text"
    >
      {name} &nbsp; <EastIcon />
    </StyledButton>
  );
};

export default ButtonBlue;
