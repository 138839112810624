import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";

const InvestmentProducts = [
  {
    title: "Saving Account",
    description:
      "Open savings account online with us in 3 minutes and get interest upto 7.5 % p.a.",
  },
  {
    title: "Current Account",
    description:
      "Open instant current account in just 3 minutes with minimum balance of Rs 3000.",
  },
  {
    title: "Fixed Deposit",
    description:
      "Invest lump sum amount in fixed deposit and earn interest up to 12 % p.a.",
  },
  {
    title: "Recurring Deposit",
    description:
      "Invest small portion of monthly earning And earn 9.00 % interest p.a.",
  },
  {
    title: "Monthly Income Scheme",
    description:
      "For those seeking regular monthly income , Earn monthly interest upto 11.50 % p.a.",
  },
  {
    title: "Daily Deposit",
    description:
      "Save minimum Rs 100 per day and get Rs 300744 after end of 7 years.",
  },
];

const LoanProducts = [
  {
    title: "Gold Loan",
    description:
      "Utilize your gold assets as collateral to secure a loan with suitable interest rate.",
  },
  {
    title: "Vehicle Loan",
    description:
      "To give loan for the purchase of a vehicle with convenient repayment terms and prevailing interest rates.",
  },
  {
    title: "Loan Against Deposit",
    description:
      "Leverage your existing deposits as collateral to get loan with effective interest rates.",
  },
  {
    title: "Personal Loan",
    description:
      "Get attractive interest rates with flexible financing options.",
  },
  {
    title: "Business Loan",
    description:
      "Entrepreneurs and small businesses can get essential financial support to start, expand, or stabilize their business.",
  },
];

const useStyles = makeStyles((theme) => ({
  section: {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
    overflow: "hidden",
    position: "relative",
    backgroundColor: "#EFF8FF",
    backgroundSize: "calc(17px*6) calc(17px*6)",
    backgroundImage:
      "radial-gradient(transparent 17px,#FFFFFF 17px,#FFFFFF calc(17px*4),transparent calc(17px*4))",
  },
  root: {
    padding: theme.spacing(4),
  },
  OurProductTabSection: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  heading: {
    color: "#0561AB",
    fontSize: "2.4rem",
    fontWeight: "900",
    background: "#ffffff",
    letterSpacing: "1px",
    fontFamily: "sans-serif",
    marginBottom: theme.spacing(2),
    "@media (max-width: 600px)": {
      fontSize: "1.8rem",
    },
  },
  productsSection: {
    display: "flex",
    backgroundColor: "transparent",
    justifyContent: "center",
    alignItems: "center",
    boxShadow: "none",
    padding: "15px 15px",
    gap: theme.spacing(4),
    "& .slick-slide": {
      padding: "30px",
      backgroundColor: "transparent",
      boxShadow: "none",
    },
  },
  card: {
    position: "relative",
    width: "220px",
    height: "220px",
    cursor: "pointer",
    borderRadius: "14px",
    zIndex: 1,
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: "auto",
    fontFamily: "sans-serif",
    justifyContent: "center",
    boxShadow: "5px 5px 10px #bebebe, -20px -20px 60px #ffffff",
    backgroundColor: "#f8f8f8",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.black,
      transform: "scale(1.05)",
    },
    "@media (max-width: 600px)": {
      width: "180px",
      height: "180px",
    },
  },
  bg: {
    position: "absolute",
    top: "5px",
    left: "5px",
    width: "210px",
    height: "210px",
    zIndex: 2,
    background: "rgba(255, 255, 255, 3)",
    backdropFilter: "blur(24px)",
    borderRadius: "10px",
    overflow: "hidden",
    outline: "3px solid white",
    "@media (max-width: 600px)": {
      width: "170px",
      height: "170px",
    },
  },
  blob: {
    position: "absolute",
    zIndex: 1,
    top: "50%",
    left: "50%",
    width: "180px",
    height: "180px",
    borderRadius: "50%",
    backgroundColor: "#ff0000",
    opacity: 0.4,
    filter: "blur(12px)",
    animation: "$blobBounce 5s infinite ease",
    "@media (max-width: 600px)": {
      width: "140px",
      height: "140px",
    },
  },
  "@keyframes blobBounce": {
    "0%": {
      transform: "translate(-100%, -100%) translate3d(0, 0, 0)",
    },
    "25%": {
      transform: "translate(-100%, -100%) translate3d(100%, 0, 0)",
    },
    "50%": {
      transform: "translate(-100%, -100%) translate3d(100%, 100%, 0)",
    },
    "75%": {
      transform: "translate(-100%, -100%) translate3d(0, 100%, 0)",
    },
    "100%": {
      transform: "translate(-100%, -100%) translate3d(0, 0, 0)",
    },
  },
  icon: {
    position: "absolute",
    top: "40px",
    height: "39px",
    width: "39px",
    zIndex: 3,
    color: "#0561AB",
    fontSize: "2rem",
    "@media (max-width: 600px)": {
      top: "20px",
      height: "25px",
      width: "25px",
      fontSize: "1.5rem",
    },
  },
  title: {
    position: "relative",
    zIndex: 3,
    color: "#0561AB",
    fontSize: "1rem",
    fontWeight: "bold",
    marginTop: "70px",
    textAlign: "center",
    "@media (max-width: 600px)": {
      marginTop: "40px",
      fontSize: "0.875rem",
    },
  },
  description: {
    position: "relative",
    zIndex: 3,
    minHeight: "90px",
    fontSize: "0.9rem",
    color: "black",
    textAlign: "center",
    marginTop: "20px",
    padding: "0 12px",
    "@media (max-width: 600px)": {
      fontSize: "0.75rem",
      minHeight: "70px",
    },
  },
  buttonGroup: {
    display: "flex",
    gap: theme.spacing(2),
    justifyContent: "center",
    marginBottom: theme.spacing(4),
    flexWrap: "wrap",
  },
  button: {
    "--c": "#0561AB" /* the color */,
    fontFamily: "sans-serif",
    fontSize: "1rem",
    margin: "1rem 0",
    fontWeight: "bold",
    cursor: "pointer",
    padding: "0.5em 1.5em",
    border: "none",
    borderRadius: "50px",
    background: "#fff",
    color: "#0561AB",
    boxShadow: "0 0 0 .1em inset var(--c)",
    "--_g": "linear-gradient(var(--c) 0 0) no-repeat",
    background:
      "var(--_g) calc(var(--_p, 0%) - 100%) 0%, var(--_g) calc(200% - var(--_p, 0%)) 0%, var(--_g) calc(var(--_p, 0%) - 100%) 100%, var(--_g) calc(200% - var(--_p, 0%)) 100%",
    backgroundSize: "50.5% calc(var(--_p, 0%)/2 + .5%)",
    outlineOffset: ".1em",
    transition: "background-size .4s, background-position 0s .4s",
    "&:hover": {
      "--_p": "100%",
      color: "#fff",
      transition: "background-position .4s, background-size 0s",
    },
    "&:active": {
      boxShadow: "0 0 9e9q inset #0009",
      backgroundColor: "var(--c)",
      color: "#fff",
    },
    "&.active": {
      backgroundColor: "var(--c)",
      color: "#fff",
      boxShadow: "0 0 9e9q inset #0009",
    },
  },
  customArrow: {
    display: "flex",
    "&::before": {
      color: "#0561AB",
      fontSize: "30px",
    },
  },
  customArrowLeft: {
    left: "-25px",
  },
  customArrowRight: {
    right: "-25px",
  },
}));

const CustomArrow = ({ className, style, onClick }) => {
  const classes = useStyles();
  return (
    <div
      className={`${className} ${classes.customArrow}`}
      style={{ ...style, margin: "10px" }}
      onClick={onClick}
    />
  );
};

const CardComponent = ({ product }) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const handleProductClick = (product) => {
      navigate(`/${product.title.toLowerCase().replace(/ /g, "-")}`);
      window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <Box className={classes.card} onClick={() => handleProductClick(product)}>
      <Box className={classes.bg}></Box>
      <Box className={classes.blob}></Box>
      <img
        src={`icons/${product.title}.svg`}
        className={classes.icon}
        alt="icon"
      />
      <p className={classes.title}>{product.title}</p>
      <p className={classes.description}>{product.description}</p>
    </Box>
  );
};

const Products = ({ isDepositClicked }) => {
  const classes = useStyles();

  const [selectedTab, setSelectedTab] = useState("loan");

  useEffect(() => {
    setSelectedTab(isDepositClicked ? "investment" : "loan");
  }, [isDepositClicked]);

  const handleTabChange = (tab) => {
    setSelectedTab(tab);
  };

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    nextArrow: (
      <CustomArrow className={`slick-next ${classes.customArrowRight}`} />
    ),
    prevArrow: (
      <CustomArrow className={`slick-prev ${classes.customArrowLeft}`} />
    ),
    responsive: [
      {
        breakpoint: 1240,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const renderCardComponents = () => {
    const products = selectedTab === "loan" ? LoanProducts : InvestmentProducts;
    return products.map((product, index) => (
      <CardComponent key={index} product={product} />
    ));
  };

  return (
    <section className={classes.section} id="offeringProduct">
      <div className={classes.root}>
        <div className={classes.OurProductTabSection}>
          <p className={classes.heading}>Our Products</p>
          <div className={classes.buttonGroup}>
            <button
              className={`${classes.button} ${
                selectedTab === "investment" ? "active" : ""
              }`}
              onClick={() => handleTabChange("investment")}
            >
              Deposit Products
            </button>
            <button
              className={`${classes.button} ${
                selectedTab === "loan" ? "active" : ""
              }`}
              onClick={() => handleTabChange("loan")}
            >
              Loan Products
            </button>
          </div>
        </div>
        <Slider {...settings} className={classes.productsSection}>
          {renderCardComponents()}
        </Slider>
      </div>
    </section>
  );
};

export default Products;
