// src/components/Custom/PieChart.js
import React from "react";
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { makeStyles } from "@mui/styles";

// Register the necessary components
ChartJS.register(ArcElement, Tooltip, Legend);

const useStyles = makeStyles((theme) => ({
  chartContainer: {
    width: "100%",
    height: "412px",
    marginTop: "45px",
    padding: "50px",
    backgroundColor: "#E3F2FD",
    borderRadius: "15px",
  },
}));

const PieChart = ({ principal, interest, pieFirst, pieSecond }) => {
  const classes = useStyles();

  const data = {
    labels: [pieFirst, pieSecond],
    datasets: [
      {
        data: [principal, interest],
        backgroundColor: ["#1976d2", "#F35425"],
        hoverBackgroundColor: ["#115293", "#F35425"],
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "bottom",
      },
    },
  };

  return (
    <div className={classes.chartContainer}>
      <Pie data={data} options={options} />
    </div>
  );
};

export default PieChart;
