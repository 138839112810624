import {takeEvery, put, call} from "redux-saga/effects"
import { GET_USERS, GET_USERS_FAIL, GET_USERS_SUCCESS } from '../actions/users'
import { doGet, doPost } from '../../request'
import {all} from "redux-saga/effects";

function* getUsers() {
  try {
    const url = 'users'
    const response = yield call(doGet, url)
    yield put({ type: GET_USERS_SUCCESS, payload: response })
  } catch (error) {
    yield put({ type: GET_USERS_FAIL, payload: error })
  }
}

export default function* userWatcher() {
  yield all([
    takeEvery(GET_USERS, getUsers),
  ]);
}

