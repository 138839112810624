import { combineReducers } from 'redux';
import user from "./users"
import blogs from "./blogs"
import faqs from "./faqs"
import testimonials from "./testimonials"
import auth from "./auth"
import productDetail from "./productDetail"
import leads from'./leads'
import enquiries from'./enquiry'

const rootReducer = combineReducers({
  user,
  blogs,
  faqs,
  testimonials,
  auth,
  productDetail,
  leads,
  enquiries
})

export default rootReducer;