import React, { useEffect, useState } from "react";
import {
  TextField,
  Slider,
  Typography,
  Container,
  Box,
  Grid,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import ButtonBlue from "../Custom/ButtonBlue";
import ReportModal from "./ReportModal";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  calculatorContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "#ffffff",
    padding: "40px 0px",
    maxWidth: "100%",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      padding: "20px",
    },
  },
  shiningText: {
    fontFamily: "sans-serif", // Change font to "sans-serif
    color: "#1976d2",
    fontWeight: "bold",
    textAlign: "center",
    position: "relative",
    marginBottom: "1.8rem",
    fontSize: "1.5rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.2rem",
    },
    "&::before": {
      content: '""',
      position: "absolute",
      top: 0,
      left: 0,
      height: "100%",
      width: "100%",
      background:
        "linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.5), transparent)",
      backgroundSize: "200px 100%",
      animation: "$shine 1.5s infinite",
    },
  },
  "@keyframes shine": {
    "0%": {
      backgroundPosition: "-200px",
    },
    "100%": {
      backgroundPosition: "200px",
    },
  },
  resultsContainer: {
    height: "100%",
    padding: "20px",
    background: "#e3f2fd",
    borderRadius: "8px",
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    textAlignLast: "center",
    [theme.breakpoints.down("sm")]: {
      marginTop: "10px",
      padding: "10px",
    },
  },
  resultItem: {
    marginBottom: "10px",
    padding: "10px",
    borderRadius: "8px",
    backgroundColor: "#fff",
    width: "100%",
    textAlign: "left",
    [theme.breakpoints.down("sm")]: {
      padding: "8px",
      marginBottom: "8px",
    },
  },
  resultText: {
    color: "black",
    padding: 10,
    textTransform: "uppercase",
    fontWeight: "bold",
    fontSize: "1.2rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
    },
  },
  separator: {
    width: "100%",
    height: "1px",
    backgroundColor: "#ccc",
    margin: "10px 0",
  },
  resultTitle: {
    fontSize: "1.5rem",
    fontWeight: "bolder",
    color: "#1976d2",
    marginBottom: "10px",
  },
  resultValue: {
    fontSize: "2rem",
    fontWeight: "bold",
    color: "#1976d2",
  },
  item: {
    display: "flex",
    justifyContent: "space-between",
  },
  value: {
    color: "black",
    padding: 10,
    fontWeight: "bold",
    fontSize: "1.2rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
    },
  },
}));

const LoanCalculator = ({ productType, onCalculate }) => {
  const classes = useStyles();

  const [loanAmount, setLoanAmount] = useState(20000);
  const [interestRate, setInterestRate] = useState(8.75);
  const [loanTerm, setLoanTerm] = useState(12);
  const [EMI, setEMI] = useState(null);
  const [totalInterest, setTotalInterest] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [monthlyInterestRate, setMonthlyInterestRate] = useState(
    loanAmount / 100 / 12
  );

  useEffect(() => {
    const principal = parseFloat(loanAmount);
    const annualInterestRate = parseFloat(interestRate);
    const termInMonths = parseFloat(loanTerm);

    const monthlyInterestRate = annualInterestRate / 100 / 12;

    const EMI =
      (principal *
        monthlyInterestRate *
        Math.pow(1 + monthlyInterestRate, termInMonths)) /
      (Math.pow(1 + monthlyInterestRate, termInMonths) - 1);

    const roundedEMI = EMI.toFixed(2);

    setEMI(Number(roundedEMI));

    const totalInterest = EMI * termInMonths - principal;

    const roundedTotalInterest = totalInterest.toFixed(2);

    onCalculate(principal, roundedTotalInterest);
    setTotalInterest(Number(roundedTotalInterest));

    setMonthlyInterestRate(monthlyInterestRate);
  }, [loanAmount, interestRate, loanTerm]);

  useEffect(() => {
    switch (productType) {
      case "Personal Loan":
        setLoanAmount(20000);
        setInterestRate(10.5);
        setLoanTerm(24);
        break;
      case "Business Loan":
        setLoanAmount(50000);
        setInterestRate(12);
        setLoanTerm(36);
        break;
      case "Home Loan":
        setLoanAmount(300000);
        setInterestRate(8.5);
        setLoanTerm(240);
        break;
      default:
        setLoanAmount(20000);
        setInterestRate(8.75);
        setLoanTerm(12);
    }
  }, [productType]);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const navigate = useNavigate();

  const reportProps = {
    productType,
    loanAmount,
    monthlyInterestRate,
    loanTerm,
    EMI,
    totalInterest,
  };

  return (
    <Container maxWidth="100%" className={classes.calculatorContainer}>
      {/* <Box className={classes.calculator}> */}
      <Grid container gap={6} spacing={2}>
        <Grid item xs={12} lg={5.5}>
          <p className={classes.shiningText}>{productType}</p>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label="Loan Amount"
                type="number"
                value={loanAmount?.toString().replace(/^0+/, "")}
                onChange={(e) =>
                  setLoanAmount(
                    Math.max(0, Math.min(2500000, Number(e.target.value) || 0))
                  )
                }
                fullWidth
                variant="outlined"
                InputProps={{
                  inputProps: {
                    min: 1,
                  },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <div className={classes.sliderContainer}>
                <Slider
                  value={loanAmount?.toString().replace(/^0+/, "")}
                  onChange={(e, newValue) =>
                    setLoanAmount(
                      Math.max(
                        5000,
                        Math.min(2500000, Number(newValue) || 5000)
                      )
                    )
                  }
                  min={5000}
                  max={2500000}
                  step={1000}
                  valueLabelDisplay="auto"
                />
              </div>
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Interest Rate (%)"
                type="number"
                value={interestRate?.toString().replace(/^0+/, "")}
                onChange={(e) =>
                  setInterestRate(
                    Math.max(1, Math.min(20, parseFloat(e.target.value)) || 0)
                  )
                }
                fullWidth
                variant="outlined"
                InputProps={{
                  inputProps: {
                    min: 0.1,
                    step: 0.1,
                  },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <div className={classes.sliderContainer}>
                <Slider
                  value={interestRate?.toString().replace(/^0+/, "")}
                  onChange={(e, newValue) =>
                    setInterestRate(
                      Math.max(0, Math.min(20, parseFloat(newValue)) || 0)
                    )
                  }
                  min={1}
                  max={20}
                  step={0.1}
                  valueLabelDisplay="auto"
                />
              </div>
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Loan Term (Months)"
                type="number"
                value={loanTerm?.toString().replace(/^0+/, "")}
                onChange={(e) =>
                  setLoanTerm(
                    Math.max(1, Math.min(120, parseFloat(e.target.value)) || 1)
                  )
                }
                fullWidth
                variant="outlined"
                InputProps={{
                  inputProps: {
                    min: 1,
                  },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <div className={classes.sliderContainer}>
                <Slider
                  value={loanTerm?.toString().replace(/^0+/, "")}
                  onChange={(e, newValue) =>
                    setLoanTerm(
                      Math.max(1, Math.min(120, Number(newValue)) || 1)
                    )
                  }
                  min={1}
                  max={120}
                  step={1}
                  valueLabelDisplay="auto"
                />
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} lg={5.5}>
          <Box className={classes.resultsContainer}>
            <div
              className={classes.resultItem}
              style={{ marginBottom: 10, textAlign: "center" }}
            >
              <Typography
                fontSize={16}
                fontWeight={"bolder"}
                className={classes.resultTitle}
              >
                MONTHLY INSTALLMENT
              </Typography>
              <Typography
                fontSize={16}
                fontWeight={"700"}
                className={classes.resultValue}
              >
                ₹{parseInt(EMI).toLocaleString()}
              </Typography>
            </div>
            <div className={classes.item}>
              <Typography className={classes.resultText}>
                Total interest:
              </Typography>
              <span className={classes.value}>
                ₹{parseInt(totalInterest ?? 0).toLocaleString()}
              </span>
            </div>
            <div className={classes.separator}></div>
            <div className={classes.item}>
              <Typography className={classes.resultText}>
                Principal amount:
              </Typography>
              <span className={classes.value}>
                ₹{parseInt(loanAmount).toLocaleString()}
              </span>
            </div>
            <div className={classes.separator}></div>
            <div className={classes.item}>
              <Typography className={classes.resultText}>
                Total amount:
              </Typography>
              <span className={classes.value}>
                ₹{parseInt(totalInterest + loanAmount).toLocaleString()}
              </span>
            </div>
            <div style={{ margin: "10px 0" }}>
              <div
                style={{ display: "flex", flexDirection: "column", gap: 10 }}
              >
                <ButtonBlue name={"Apply now"} 
                onClick={()=>{
                  navigate("/profile")
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }}
                />
                <ButtonBlue name={"Show Schedule"} onClick={handleOpenModal} />
              </div>
              <ReportModal
                isOpen={isModalOpen}
                onRequestClose={handleCloseModal}
                reportProps={reportProps}
                setIsModalOpen={setIsModalOpen}
                isInvestment={false}
              />
            </div>
          </Box>
        </Grid>
      </Grid>
      {/* </Box> */}
    </Container>
  );
};

export default LoanCalculator;
