import {takeEvery, put, call} from "redux-saga/effects"
import { doDelete, doGet, doPost } from '../../request'
import {all} from "redux-saga/effects";
import { GET_TESTIMONIALS, GET_TESTIMONIALS_FAIL, GET_TESTIMONIALS_SUCCESS } from "../actions/testimonials";

// API to get testimonials list
function* getTestimonials() {
  try {
    const url = 'testimonials';
    const response = yield call(doGet, url)
    yield put({ type: GET_TESTIMONIALS_SUCCESS, payload: response })
  } catch (error) {
    yield put({ type: GET_TESTIMONIALS_FAIL, payload: error })
  }
}

export default function* testimonialsWatcher() {
  yield all([
    takeEvery(GET_TESTIMONIALS, getTestimonials),
  ]);
}
