export const LOGIN_WITH_OTP = "LOGIN_WITH_OTP";
export const LOGIN_WITH_OTP_SUCCESS = "LOGIN_WITH_OTP_SUCCESS";
export const LOGIN_WITH_OTP_FAIL = "LOGIN_WITH_OTP_FAIL";
export const VERIFY_OTP = "VERIFY_OTP";
export const VERIFY_OTP_SUCCESS = "VERIFY_OTP_SUCCESS";
export const VERIFY_OTP_FAIL = "VERIFY_OTP_FAIL";
export const RESET_AUTH = "RESET_AUTH";
export const LOGOUT = "LOGOUT";
export const ADMIN_LOGIN = "ADMIN_LOGIN";
export const ADMIN_LOGIN_SUCCESS = "ADMIN_LOGIN_SUCCESS";
export const ADMIN_LOGIN_FAIL = "ADMIN_LOGIN_FAIL";

export const loginWithOtp = (payload) => ({
  type: LOGIN_WITH_OTP,
  payload,
});

export const loginWithOtpSuccess = (payload) => ({
  type: LOGIN_WITH_OTP_SUCCESS,
  payload,
});

export const loginWithOtpFail = (payload) => ({
  type: LOGIN_WITH_OTP_FAIL,
  payload,
});

export const verifyOtp = (payload) => ({
    type: VERIFY_OTP,
    payload
})

export const verifyOtpSuccess = (payload) => ({
    type: VERIFY_OTP_SUCCESS,
    payload
})

export const verifyOtpFail = (error) => ({
    type: VERIFY_OTP_FAIL,
    error
})

export const resetAuth = () => ({
    type: RESET_AUTH
})

export const logout = () => ({
  type: LOGOUT
})


// Admin auth

export const adminLogin = (payload) => ({
  type: ADMIN_LOGIN,
  payload,
});

export const adminLoginSuccess = (data) => ({
  type: ADMIN_LOGIN_SUCCESS,
  payload: data,
});

export const adminLoginFail = (error) => ({
  type: ADMIN_LOGIN_FAIL,
  payload: error,
});
