import {takeEvery, put, call} from "redux-saga/effects"
import { doDelete, doGet, doPost } from '../../request'
import {all} from "redux-saga/effects";
import { ADMIN_LOGIN, ADMIN_LOGIN_FAIL, ADMIN_LOGIN_SUCCESS, LOGIN_WITH_OTP, LOGIN_WITH_OTP_FAIL, LOGIN_WITH_OTP_SUCCESS, VERIFY_OTP, VERIFY_OTP_FAIL, VERIFY_OTP_SUCCESS } from "../actions/auth";
import toast from "react-hot-toast";

function* loginWithOtp({payload}) {
    try {
      const isAdminLogin = window.location?.pathname?.includes('admin');
      const url = isAdminLogin ? 'auth/admin/login' : 'auth/login';
      const response = yield call(doPost, url, payload)
      
      yield put({ type: LOGIN_WITH_OTP_SUCCESS, payload: response })
      if(response.statusCode == 200){
        toast.success(response.message,{
          duration: 4000
        })
      }
    } catch (error) {
      yield put({ type: LOGIN_WITH_OTP_FAIL, payload: error })
      if(error.statusCode == 400){
        toast.error(error.message,{
          duration: 4000
        })
      }
    }
  }

function* verifyOtp({payload}) {
  try {
    const url = 'auth/verify-otp';
    const response = yield call(doPost, url, payload)
    yield put({ type: VERIFY_OTP_SUCCESS, payload: response })
    if(response.statusCode == 200){
      toast.success(response.message,{
        duration: 4000
      })
    }
  } catch (error) {
    yield put({ type: VERIFY_OTP_FAIL, payload: error })
    if(error.statusCode == 400){
      toast.error(error.message,{
        duration: 4000
      })
    }
  }
}

function* adminLogin({payload}) {
  try {
    const response = yield call(doPost, 'auth/admin/login', payload)
    yield put({ type: ADMIN_LOGIN_SUCCESS, payload: response })
  } catch (error) {
    yield put({ type: ADMIN_LOGIN_FAIL, payload: error })
    if(error.statusCode == 400){
      toast.error(error.message,{
        duration: 4000
      })
    }
  }

}


export default function* authWatcher() {
  yield all([
    takeEvery(LOGIN_WITH_OTP, loginWithOtp),
    takeEvery(VERIFY_OTP, verifyOtp),
    takeEvery(ADMIN_LOGIN, adminLogin),
  ]);
}
