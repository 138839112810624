import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { Box, MenuItem, Select, Typography, TextField } from "@mui/material";
import { bindActionCreators } from "redux";
import * as leadsActions from "../redux/actions/leads"; // Update this path to where your actions are located
import dayjs from "dayjs";

const LoanProducts = [
  {
    title: "Gold Loan",
    description:
      "Utilize your gold assets as collateral to secure a loan with suitable interest rate.",
  },
  {
    title: "Vehicle Loan",
    description:
      "To give loan for the purchase of a vehicle with convenient repayment terms and prevailing interest rates.",
  },
  {
    title: "Loan Against Deposit",
    description:
      "Leverage your existing deposits as collateral to get loan with effective interest rates.",
  },
  {
    title: "Personal Loan",
    description:
      "Get attractive interest rates with flexible financing options.",
  },
  {
    title: "Business Loan",
    description:
      "Entrepreneurs and small businesses can get essential financial support to start, expand, or stabilize their business.",
  },
];

const InvestmentProducts = [
  {
    title: "Saving Account",
    description:
      "Open savings account online with us in 3 minutes and get interest upto 7.5 % p.a.",
  },
  {
    title: "Current Account",
    description:
      "Open instant current account in just 3 minutes with minimum balance of Rs 3000.",
  },
  {
    title: "Fixed Deposit",
    description:
      "Invest lump sum amount in fixed deposit and earn interest up to 12 % p.a.",
  },
  {
    title: "Recurring Deposit",
    description:
      "Invest small portion of monthly earning And earn 9.00 % interest p.a.",
  },
  {
    title: "Monthly Income Scheme",
    description:
      "For those seeking regular monthly income , Earn monthly interest upto 11.50 % p.a.",
  },
  {
    title: "Daily Deposit",
    description:
      "Save minimum Rs 100 per day and get Rs 300744 after end of 7 years.",
  },
];

const TableComponent = ({ getLeads, resetLeads, leads }) => {
  const [selectedLoanProduct, setSelectedLoanProduct] =
    useState("Business Loan");
  const [selectedInvestmentProduct, setSelectedInvestmentProduct] = useState(
    "Select Investment Product"
  );
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 });
  const [searchQuery, setSearchQuery] = useState(""); // Search query state

  useEffect(() => {
    const product =
      selectedLoanProduct !== "Select Loan Product"
        ? selectedLoanProduct
        : selectedInvestmentProduct;
    if (
      product &&
      product !== "Select Loan Product" &&
      product !== "Select Investment Product"
    ) {
      const offset = pagination.pageIndex * pagination.pageSize;
      if (!isNaN(offset) && !isNaN(pagination.pageSize)) {
        getLeads({
          product: product.toLowerCase().replace(/ /g, "-"),
          offset,
          limit: pagination.pageSize,
        });
      }
    }
  }, [
    selectedLoanProduct,
    selectedInvestmentProduct,
    pagination.pageIndex,
    pagination.pageSize,
    getLeads,
    resetLeads,
  ]);

  const filteredLeads = useMemo(() => {
    return leads?.success ? leads.data.leads : [];
  }, [leads]);

  // Filter leads based on the search query
  const searchedLeads = useMemo(() => {
    if (!searchQuery) return filteredLeads;
    return filteredLeads.filter(
      (lead) =>
        lead.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        lead.phoneNumber.toLowerCase().includes(searchQuery.toLowerCase()) ||
        lead.email.toLowerCase().includes(searchQuery.toLowerCase())
    );
  }, [filteredLeads, searchQuery]);

  const columns = useMemo(
    () => [
      { accessorKey: "name", header: "Name" },
      { accessorKey: "phoneNumber", header: "Phone Number" },
      { accessorKey: "email", header: "Email" },
      {
        accessorKey: "created_at",
        header: "Created At",
        Cell: ({ cell }) =>
          dayjs(cell.getValue()).format("YYYY-MM-DD HH:mm:ss"),
      },
      {
        accessorKey: "updated_at",
        header: "Updated At",
        Cell: ({ cell }) =>
          dayjs(cell.getValue()).format("YYYY-MM-DD HH:mm:ss"),
      },
    ],
    []
  );

  const table = useMaterialReactTable({
    columns,
    data: searchedLeads,
    manualPagination: true,
    onPaginationChange: setPagination,
    rowCount: leads.data.count,
    state: {
      pagination,
    },
    // Optional: Adding additional table configuration if needed
  });

  return (
    <Box padding={"30px"}>
      <Box sx={{ display: "flex", gap: "1rem", paddingBottom: "20px" }}>
        <Select
          value={selectedLoanProduct}
          onChange={(e) => {
            setSelectedLoanProduct(e.target.value);
            setSelectedInvestmentProduct("Select Investment Product");
          }}
          displayEmpty
        >
          <MenuItem value="Select Loan Product">
            <em>Select Loan Product</em>
          </MenuItem>
          {LoanProducts.map((product, index) => (
            <MenuItem key={index} value={product.title}>
              {product.title}
            </MenuItem>
          ))}
        </Select>
        <Select
          value={selectedInvestmentProduct}
          onChange={(e) => {
            setSelectedInvestmentProduct(e.target.value);
            setSelectedLoanProduct("Select Loan Product");
          }}
          displayEmpty
        >
          <MenuItem value="Select Investment Product">
            <em>Select Investment Product</em>
          </MenuItem>
          {InvestmentProducts.map((product, index) => (
            <MenuItem key={index} value={product.title}>
              {product.title}
            </MenuItem>
          ))}
        </Select>
        <TextField
          label="Search"
          variant="outlined"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          sx={{ marginLeft: "auto", width: "300px" }}
        />
      </Box>
      {(selectedLoanProduct !== "Select Loan Product" ||
        selectedInvestmentProduct !== "Select Investment Product") && (
        <MaterialReactTable table={table} />
      )}
      {selectedLoanProduct === "Select Loan Product" &&
        selectedInvestmentProduct === "Select Investment Product" && (
          <Typography>Select a product to view the leads.</Typography>
        )}
    </Box>
  );
};

const LeadsTable = ({ getLeads, resetLeads, leads }) => (
  <Box padding={"30px"}>
    <TableComponent getLeads={getLeads} resetLeads={resetLeads} leads={leads} />
  </Box>
);

const mapStateToProps = (state) => ({
  leads: state.leads.leads,
});

const mapDispatchToProps = (dispatch) => ({
  getLeads: bindActionCreators(leadsActions.getLeads, dispatch),
  resetLeads: bindActionCreators(leadsActions.resetLeads, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(LeadsTable);
