export const GET_PRODUCT_DETAIL = 'GET_PRODUCT_DETAIL'
export const GET_PRODUCT_DETAIL_SUCCESS = 'GET_PRODUCT_DETAIL_SUCCESS'
export const GET_PRODUCT_DETAIL_FAIL = 'GET_PRODUCT_DETAIL_FAIL'
export const RESET_PRODUCT_DETAIL = 'RESET_PRODUCT_DETAIL'

export const getProductDetail = (payload) => ({
  type: GET_PRODUCT_DETAIL,
  payload
})

export const getProductDetailSuccess = (data) => ({
  type: GET_PRODUCT_DETAIL_SUCCESS,
  paylaod: data
})

export const getProductDetailFail = (error) => ({
  type: GET_PRODUCT_DETAIL_FAIL,
  payload: error
})

export const resetProductDetail = () => ({
  type: RESET_PRODUCT_DETAIL
})
