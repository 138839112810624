import React from "react";
import { makeStyles } from "@mui/styles";
import {
  Container,
  Grid,
  Card,
  CardContent,
  Typography,
  Chip,
  Box,
} from "@mui/material";
import CustomArrowButton from "../Custom/CustomArrowButton";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(4),
    position: "relative",
    overflow: "hidden",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: theme.spacing(4),
    zIndex: 1,
    position: "relative",
    flexDirection: "column",
    "@media (min-width: 600px)": {
      flexDirection: "row",
    },
  },
  title: {
    color: "#0561AB",
    fontWeight: "bold",
    fontSize: "2.5rem",
    textAlign: "center",
    "@media (min-width: 600px)": {
      textAlign: "left",
    },
  },
  card: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    height: "100%",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    transition: "transform 0.3s ease, box-shadow 0.3s ease",
    "&:hover": {
      transform: "translateY(-10px)",
      boxShadow: "0 12px 16px rgba(0, 0, 0, 0.2)",
    },
    cursor: "pointer",
  },
  media: {
    width: "300px",
    height: "300px",
    objectFit: "cover",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
  description: {
    marginBottom: theme.spacing(2),
  },
  tags: {
    display: "flex",
    flexWrap:"wrap",
    justifyContent: "start",
    gap: theme.spacing(1),
    marginTop: theme.spacing(2),
  },
  link: {
    textDecoration: "none",
    fontWeight: "bold",
    color: theme.palette.primary.main,
  },
  svgBackground: {
    position: "absolute",
    top: 0,
    left: 0,
    opacity: 0.3,
    width: "100%",
    height: "100%",
    zIndex: -1,
    overflow: "hidden",
    pointerEvents: "none",
  },
  svgShape: {
    position: "absolute",
    animation: `$float 6s ease-in-out infinite`,
  },
  svgShape1: {
    top: "-20%",
    left: "-10%",
    width: "500px",
    height: "500px",
    background:
      'url(\'data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200"><path fill="%23FF7F50" d="M45.5,-55.3C60.6,-46.1,73.2,-30.8,72.7,-16.1C72.2,-1.3,58.6,12.8,47.7,22.6C36.8,32.3,28.7,37.7,18.8,43.8C9,49.8,-2.7,56.6,-13.6,57.7C-24.5,58.9,-34.6,54.4,-41.7,46.1C-48.9,37.8,-53.1,25.6,-58.2,12.4C-63.2,-0.8,-69.2,-14.9,-66.6,-27.4C-64,-39.9,-52.7,-50.8,-40.1,-58.6C-27.4,-66.3,-13.7,-71,-0.2,-70.7C13.4,-70.4,26.7,-64.5,45.5,-55.3Z" transform="translate(100 100)" /></svg>\') no-repeat center/contain',
  },
  svgShape2: {
    bottom: "-20%",
    right: "-10%",
    width: "400px",
    height: "400px",
    background:
      'url(\'data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200"><path fill="%230561AB" d="M42.1,-60.8C53.7,-56.4,60.8,-43,61.7,-30.1C62.5,-17.1,57.1,-4.6,56.3,9.3C55.5,23.1,59.2,38.3,54.7,45.8C50.2,53.3,37.5,53.2,25.9,56.3C14.3,59.4,7.2,65.6,-0.5,66.3C-8.2,67,-16.4,62.2,-28.4,59.7C-40.4,57.2,-56.2,56.9,-62.9,49.7C-69.6,42.5,-67.2,28.4,-66.7,16.7C-66.2,5,-67.6,-4.2,-66.5,-12.5C-65.4,-20.9,-61.7,-28.3,-55.8,-31.2C-49.9,-34,-41.8,-32.2,-34.8,-34.4C-27.8,-36.5,-21.8,-42.5,-12.3,-49.1C-2.7,-55.7,9.4,-62.9,20.2,-66.2C30.9,-69.5,41.5,-68.9,42.1,-60.8Z" transform="translate(100 100)" /></svg>\') no-repeat center/contain',
  },
  "@keyframes float": {
    "0%": {
      transform: "translateY(0px)",
    },
    "50%": {
      transform: "translateY(-20px)",
    },
    "100%": {
      transform: "translateY(0px)",
    },
  },
}));

const BlogSection = ({ blogs, all }) => {
  const classes = useStyles();

  const navigate = useNavigate();
  
  blogs = [
    {
      id: 1,
      title: "7 Reasons to Invest in Fixed Deposits",
      content:
        "A fixed deposit is one of the safest and surest investment instruments provided by banks. It provides investors with a good interest rate, guaranteed returns, and other benefits like liquidity, safety, etc.",
        image: "/blog/Fixed Deposit.jpg",
        tags: ["Investment", "Fixed Deposit", "Finance"],
    },
    {
      id: 2,
      title: "Understanding Financial Products: Fixed Deposit, Recurring Deposit, and Daily Deposit",
      content:
        "In today's fast-paced world, managing finances effectively is crucial for achieving both short-term and long-term financial goals. Among the various financial products available, Fixed Deposits (FDs), Recurring Deposits (RDs), and Daily Deposits are popular choices for individuals seeking to grow their savings with minimal risk. Each of these products offers unique benefits tailored to different financial needs. Let’s explore these options in detail.",
      image: "/blog/Current Account.jpg",
      tags: ["Finance", "Investment", "Fixed Deposit", "Recurring Deposit", "Daily Deposit"],
    },
    {
      id: 3,
      title: "The Power of Saving ₹100 Daily: Transforming Small Habits into a Secure Future",
      content:
        "In the journey toward financial stability and achieving long-term goals, the significance of consistent saving cannot be overstated. Often, the idea of setting aside a substantial amount each month seems daunting, leading many to delay or avoid saving altogether. However, saving just ₹100 daily can make a remarkable difference over time. Let’s delve into how this simple habit can transform your financial future.",
        image: "/blog/Daily Deposit.jpg",
        tags: ["Savings", "Finance", "Compounding", "Daily Habits", "Financial Security"]
    },
    {
      id: 4,
      title: "Why Recurring Deposit (RD) is Better Than Mutual Fund SIP for Certain Investors",
      content:
        "When it comes to choosing a savings or investment plan, the decision often boils down to your risk tolerance, financial goals, and time horizon. While both Recurring Deposits (RDs) and Mutual Fund Systematic Investment Plans (SIPs) are popular options, RDs offer several advantages that might make them a better choice for certain types of investors.",
        image: "/blog/Recurring Deposit.jpg",
        tags: ["Finance", "Investment", "Recurring Deposit", "SIP", "Savings"],
      },
  ];

  return (
    <Box className={classes.root}>
      <div className={classes.svgBackground}>
        <div className={`${classes.svgShape} ${classes.svgShape1}`} />
        <div className={`${classes.svgShape} ${classes.svgShape2}`} />
      </div>
      <div className={classes.header}>
        <Typography variant="h3" className={classes.title}>
          Blogs
        </Typography>
        {all === false && (
          <CustomArrowButton
            onClick={() => {
              navigate("/blogs");
              window.scrollTo({ top: 0, behavior: "smooth" });
            }}
          >
            All Articles
          </CustomArrowButton>
        )}
      </div>
      <Grid container spacing={4} justifyContent={"center"}>
        {blogs?.map((blog, index) => (
          <Grid item key={index} xs={12} sm={6} md={4} lg={3}>
            <Card onClick={()=>{
              navigate("/blog/" + blog.id)
              window.scrollTo({ top: 0, behavior: "smooth" });
            }} className={classes.card}>
              <img
                className={classes.media}
                src={
                 blog.image
                }
                alt=""
              />
              <CardContent className={classes.content}>
                <Typography variant="h6" gutterBottom>
                  {blog.title}
                </Typography>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  className={classes.description}
                >
                  {blog.content?.slice(0, 140) + "..."}
                </Typography>
                <Box className={classes.tags}>
                  {blog?.tags?.map((tag, idx) => (
                    <Chip
                      key={idx}
                      label={tag}
                      variant="outlined"
                      size="small"
                    />
                  ))}
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default BlogSection;
