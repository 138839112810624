import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import { Typography } from "@mui/material";
import { current } from "@reduxjs/toolkit";

const colors = ["#952aff", "#e44002", "#3ecd5e", "#cd3e94", "#4c49ea"];

const useStyles = makeStyles((theme) => ({
  container: {
    textAlign: "center",
    maxWidth: "1142px",
    margin: "0 auto",
    marginBottom: "30px",
  },
  sectionTwo: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "80%",
    margin: "40px auto",
    gap: 20,
    "@media (max-width: 500px)": {
      width: "90vw",
    },
    "@media (max-width: 1200px)": {
      width: "90vw",
    },
  },
  cardContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
    gap: "30px",
    [theme.breakpoints.down("md")]: {
      gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))",
    },
  },
  card: {
    overflow: "hidden",
    border: "1px solid #000",
    borderRadius: "28px",
    position: "relative",
  },
  cardLink: {
    display: "block",
    padding: "30px 20px",
    backgroundColor: "#ffffff",
    overflow: "hidden",
    textDecoration: "none",
    color: "#f9b234",
    position: "relative",
    cursor: "pointer",
    zIndex: 1,
  },
  cardTitle: {
    textJustify: "center",
    marginTop: "15px",
    marginLeft: "15px",
    marginRight: "15px",
    marginBottom: "8px",
    fontFamily: "sans-serif",
    overflow: "hidden",
    fontWeight: "600",
    fontSize: "17px",
    zIndex: "2",
    position: "relative",
    transition: "color .5s ease", // Add transition here
  },
  heading:{
    fontFamily: "sans-serif",
  },
  cardDescription: {
    textJustify: "center",
    fontFamily: "sans-serif",
    overflow: "hidden",
    fontWeight: "300",
    fontSize: "13px",
    zIndex: "2",
    position: "relative",
    transition: "color .5s ease", // Add transition here
  },
  cardDateBox: {
    fontSize: "18px",
    color: "#FFF",
    zIndex: "2",
    position: "relative",
  },
  cardDate: {
    fontWeight: "bold",
    color: "#f9b234",
    transition: "color .5s ease",
  },
  cardBg: {
    height: "128px",
    width: "128px",
    zIndex: "1",
    padding: "20px",
    position: "absolute",
    top: "-75px",
    right: "-75px",
    borderRadius: "50%",
    transition: "all .5s ease",
  },
}));

const Features = ({ features, title }) => {
  const classes = useStyles();
  const [hoverStates, setHoverStates] = useState(
    Array(features?.length).fill(false)
  );

  return (
    <div className={classes.container}>
      <div className={classes.sectionTwo}>
        <h3 className={classes.heading}>
          {title ? title : ""} Features and Benefits
        </h3>
      </div>
      <div className={classes.cardContainer}>
        {features?.map((feature, index) => (
          <div className={classes.card} key={index}>
            <div className={classes.cardLink}>
              <div
                className={`${classes.cardBg} ag-courses-item_bg`}
                style={{ backgroundColor: colors[index % colors.length] }}
              ></div>
              <p
                variant="h6"
                className={classes.cardTitle}
                style={{
                  color: "#000",
                }}
              >
                {feature?.title}
              </p>
              <p
                variant="h6"
                className={classes.cardDescription}
                style={{
                  color: "#000",
                }}
              >
                {feature?.description}
              </p>
              <Typography variant="body1" className={classes.cardDateBox}>
                <span className={classes.cardDate}>{feature.date}</span>
              </Typography>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Features;
