import React from 'react';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  card: {
    width: '300px',
    height: '200px',
    backgroundColor: '#fff',
    border: '1px solid #FF683E',
    borderRadius: '20px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    cursor: 'pointer',
    transition: 'transform 0.3s ease, box-shadow 0.3s ease',
    '&:hover': {
      transform: 'scale(1.05)',
      boxShadow: '0 10px 20px rgba(0, 0, 0, 0.2)',
    },
  },
  image: {
    height: '100px',
    transition: 'transform 0.3s ease',
    '&:hover': {
      transform: 'scale(1.1)',
    },
  },
  name: {
    fontSize: '1.2rem',
    fontWeight: 'bold',
    transition: 'color 0.3s ease',
    '&:hover': {
      color: '#FF683E',
    },
  },
}));

const EligibilityCard = ({ iconUrl, name }) => {
  const classes = useStyles();

  return (
    <div className={classes.card}>
      <img className={classes.image} src={iconUrl} alt={name} />
      <h4 className={classes.name}>{name}</h4>
    </div>
  );
}

export default EligibilityCard;
