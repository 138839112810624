import React from "react";
import { makeStyles } from "@mui/styles";
import { Typography } from "@mui/material";
import ButtonWhite from "../Custom/ButtonOrangeShadow";
import VerifiedIcon from "@mui/icons-material/Verified";
import CustomArrowButton from "../Custom/CustomArrowButton";

const useStyles = makeStyles((theme) => ({
  "@keyframes fadeIn": {
    "0%": {
      opacity: 0,
    },
    "100%": {
      opacity: 1,
    },
  },
  mainContainer: {
    padding: "20px 10px",
    textAlign: "center",
    color: theme.palette.text.primary,
    animation: "$fadeIn 2s ease-in-out",
    width: "50%",
    maxWidth: "1000px",
    margin: "0 auto",
    transition: "background-color 0.2s ease, box-shadow 0.3s ease",
    [theme.breakpoints.down("md")]: {
      padding: "15px 5px",
      width: "100%",
    },
  },
  spanText: {
    color: "#0561AB",
  },
  buttonGroup: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: 30,
    marginTop: theme.spacing(8),
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      alignItems: "flex-start",
      gap: 30,
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "center",
      gap: 30,
      marginTop: theme.spacing(4),
    },
  },
  creditText: {
    animation: "$fadeIn 2s ease-in-out",
    display: "flex",
    alignItems: "flex-start",
    gap: 10,
    fontSize: "0.875rem",
    backgroundColor: "#0561ab",
    color: "#fff",
    padding: "4px",
    borderRadius: 5,
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.75rem",
    },
  },
  subPoint: {
    animation: "$fadeIn 2s ease-in-out",
    display: "flex",
    alignItems: "flex-start",
    gap: 10,
    fontSize: "0.875rem",
    color: "#0561AB",
    borderRadius: 5,
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.75rem",
    },
  },
  headerText: {
    animation: "$fadeIn 3s ease-in-out",
    color: "#F35425",
    fontSize: "2rem",
    textAlign: "left",
    [theme.breakpoints.down("md")]: {
      fontSize: "1.75rem",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.5rem",
      textAlign: "left",
    },
  },
  descriptionText: {
    animation: "$fadeIn 4s ease-in-out",
    color: "black",
    fontSize: "1rem",
    textAlign: "left",
    [theme.breakpoints.down("md")]: {
      fontSize: "0.875rem",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.75rem",
      textAlign: "left",
    },
  },
  subDescription: {
    marginTop: "20px",
    listStyleType: "disc",
    marginLeft: "20px",
    textAlign: "left",
    [theme.breakpoints.down("sm")]: {
      textAlign: "left",
      marginLeft: "0",
    },
  },
}));

const BannerInfo = () => {
  const classes = useStyles();

  return (
    <div className={classes.mainContainer}>
      <Typography
        component="span"
        width={"fit-content"}
        marginBottom={5}
        className={`${classes.creditText} semi-bold-font`}
      >
        <VerifiedIcon /> Join Hands, Build wealth.
      </Typography>
      <Typography
        variant="h4"
        component="div"
        marginBottom={5}
        className={`${classes.headerText} bold-font letter-spacing`}
      >
        Welcome to Finland <br /> Empowering Your{" "}
        <span className={`${classes.spanText} bold-font`}>
          Financial Journey
        </span>
      </Typography>
      <Typography
        component="div"
        fontWeight={"500"}
        marginBottom={4}
        className={`${classes.descriptionText} regular-font`}
      >
        Join our Credit Co-op Society for smart savings and swift loans! Save
        little, grow big with secure small savings plans. Need funds fast? Our
        quick loans offer hassle-free access to money when you need it most.
        Empower your finances with our trusted community solutions!
        <ul className={`${classes.subDescription}`}>
          <Typography
            component="span"
            width={"fit-content"}
            marginBottom={2}
            className={`${classes.subPoint} semi-bold-font`}
          >
            <VerifiedIcon
              style={{
                color: "#0561AB",
              }}
            />{" "}
            Open Saving Account in just 3 minutes.
          </Typography>
          <Typography
            component="span"
            width={"fit-content"}
            marginBottom={2}
            className={`${classes.subPoint} semi-bold-font`}
          >
            <VerifiedIcon
              style={{
                color: "#0561AB",
              }}
            />{" "}
            Hassle free mobile banking experience.
          </Typography>
          <Typography
            component="span"
            width={"fit-content"}
            marginBottom={2}
            className={`${classes.subPoint} semi-bold-font`}
          >
            <VerifiedIcon
              style={{
                color: "#0561AB",
              }}
            />{" "}
            Building community of trust.
          </Typography>
        </ul>
      </Typography>
      <div className={classes.buttonGroup}>
        {/* <ButtonWhite name={"Open Account"} />
        <CustomArrowButton>Become Member</CustomArrowButton> */}
      </div>
    </div>
  );
};

export default BannerInfo;
