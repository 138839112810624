import React, { useState } from "react";
import {
  Container,
  Grid,
  TextField,
  Button,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import toast from "react-hot-toast";
import { doPost } from "../request";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
    backgroundColor: "#fff",
  },
  formContainer: {
    textAlign: "left",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  formTitle: {
    fontWeight: 700,
    fontSize: "1.6rem",
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  textField: {
    marginBottom: theme.spacing(2),
  },
  button: {
    borderRadius: "5px",
    padding: theme.spacing(1.5, 3),
    color: "#ffffff",
    backgroundColor: "#ff5252",
    "&:hover": {
      backgroundColor: "#ff1744",
    },
  },
  infoContainer: {
    textAlign: "left",
    paddingLeft: theme.spacing(3),
    [theme.breakpoints.down("sm")]: {
      borderLeft: "none",
      paddingLeft: 0,
      textAlign: "center",
      marginTop: theme.spacing(4),
    },
  },
  infoTitle: {
    fontWeight: 700,
    fontSize: "2rem",
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.6rem",
    },
  },
  infoText: {
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
    },
  },
}));

const ContactUs = () => {
  const classes = useStyles();
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",  // Added phone number state
    message: "",
  });

  const phoneRegex = /^\d{10}$/;
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    if (id === "phoneNumber") {
      // Allow only digits and limit to 10 characters
      if (value === "" || /^\d*$/.test(value)) {
        setFormData({ ...formData, [id]: value.slice(0, 10) });
      }
    } else {
      setFormData({ ...formData, [id]: value });
    }
  };   

  const handleSubmit = async () => {

    // First name validation: Required, only letters, 2-30 characters
    if (!formData.firstName.trim()) {
      toast.error("First name is required.", { duration: 4000 });
      return;
    }
    if (!/^[A-Za-z]{2,30}$/.test(formData.firstName)) {
      toast.error("First name must be 2-30 letters long.", { duration: 4000 });
      return;
    }

    // Last name validation: Required, only letters, 2-30 characters
    if (!formData.lastName.trim()) {
      toast.error("Last name is required.", { duration: 4000 });
      return;
    }
    if (!/^[A-Za-z]{2,30}$/.test(formData.lastName)) {
      toast.error("Last name must be 2-30 letters long.", { duration: 4000 });
      return;
    }

    // Email validation: Required, valid email format
    if (!formData.email.trim()) {
      toast.error("Email is required.", { duration: 4000 });
      return;
    }
    if (!emailRegex.test(formData.email)) {
      toast.error("Please enter a valid email address.", { duration: 4000 });
      return;
    }

    // Phone number validation: Required, valid phone format
    if (!formData.phoneNumber.trim()) {
      toast.error("Phone number is required.", { duration: 4000 });
      return;
    }
    if (!phoneRegex.test(formData.phoneNumber)) {
      toast.error("Phone number must be exactly 10 digits", { duration: 4000 });
      return;
    }

    // Message validation: Required, at least 10 characters
    if (!formData.message.trim()) {
      toast.error("Message is required.", { duration: 4000 });
      return;
    }
    if (formData.message.length < 10) {
      toast.error("Message must be at least 10 characters long.", { duration: 4000 });
      return;
    }

    const payload = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      email: formData.email,
      phoneNumber: formData.phoneNumber,  // Include phone number in payload
      message: formData.message,
    };

    try {
      const response = await doPost("/enqury", payload);
      if (response.status) {
        toast.success(response.message, { duration: 4000 });
        setFormData({
          firstName: "",
          lastName: "",
          email: "",
          phoneNumber: "",
          message: "",
        });
      } else {
        toast.error(response.message, { duration: 4000 });
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("Something went wrong. Please try again later.", { duration: 4000 });
    }
  };

  return (
    <Container maxWidth="md" className={classes.root}>
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12} lg={6} className={classes.formContainer}>
          <Typography
            variant="h4"
            fontWeight={"bold"}
            mb={3}
            className={classes.formTitle}
            color={"#0561AB"}
          >
            Get in Touch. <br /> We're here to help.
          </Typography>
          <Grid
            spacing={4}
            sx={{ display: "flex", flexDirection: "column", gap: "8px" }}
          >
            <TextField
              fullWidth
              id="firstName"
              label="First name"
              variant="standard"
              className={classes.textField}
              value={formData.firstName}
              onChange={handleInputChange}
              error={!/^[A-Za-z]{2,30}$/.test(formData.firstName) && formData.firstName.length > 0}
              helperText={!/^[A-Za-z]{2,30}$/.test(formData.firstName) && formData.firstName.length > 0 ? "First name must be 2-30 letters long." : ""}
            />
            <TextField
              fullWidth
              id="lastName"
              label="Last name"
              variant="standard"
              className={classes.textField}
              value={formData.lastName}
              onChange={handleInputChange}
              error={!/^[A-Za-z]{2,30}$/.test(formData.lastName) && formData.lastName.length > 0}
              helperText={!/^[A-Za-z]{2,30}$/.test(formData.lastName) && formData.lastName.length > 0 ? "Last name must be 2-30 letters long." : ""}
            />
            <TextField
              fullWidth
              id="email"
              label="Email"
              variant="standard"
              className={classes.textField}
              value={formData.email}
              onChange={handleInputChange}
              error={!emailRegex.test(formData.email) && formData.email.length > 0}
              helperText={!emailRegex.test(formData.email) && formData.email.length > 0 ? "Please enter a valid email address." : ""}
            />
          <TextField
              fullWidth
              id="phoneNumber"
              label="Phone Number"
              variant="standard"
              className={classes.textField}
              value={formData.phoneNumber}
              onChange={handleInputChange}
              error={!phoneRegex.test(formData.phoneNumber) && formData.phoneNumber.length > 0}
              helperText={!phoneRegex.test(formData.phoneNumber) && formData.phoneNumber.length > 0 ? "Phone number must be exactly 10 digits." : ""}
            />
            <TextField
              fullWidth
              id="message"
              label="Write your message"
              variant="standard"
              multiline
              rows={4}
              className={classes.textField}
              value={formData.message}
              onChange={handleInputChange}
              error={formData.message.length < 10 && formData.message.length > 0}
              helperText={formData.message.length < 10 && formData.message.length > 0 ? "Message must be at least 10 characters long." : ""}
            />
          </Grid>
          <Button
            variant="contained"
            className={classes.button}
            style={{ marginTop: "20px" }}
            onClick={handleSubmit}
          >
            Send Message
          </Button>
        </Grid>
        <Grid item xs={12} lg={6} className={classes.infoContainer}>
          <Typography
            variant="h4"
            mb={3}
            fontWeight={"bold"}
            color="#464245"
            className={classes.infoTitle}
          >
            Let’s talk about your financial goals.
          </Typography>
          <Typography variant="body1" className={classes.infoText}>
            Whether you're looking to invest or need a loan, our experts are here to guide you. Discover the best financial solutions tailored to your needs.
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
};



export default ContactUs;
