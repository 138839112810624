import React from "react";
import { makeStyles } from "@mui/styles";
import { SvgIcon, Box } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  playstoreButton: {
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    // border: "2px solid #000",
    borderRadius: "9999px",
    // backgroundColor: "rgba(0, 0, 0, 1)",
    backgroundColor: "#0561AB",
    padding: "0.625rem 1.5rem",
    textAlign: "center",
    color: "rgba(255, 255, 255, 1)",
    outline: 0,
    transition: "all 0.2s ease",
    textDecoration: "none",
    "&:hover": {
      // backgroundColor: "transparent",
      backgroundColor: "#F35425",
      color: "#fff",
    },
  },
  icon: {
    height: "1.5rem",
    width: "1.5rem",
  },
  texts: {
    marginLeft: "1rem",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    lineHeight: 1,
  },
  text1: {
    marginBottom: "0.25rem",
    fontSize: "0.75rem",
    lineHeight: "1rem",
  },
  text2: {
    fontWeight: 600,
  },
}));

const PlaystoreButton = () => {
  const classes = useStyles();

  return (
    <a href="https://play.google.com/store/apps/details?id=com.simfimb.thefinlandcoopcredit&pcampaignid=web_share" className={classes.playstoreButton}>
      <SvgIcon
        className={classes.icon}
        viewBox="0 0 512 512"
        fill="currentColor"
      >
        <path d="M99.617 8.057a50.191 50.191 0 00-38.815-6.713l230.932 230.933 74.846-74.846L99.617 8.057zM32.139 20.116c-6.441 8.563-10.148 19.077-10.148 30.199v411.358c0 11.123 3.708 21.636 10.148 30.199l235.877-235.877L32.139 20.116zM464.261 212.087l-67.266-37.637-81.544 81.544 81.548 81.548 67.273-37.64c16.117-9.03 25.738-25.442 25.738-43.908s-9.621-34.877-25.749-43.907zM291.733 279.711L60.815 510.629c3.786.891 7.639 1.371 11.492 1.371a50.275 50.275 0 0027.31-8.07l266.965-149.372-74.849-74.847z" />
      </SvgIcon>
      <Box className={classes.texts}>
        <span className={classes.text1}>GET IT ON</span>
        <span className={classes.text2}>Google Play</span>
      </Box>
    </a>
  );
};

export default PlaystoreButton;
