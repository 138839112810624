import React, { useEffect, useState } from "react";
import Investment from "../Calculators/Investment";
import LoanCalculator from "../Calculators/Loan";
import PieChart from "../Custom/PieChart";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  usecase: {
    display: "flex",
    flexDirection: "column",
    marginTop: theme.spacing(4),
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
    },
  },
  left: {
    display: "flex",
    marginTop: 30,
    flexDirection: "column",
    alignItems: "center",
    flex: 2,
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "40%",
    },
  },
  right: {
    flex: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      width: "60%",
    },
  },
}));

const Calculator = ({ product }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [principal, setPrincipal] = useState(0);
  const [interest, setInterest] = useState(0);
  const [pieFirst, setPieFirst] = useState("");
  const [pieSecond, setPieSecond] = useState("");

  const productCategory = {
    investment: [
      "fixed-deposit",
      "monthly-income-scheme",
      "recurring-deposit",
      "daily-deposit",
    ],
    loan: ["personal-loan", "business-loan", "gold-loan", "vehicle-loan"],
  };

  let category;
  for (const [cat, prods] of Object.entries(productCategory)) {
    if (prods.includes(product)) {
      category = cat;
      break;
    }
  }

  useEffect(() => {
    if (category === "loan") {
      setPieFirst("Principal Amount");
      setPieSecond("Interest Amount");
    } else if (category === "investment") {
      setPieFirst("Principal Amount");
      setPieSecond("Interest Earned");
    }
  }, [category]);

  switch (product) {
    case "fixed-deposit":
      product = "Fixed Deposit";
      break;
    case "monthly-income-scheme":
      product = "Monthly Income Scheme";
      break;
    case "recurring-deposit":
      product = "Recurring Deposit";
      break;
    case "daily-deposit":
      product = "Daily Deposit";
      break;
    case "personal-loan":
      product = "Personal Loan";
      break;
    case "business-loan":
      product = "Business Loan";
      break;
    case "vehicle-loan":
      product = "Vehicle Loan";
      break;
    case "gold-loan":
      product = "Gold Loan";
      break;
    default:
      return null;
  }

  const handleCalculation = (principal, interest) => {
    setPrincipal(principal);
    setInterest(interest);
  };

  return (
    <Box
      className={classes.usecase}
      sx={{ flexDirection: isSmallScreen ? "column" : "row" }}
    >
      <Box className={classes.left}>
        {category === "investment" && (
          <Investment
            productType={product}
            product={product}
            onCalculate={handleCalculation}
          />
        )}
        {category === "loan" && (
          <LoanCalculator
            productType={product}
            onCalculate={handleCalculation}
          />
        )}
      </Box>
      <Box className={classes.right}>
        <PieChart
          pieFirst={pieFirst}
          pieSecond={pieSecond}
          principal={principal}
          interest={interest}
        />
      </Box>
    </Box>
  );
};

export default Calculator;
