import React, { useEffect, useState } from "react";
import {
  Grid,
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormLabel,
  FormControl,
  Typography,
  MenuItem,
  Select,
  InputLabel,
  Box,
  Card,
  CardContent,
  Container,
} from "@mui/material";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import UploadIcon from "@mui/icons-material/Upload";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { getToken } from "../../request";

export const renderFilePreview = (file) => {
  if (!file) return null;
  const fileUrl =
    file.document_url ||
    (file instanceof Blob ? URL.createObjectURL(file) : null);

  const mimeType = file.mime_type || file.type;

  if (mimeType?.startsWith("image/")) {
    return (
      <img
        src={fileUrl}
        alt="Document Preview"
        style={{ maxHeight: "200px", maxWidth: "200px", margin: "10px" }}
      />
    );
  } else if (mimeType === "application/pdf") {
    return (
      <embed
        src={fileUrl}
        type="application/pdf"
        width="200px"
        height="200px"
        style={{ margin: "10px" }}
      />
    );
  } else {
    return (
      <a href={fileUrl} download>
        Download File
      </a>
    );
  }
};
const validationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  surname: Yup.string().required("Surname is required"),
  fatherName: Yup.string().required("Father/Husband Name is required"),
  dob: Yup.date().required("Birthdate is required"),
  gender: Yup.string().required("Gender is required"),
  panNumber: Yup.string().required("PAN is required"),
  smsPhoneNumber: Yup.string().required("Phone number is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  address: Yup.string().required("Current Address is required"),
  district: Yup.string().required("District is required"),
  state: Yup.string().required("State is required"),
  pincode: Yup.string().required("Pin is required"),
  accountHolderName: Yup.string().required("Account Holder Name is required"),
  accountNo: Yup.string().required("Account No is required"),
  accountType: Yup.string().required("Account Type is required"),
  ifscCode: Yup.string().required("IFSC Code is required"),
  declarationName: Yup.string().required("Nominee Name is required"),
  relation: Yup.string().required("Relationship is required"),
  age: Yup.string().required("Age is required"),
  guardianName: Yup.string().required("Guardian's Name is required"),
  acceptTerms: Yup.boolean().oneOf(
    [true],
    "You must accept the terms and conditions"
  ),
  permanentAddress: Yup.string().when("currentAsPermanentAddress", {
    is: false,
    then: Yup.string().required("Permanent Address is required"),
  }),
  permanentDistrict: Yup.string().when("currentAsPermanentAddress", {
    is: false,
    then: Yup.string().required("Permanent District is required"),
  }),
  permanentState: Yup.string().when("currentAsPermanentAddress", {
    is: false,
    then: Yup.string().required("Permanent State is required"),
  }),
  permanentPincode: Yup.string().when("currentAsPermanentAddress", {
    is: false,
    then: Yup.string().required("Permanent Pincode is required"),
  }),
});

const BankingForm = ({ onSubmit, initialValues, readOnly }) => {
  const [profile, setProfile] = useState(null);
  const [kycDocument, setKycDocument] = useState(null);
  const [panCard, setPanCard] = useState(null);
  const [signature, setSignature] = useState(null);
  const [isSameForPermanant, setIsSameForPermanant] = useState(false);

  useEffect(() => {
    if (initialValues) {
      setProfile(initialValues.documents?.profile || null);
      setKycDocument(initialValues.documents?.kycDocument || null);
      setPanCard(initialValues.documents?.panCard || null);
      setSignature(initialValues.documents?.signature || null);
      setIsSameForPermanant(initialValues.currentAsPermanentAddress);
    }
  }, [initialValues]);

  const handleFileChange = (event, setFile) => {
    setFile(event.target.files[0]);
  };

  const handleSubmit = async (values) => {
    const formData = new FormData();

    Object.keys(values).forEach((key) => {
      formData.append(key, values[key]);
    });

    formData.append("profile", profile);
    formData.append("kycDocument", kycDocument);
    formData.append("panCard", panCard);
    formData.append("signature", signature);

    try {
      const token = getToken();

      const response = await axios.post(
        "http://localhost:5000/api/user",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } catch (error) {
      console.error("Error submitting form", error);
    }
  };


  return (
    <Container style={{ marginTop: "30px", marginBottom: "30px" }}>
      <Formik
        initialValues={{
          id: initialValues?.id || null,
          name: initialValues?.name || "",
          surname: initialValues?.surname || "",
          fatherName: initialValues?.fatherName || "",
          dob: initialValues?.dob || "",
          gender: initialValues?.gender || "",
          panNumber: initialValues?.panNumber || "",
          smsPhoneNumber: initialValues?.smsPhoneNumber || "",
          email: initialValues?.email || "",
          address: initialValues?.address || "",
          district: initialValues?.district || "",
          state: initialValues?.state || "",
          pincode: initialValues?.pincode || "",
          accountHolderName: initialValues?.accountHolderName || "",
          accountNo: initialValues?.accountNo || "",
          accountType: initialValues?.accountType || "",
          ifscCode: initialValues?.ifscCode || "",
          declarationName: initialValues?.declarationName || "",
          relation: initialValues?.relation || "",
          age: initialValues?.age || "",
          guardianName: initialValues?.guardianName || "",
          acceptTerms: initialValues?.acceptTerms || false,
          currentAsPermanentAddress:
            initialValues?.currentAsPermanentAddress || true,
          permanentAddress: initialValues?.permanentAddress || "",
          permanentDistrict: initialValues?.permanentDistrict || "",
          permanentState: initialValues?.permanentState || "",
          permanentPincode: initialValues?.permanentPincode || "",
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ errors, touched, values }) => (
          <Form noValidate autoComplete="off">
            <Card style={{ padding: "30px" }}>
              <CardContent>
                <Typography
                  variant="h6"
                  letterSpacing={1}
                  marginBottom={4}
                  align="center"
                >
                  Membership Application Form
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="body1" gutterBottom>
                      Please fill out the form below to apply for membership
                      with the Finland Multi State Credit Co Op Society Ltd. We
                      kindly ask you to provide accurate information to ensure a
                      smooth application process.
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    {profile != null && renderFilePreview(profile)}
                    {!readOnly && (
                      <Button
                        variant="contained"
                        component="label"
                        startIcon={<PhotoCameraIcon />}
                        fullWidth
                      >
                        Upload Passport Size Photo
                        <input
                          type="file"
                          hidden
                          name="profile"
                          onChange={(event) =>
                            handleFileChange(event, setProfile)
                          }
                        />
                      </Button>
                    )}
                  </Grid>
                </Grid>
                <Grid container spacing={2} marginTop={2}>
                  <Grid item xs={12} sm={6} md={4}>
                    <Field
                      name="name"
                      as={TextField}
                      label="Name"
                      variant="outlined"
                      fullWidth
                      error={touched.name && Boolean(errors.name)}
                      helperText={touched.name && errors.name}
                      required
                      disabled={readOnly}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Field
                      name="surname"
                      as={TextField}
                      label="Surname"
                      variant="outlined"
                      fullWidth
                      error={touched.surname && Boolean(errors.surname)}
                      helperText={touched.surname && errors.surname}
                      required
                      disabled={readOnly}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Field
                      name="fatherName"
                      as={TextField}
                      label="Father / Husband Name"
                      variant="outlined"
                      fullWidth
                      error={touched.fatherName && Boolean(errors.fatherName)}
                      helperText={touched.fatherName && errors.fatherName}
                      required
                      disabled={readOnly}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Field
                      name="dob"
                      as={TextField}
                      label="Birthdate (DD-MM-YYYY)"
                      variant="outlined"
                      fullWidth
                      error={touched.dob && Boolean(errors.dob)}
                      helperText={touched.dob && errors.dob}
                      required
                      disabled={readOnly}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <FormControl component="fieldset" fullWidth>
                      <FormLabel component="legend">Gender</FormLabel>
                      <RadioGroup row aria-label="gender" name="gender">
                        <FormControlLabel
                          value="male"
                          control={<Field as={Radio} name="gender" />}
                          label="Male"
                          disabled={readOnly}
                        />
                        <FormControlLabel
                          value="female"
                          control={<Field as={Radio} name="gender" />}
                          label="Female"
                          disabled={readOnly}
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Field
                      name="panNumber"
                      as={TextField}
                      label="PAN"
                      variant="outlined"
                      fullWidth
                      error={touched.panNumber && Boolean(errors.panNumber)}
                      helperText={touched.panNumber && errors.panNumber}
                      required
                      disabled={readOnly}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Field
                      name="smsPhoneNumber"
                      as={TextField}
                      label="Phone"
                      variant="outlined"
                      fullWidth
                      error={
                        touched.smsPhoneNumber && Boolean(errors.smsPhoneNumber)
                      }
                      helperText={
                        touched.smsPhoneNumber && errors.smsPhoneNumber
                      }
                      required
                      disabled={readOnly}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Field
                      name="email"
                      as={TextField}
                      label="Email"
                      variant="outlined"
                      fullWidth
                      error={touched.email && Boolean(errors.email)}
                      helperText={touched.email && errors.email}
                      required
                      disabled={readOnly}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} marginTop={2}>
                  <Grid item xs={12}>
                    <Field
                      name="address"
                      as={TextField}
                      label="Current Address"
                      variant="outlined"
                      fullWidth
                      multiline
                      rows={3}
                      error={touched.address && Boolean(errors.address)}
                      helperText={touched.address && errors.address}
                      required
                      disabled={readOnly}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} marginTop={2}>
                  <Grid item xs={12} sm={6} md={4}>
                    <Field
                      name="district"
                      as={TextField}
                      label="District"
                      variant="outlined"
                      fullWidth
                      error={touched.district && Boolean(errors.district)}
                      helperText={touched.district && errors.district}
                      required
                      disabled={readOnly}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Field
                      name="state"
                      as={TextField}
                      label="State"
                      variant="outlined"
                      fullWidth
                      error={touched.state && Boolean(errors.state)}
                      helperText={touched.state && errors.state}
                      required
                      disabled={readOnly}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Field
                      name="pincode"
                      as={TextField}
                      label="Pin"
                      variant="outlined"
                      fullWidth
                      error={touched.pincode && Boolean(errors.pincode)}
                      helperText={touched.pincode && errors.pincode}
                      required
                      disabled={readOnly}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} marginTop={2}>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Field
                          as={Checkbox}
                          name="currentAsPermanentAddress"
                          onChange={() =>
                            setIsSameForPermanant(!isSameForPermanant)
                          }
                          disabled={readOnly}
                        />
                      }
                      label="Current As Permanent Address"
                    />
                  </Grid>
                </Grid>
                {!isSameForPermanant && (
                  <Grid container spacing={2} marginTop={2}>
                    <Grid item xs={12}>
                      <Field
                        name="permanentAddress"
                        as={TextField}
                        label="Permanent Address"
                        variant="outlined"
                        fullWidth
                        multiline
                        rows={3}
                        error={
                          touched.permanentAddress &&
                          Boolean(errors.permanentAddress)
                        }
                        helperText={
                          touched.permanentAddress && errors.permanentAddress
                        }
                        required
                        disabled={readOnly}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <Field
                        name="permanentDistrict"
                        as={TextField}
                        label="Permanent District"
                        variant="outlined"
                        fullWidth
                        error={
                          touched.permanentDistrict &&
                          Boolean(errors.permanentDistrict)
                        }
                        helperText={
                          touched.permanentDistrict && errors.permanentDistrict
                        }
                        required
                        disabled={readOnly}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <Field
                        name="permanentState"
                        as={TextField}
                        label="Permanent State"
                        variant="outlined"
                        fullWidth
                        error={
                          touched.permanentState &&
                          Boolean(errors.permanentState)
                        }
                        helperText={
                          touched.permanentState && errors.permanentState
                        }
                        required
                        disabled={readOnly}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <Field
                        name="permanentPincode"
                        as={TextField}
                        label="Permanent Pin"
                        variant="outlined"
                        fullWidth
                        error={
                          touched.permanentPincode &&
                          Boolean(errors.permanentPincode)
                        }
                        helperText={
                          touched.permanentPincode && errors.permanentPincode
                        }
                        required
                        disabled={readOnly}
                      />
                    </Grid>
                  </Grid>
                )}
                <Grid container spacing={2} marginTop={2}>
                  <Grid item xs={12} sm={6} md={4}>
                    <Field
                      name="accountHolderName"
                      as={TextField}
                      label="Account Holder Name"
                      variant="outlined"
                      fullWidth
                      error={
                        touched.accountHolderName &&
                        Boolean(errors.accountHolderName)
                      }
                      helperText={
                        touched.accountHolderName && errors.accountHolderName
                      }
                      required
                      disabled={readOnly}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Field
                      name="accountNo"
                      as={TextField}
                      label="Account No"
                      variant="outlined"
                      fullWidth
                      error={touched.accountNo && Boolean(errors.accountNo)}
                      helperText={touched.accountNo && errors.accountNo}
                      required
                      disabled={readOnly}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <FormControl
                      variant="outlined"
                      fullWidth
                      required
                      disabled={readOnly}
                    >
                      <InputLabel>Type of Account</InputLabel>
                      <Field
                        name="accountType"
                        as={Select}
                        label="Type of Account"
                      >
                        <MenuItem value="Saving">Saving</MenuItem>
                        <MenuItem value="Current">Current</MenuItem>
                      </Field>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Field
                      name="ifscCode"
                      as={TextField}
                      label="IFSC Code"
                      variant="outlined"
                      fullWidth
                      error={touched.ifscCode && Boolean(errors.ifscCode)}
                      helperText={touched.ifscCode && errors.ifscCode}
                      required
                      disabled={readOnly}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} marginTop={2}>
                  <Grid item xs={12}>
                    <Typography variant="body1" marginTop={2} gutterBottom>
                      To receive SMS and e-service notifications, please confirm
                      your phone number below. We will use this number to send
                      you important updates and information.
                    </Typography>
                    <Field
                      name="smsPhoneNumber"
                      as={TextField}
                      variant="outlined"
                      fullWidth
                      required
                      disabled={readOnly}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} marginTop={2}>
                  <Grid item xs={12}>
                    <Typography variant="body1" gutterBottom>
                      Please declare your nominee for the share(s) you purchase.
                      The nominee will inherit your shares in the event of your
                      death.
                    </Typography>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={6} md={3}>
                        <Field
                          name="declarationName"
                          as={TextField}
                          label="Nominee Name"
                          variant="outlined"
                          fullWidth
                          required
                          disabled={readOnly}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                        <Field
                          name="relation"
                          as={TextField}
                          label="Relationship"
                          variant="outlined"
                          fullWidth
                          required
                          disabled={readOnly}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                        <Field
                          name="age"
                          as={TextField}
                          label="Nominee's Age"
                          variant="outlined"
                          fullWidth
                          required
                          disabled={readOnly}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                        <Field
                          name="guardianName"
                          as={TextField}
                          label="Guardian's Name (if nominee is a minor)"
                          variant="outlined"
                          fullWidth
                          required
                          disabled={readOnly}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container spacing={2} marginTop={2}>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Field
                          as={Checkbox}
                          name="acceptTerms"
                          color="primary"
                          disabled={readOnly}
                        />
                      }
                      label="I accept the terms and conditions"
                      error={touched.acceptTerms && Boolean(errors.acceptTerms)}
                      helperText={touched.acceptTerms && errors.acceptTerms}
                      required
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} marginTop={2}>
                  <Grid item xs={12} sm={6} md={4}>
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                      justifyContent="center"
                    >
                      {kycDocument != null && renderFilePreview(kycDocument)}
                      {!readOnly && (
                        <Button
                          variant="contained"
                          component="label"
                          startIcon={<UploadFileIcon />}
                          fullWidth
                        >
                          Upload KYC Document
                          <input
                            type="file"
                            hidden
                            name="kycDocument"
                            onChange={(event) =>
                              handleFileChange(event, setKycDocument)
                            }
                          />
                        </Button>
                      )}
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                      justifyContent="center"
                    >
                      {panCard != null && renderFilePreview(panCard)}
                      {!readOnly && (
                        <Button
                          variant="contained"
                          component="label"
                          startIcon={<UploadFileIcon />}
                          fullWidth
                        >
                          Upload PAN Card
                          <input
                            type="file"
                            hidden
                            name="panCard"
                            onChange={(event) =>
                              handleFileChange(event, setPanCard)
                            }
                          />
                        </Button>
                      )}
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                      justifyContent="center"
                    >
                      {signature != null && renderFilePreview(signature)}
                      {!readOnly && (
                        <Button
                          variant="contained"
                          component="label"
                          startIcon={<UploadIcon />}
                          fullWidth
                        >
                          Upload Signature
                          <input
                            type="file"
                            hidden
                            name="signature"
                            onChange={(event) =>
                              handleFileChange(event, setSignature)
                            }
                          />
                        </Button>
                      )}
                    </Box>
                  </Grid>
                </Grid>
                {!readOnly && (
                  <Grid
                    container
                    spacing={2}
                    justifyContent="center"
                    marginTop={4}
                  >
                    <Grid item xs={12} sm={6} md={4}>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        fullWidth
                      >
                        Submit Application
                      </Button>
                    </Grid>
                  </Grid>
                )}
              </CardContent>
            </Card>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default BankingForm;
