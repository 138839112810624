import {GET_USERS, GET_USERS_FAIL, GET_USERS_SUCCESS, LOGIN_WITH_OTP, LOGIN_WITH_OTP_FAIL, LOGIN_WITH_OTP_SUCCESS, RESET_USERS} from "../actions/users"

const initialState = {
  users: {
    data: [],
    loading: false,
    error: null,
    success: false
  }
}

const userReducer = (state = initialState, action) => {
  switch(action.type) {
    case GET_USERS:
      return {
        ...state,
        users: {
          ...state.users,
          loading: true,
          success: false,
          error: null
        }
      }
    case GET_USERS_SUCCESS:
      return {
        ...state,
        users: {
          ...state.users,
          data: action.payload,
          loading: false,
          success: true,
          error: null
        }
      }
    case GET_USERS_FAIL:
      return {
        ...state,
        users: {
          ...state.users,
          loading: false,
          success: false,
          error: action.payload
        }
      }
    case RESET_USERS:
      return {
        ...state,
        users: {
          ...state.users,
          loading: false,
          error: null,
          success: false
        }
      }
    default:
      return state;
  }
}

export default userReducer;