export const GET_BLOGS = 'GET_BLOGS'
export const GET_BLOGS_SUCCESS = 'GET_BLOGS_SUCCESS'
export const GET_BLOGS_FAIL = 'GET_BLOGS_FAIL'
export const RESET_BLOGS = 'RESET_BLOGS'

export const getBlogs = (payload) => ({
  type: GET_BLOGS,
  payload
})

export const getBlogsSuccess = (data) => ({
  type: GET_BLOGS_SUCCESS,
  paylaod: data
})

export const getBlogsFail = (error) => ({
  type: GET_BLOGS_FAIL,
  payload: error
})

export const resetBlogs = () => ({
  type: RESET_BLOGS
})
