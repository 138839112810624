import React, { useState } from "react";
import {
  Container,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Box,
  Grid,
  TextField,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { makeStyles } from "@mui/styles";
import LoadMoreButton from "./LoadMoreButton";

const useStyles = makeStyles((theme) => ({
  container: {
    // border: "1px solid #C5DDF4",
    padding: theme.spacing(4),
    color: "#000",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
    },
  },
  title: {
    color: "#1976d2",
    fontFamily: "sans-serif",
    fontSize: "2rem",
    marginBottom: theme.spacing(2),
    fontWeight: "bold",
  },
  subtitle: {
    color: "#424242",
    marginBottom: theme.spacing(4),
  },
  searchBox: {
    marginBottom: theme.spacing(4),
    backgroundColor: "#fff",
    borderRadius: theme.spacing(1),
  },
  accordion: {
    backgroundColor: "#fff",
    color: "#000",
    border: "1px solid #e0e0e0",
    borderRadius: theme.spacing(1),
    boxShadow: "0 2px 10px rgba(0,0,0,0.1)",
    "&:before": {
      display: "none",
    },
    "&:not(:last-child)": {
      marginBottom: theme.spacing(2),
    },
  },
  accordionSummary: {
    backgroundColor: "#f9f9f9",
    borderBottom: "1px solid #e0e0e0",
    "&:hover": {
      backgroundColor: "#f1f1f1",
    },
  },
  accordionDetails: {
    backgroundColor: "#fff",
    textAlign:"left",
    padding: theme.spacing(2),
  },
  button: {
    marginTop: theme.spacing(2),
    color: "#fff",
    backgroundColor: "#1976d2",
    borderColor: "#1976d2",
    "&:hover": {
      backgroundColor: "#155a9b",
      borderColor: "#155a9b",
    },
  },
}));

const FAQ = ({ faqs }) => {
  const classes = useStyles();
  const [visibleFaqs, setVisibleFaqs] = useState(4);
  const [searchTerm, setSearchTerm] = useState("");

  const handleLoadMore = () => {
    setVisibleFaqs((prev) => prev + (faqs.length - visibleFaqs));
  };

  const filteredFaqs = faqs?.filter((faq) =>
    faq.question.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className={classes.container}>
      <p className={classes.title}>Frequently Asked Questions</p>
      <Typography
        variant="subtitle1"
        className={classes.subtitle}
        style={{ marginTop: "10px" }}
      >
        Still have any questions? Contact our Team via info@finlandhub.com
      </Typography>
      <TextField
        style={{ marginTop: "10px" }}
        label="Search"
        variant="outlined"
        fullWidth
        className={classes.searchBox}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      <Grid container spacing={2} style={{ marginTop: "10px" }}>
        {filteredFaqs?.slice(0, visibleFaqs).map((faq, index) => (
          <Grid item xs={12} key={index}>
            <Accordion className={classes.accordion}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon style={{ color: "#1976d2" }} />}
                aria-controls={`panel${index}-content`}
                id={`panel${index}-header`}
                className={classes.accordionSummary}
              >
                <Typography style={{
                  color: "#167CE8",
                  fontWeight: 400,
                  textAlign:"left",
                }}>{faq.question}</Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.accordionDetails}>
                <Typography>{faq.answer}</Typography>
              </AccordionDetails>
            </Accordion>
          </Grid>
        ))}
      </Grid>
      {visibleFaqs < filteredFaqs?.length && (
        <div style={{ marginTop: "30px" }}>
          <LoadMoreButton
            onClick={handleLoadMore}
            name={"Load More"}
          ></LoadMoreButton>
        </div>
      )}
    </div>
  );
};

export default FAQ;
