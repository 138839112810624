import React, { useEffect, useState } from "react";
import {
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Box,
  IconButton,
  Modal,
  Grid,
  useMediaQuery,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import * as XLSX from "xlsx";
import DownloadIcon from "@mui/icons-material/Download";
import CloseIcon from "@mui/icons-material/Close";

const useStyles = makeStyles((theme) => ({
  reportContainer: {
    marginTop: "40px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
  },
  report: {
    background: "#ffffff",
    width: "80vw",
    padding: "30px",
    maxWidth: "100%",
    margin: "auto",
    maxHeight: "90vh",
    borderRadius: "8px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "95vw",
      padding: "15px",
    },
  },
  tableContainer: {
    maxHeight: "70vh",
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      width: "8px",
    },
    "&::-webkit-scrollbar-track": {
      background: "#f1f1f1",
      borderRadius: "8px",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#1976d2",
      borderRadius: "8px",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      background: "#115293",
    },
  },
  downloadName:{
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  tableHeader: {
    backgroundColor: "#1976d2",
  },
  tableCell: {
    fontWeight: "bold",
    color: "#000",
  },
  reportTitle: {
    color: "#FF5722",
    fontWeight: "bold",
    textAlign: "center",
    fontSize: "1.5rem",
    margin: "1.5rem 0",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.2rem",
    },
  },
  icon: {
    padding: "4px",
    fontSize: "2.5rem",
    borderRadius: "50%",
    color: "#fff",
    backgroundColor: "blue",
    "&:hover": {
      backgroundColor: "#FF5722",
      color: "#fff",
    },
  },
  closeIcon: {
    cursor: "pointer",
    color: "#ff0000",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    outline: 0,
  },
  modalBackdrop: {
    backdropFilter: "blur(5px)",
  },
  flexHead: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
}));

const MonthlyLoanReport = ({
  productType,
  loanAmount,
  monthlyInterestRate,
  loanTerm,
  setIsModalOpen,
  EMI,
  totalInterest,
}) => {
  const classes = useStyles();
  const [reportData, setReportData] = useState([]);
  const [open, setOpen] = useState(true);
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));

  useEffect(() => {
    const calculateReportData = () => {
      let data = [];
      const principal = parseFloat(loanAmount);
      const termInMonths = parseFloat(loanTerm);
      let balance = principal;
      let newPrincipal = principal;

      for (let month = 1; month <= termInMonths; month++) {
        const interestForMonth = (balance * monthlyInterestRate).toFixed(2);
        const principalPayment = (EMI - interestForMonth).toFixed(2);
        balance -= principalPayment;
        let openingBalance = newPrincipal.toFixed(2);
        let closingBalance = (newPrincipal - principalPayment).toFixed(2);
        newPrincipal = parseFloat(closingBalance);

        const reportDate = new Date();
        reportDate.setMonth(reportDate.getMonth() + month);

        if (newPrincipal < 0) {
          closingBalance = "0.00";
          newPrincipal = 0;
        }

        data.push({
          month,
          date: reportDate.toLocaleDateString(),
          principalPayment,
          interestPayment: interestForMonth,
          totalPayment: EMI.toFixed(2),
          openingBalance,
          closingBalance,
        });
      }

      setReportData(data);
    };

    calculateReportData();
  }, [loanAmount, monthlyInterestRate, loanTerm, EMI, totalInterest]);

  const downloadReport = () => {
    const ws = XLSX.utils.json_to_sheet(reportData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Loan Report");
    XLSX.writeFile(wb, "Loan_Report.xlsx");
  };

  return (
    <Modal
      open={open}
      onClose={() => setIsModalOpen(false)}
      className={classes.modal}
      BackdropProps={{ className: classes.modalBackdrop }}
    >
      <Container maxWidth="100%" className={classes.reportContainer}>
        <Box className={classes.report}>
          <Grid container className={classes.flexHead}>
            <Grid item xs={4}>
              <IconButton
                className={classes.downloadButton}
                onClick={downloadReport}
              >
                <Typography className={classes.downloadName} variant="h6" fontWeight={"bold"}>
                  Download
                </Typography>
                &nbsp;
                <DownloadIcon className={classes.icon} />
              </IconButton>
            </Grid>
            <Grid item xs={4} style={{ textAlign: "center" }}>
              <Typography variant={isMobile ? "h6" : "h5"} className={classes.reportTitle}>
                {productType}
              </Typography>
            </Grid>
            <Grid item xs={4} style={{ textAlign: "right" }}>
              <IconButton
                className={classes.closeIcon}
                onClick={() => {
                  setIsModalOpen(false);
                  setOpen(false);
                }}
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
          <TableContainer component={Paper} className={classes.tableContainer}>
            <Table stickyHeader>
              <TableHead className={classes.tableHeader}>
                <TableRow>
                  <TableCell className={classes.tableCell}>Month</TableCell>
                  <TableCell className={classes.tableCell}>EMI Date</TableCell>
                  <TableCell className={classes.tableCell}>Opening Balance</TableCell>
                  <TableCell className={classes.tableCell}>EMI</TableCell>
                  <TableCell className={classes.tableCell}>Principal Payment (₹)</TableCell>
                  <TableCell className={classes.tableCell}>Interest Payment (₹)</TableCell>
                  <TableCell className={classes.tableCell}>Closing Balance (₹)</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {reportData.map((row) => (
                  <TableRow key={row.month}>
                    <TableCell>{row.month}</TableCell>
                    <TableCell>{row.date}</TableCell>
                    <TableCell>{parseFloat(row.openingBalance).toFixed(2)}</TableCell>
                    <TableCell>{parseFloat(row.totalPayment).toFixed(2)}</TableCell>
                    <TableCell>{parseFloat(row.principalPayment).toFixed(2)}</TableCell>
                    <TableCell>{parseFloat(row.interestPayment).toFixed(2)}</TableCell>
                    <TableCell>{parseFloat(row.closingBalance).toFixed(2)}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Container>
    </Modal>
  );
};

export default MonthlyLoanReport;
