import React from "react";
import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import CircleIcon from "@mui/icons-material/Circle";

const useStyles = makeStyles((theme) => ({
  stepsContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  step: {
    display: "flex",
    alignItems: "flex-start",
    marginBottom: "35px",
    position: "relative",
    paddingLeft: "25px",
    cursor: "pointer",
  },
  stepIcon: {
    color: "#FF683E",
    position: "absolute",
    left: 0,
    top: 0,
  },
  stepNumber: {
    fontWeight: "bold",
    marginBottom: "5px",
    textAlign: "left",
  },
  stepDescription: {
    textAlign: "left",
  },
  stepLine: {
    position: "absolute",
    left: 10,
    top: 20,
    height: "calc(10vh - 20px)",
    borderLeft: "2px solid #FF683E",
  },
  addMargin: {
    marginLeft: "40px",
    transition: "transform 0.3s ease, box-shadow 0.3s ease",
    "&:hover": {
      transform: "scale(1.02)",
    },
  },
}));

const HowToInvestStepComponent = ({ steps }) => {
  const classes = useStyles();

  return (
    <Box className={classes.stepsContainer}>
      {steps?.map((step, i) => (
        <Box key={i} className={classes.step}>
          <CircleIcon className={classes.stepIcon} />
          <Box className={classes.addMargin}>
            <Typography variant="body1" className={classes.stepNumber}>
              Step {step?.stepNumber ? step?.stepNumber : 0}
            </Typography>
            <Typography variant="body2" className={classes.stepDescription}>
              {step?.description ? step?.description : "Step Description"}
            </Typography>
          </Box>
          <Box className={classes.stepLine}></Box>
        </Box>
      ))}
    </Box>
  );
};

export default HowToInvestStepComponent;
