import { takeEvery, put, call } from "redux-saga/effects";
import { GET_ENQUIRIES, ENQUIRIES_FETCH_FAIL, ENQUIRIES_FETCH_SUCCESS } from "../actions/enquiry";
import { doGet } from '../../request';
import toast from "react-hot-toast";

function* fetchEnquiries({ payload }) {
  try {
    const url = `enqury?offset=${payload?.offset || 0}&limit=${payload?.limit || 10}&search=${payload?.search || ""}`;
    const response = yield call(doGet, url);
    yield put({ type: ENQUIRIES_FETCH_SUCCESS, payload: response });
  } catch (error) {
    if (error?.statusCode === 403) {
      window.location.href = "/admin/login";
    } else if (error?.statusCode === 400) {
      toast.error(error.message, { duration: 4000 });
    }
    yield put({ type: ENQUIRIES_FETCH_FAIL, payload: error.message || "An error occurred" });
  }
}

export default function* enquiryWatcher() {
  yield takeEvery(GET_ENQUIRIES, fetchEnquiries);
}
