import { Box, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import { QRCode } from "react-qrcode-logo";
import DownloadAppStore from "../Custom/DownloadAppStore";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  footer: {
    backgroundColor: "#E3F2FD",
    padding: theme.spacing(4),
    borderRadius: "30px 30px 0px 0px",
    fontFamily: "sans-serif",
  },
  logo: {
    display: "flex",
    justifyContent: "start",
    marginBottom: theme.spacing(2),
    "@media (max-width: 600px)": {
      fontSize: "0.875rem",
    },
  },
  logoImg: {
    width: "100%",
    display: "flex",
    marginBottom: theme.spacing(2),
    "@media (max-width: 700px)": {
      justifyContent: "center",
    },
  },
  logoText: {
    "@media (max-width: 700px)": {
      textAlign: "center",
    },
  },
  logoHeading: {
    fontSize: "1.2rem",
    fontFamily: "sans-serif",
    fontWeight: "bold",
    "@media (max-width: 700px)": {
      textAlign: "center",
    },
  },
  regText: {
    fontFamily: "sans-serif",
    fontWeight: "500",
    wordBreak: "break-all",
    "@media (max-width: 700px)": {
      textAlign: "center",
    },
  },
  addressHeading: {
    fontWeight: "bold",
    "@media (max-width: 700px)": {
      textAlign: "center",
    },
  },
  addressPart: {
    marginTop: "30px",
    "@media (max-width: 700px)": {
      textAlign: "center",
    },
  },
  section: {
    marginBottom: theme.spacing(2),
    fontWeight: "bold",
    "@media (max-width: 700px)": {
      textAlign: "center",
    },
  },
  p: {
    display: "block",
    fontFamily: "sans-serif",
    marginBottom: theme.spacing(1),
    color: theme.palette.text.primary,
    textDecoration: "none",
    cursor: "pointer",
    "&:hover": {
      color: theme.palette.primary.main,
      fontWeight: "bold",
    },
    "@media (max-width: 700px)": {
      textAlign: "center",
    },
  },
  qrCode: {
    margin: theme.spacing(2, 0),
    display: "flex",
    justifyContent: "center",
  },
  button: {
    marginTop: theme.spacing(2),
    display: "flex",
    justifyContent: "center",
  },
  copyright: {
    marginTop: theme.spacing(4),
    textAlign: "center",
  },
  address: {
    marginBottom: theme.spacing(2),
  },
}));

const pages = [
  { name: "Home", id: "home" },
  {
    name: "Deposits",
    id: "deposits",
    subtabs: [
      "Saving Account",
      "Current Account",
      "Fixed Deposit",
      "Recurring Deposit",
      "Monthly Income Scheme",
      "Daily Deposit",
    ],
  },
  {
    name: "Loans",
    id: "loans",
    subtabs: [
      "Gold Loan",
      "Vehicle Loan",
      "Loan Against Deposit",
      "Personal Loan",
      "Business Loan",
    ],
  },
  { name: "Calculators", id: "calculators" },
  { name: "Forms" },
  { name: "Blogs", id: "blogs" },
  { name: "About Us", id: "aboutUs" },
  { name: "Contact Us", id: "contactUs" },
];

const products = [
      // "Saving Account",
      // "Current Account",
      "Fixed Deposit",
      "Recurring Deposit",
      "Monthly Income Scheme",
      "Daily Deposit",
      "Gold Loan",
      "Vehicle Loan",
      // "Loan Against Deposit",
      "Personal Loan",
      "Business Loan",
];

const Footer = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const handleTabClick = (page, subtab) => {
    if (subtab) {
      navigate(`/${subtab?.toLowerCase()?.replace(/\s/g, "-")}`);
      window.scrollTo({ top: 0, behavior: "smooth" });
    } else {
      if (page.name === "Home") {
        window.scrollTo({ top: 0, behavior: "smooth" });
        navigate(`/#${page.id}`);
      } else if (page.name === "Calculators" || page.name === "Blogs") {
        navigate(`/#${page.id}`);
        setTimeout(() => {
          const element = document.getElementById(page.id.toLowerCase());
          if (element) {
            element.scrollIntoView({ behavior: "smooth" });
          }
        }, 100);
      } else if (page.name === "Deposits" || page.name === "Loans") {
        navigate("/");
        window.scrollTo({ top: 0, behavior: "smooth" });
      } else if (page.name === "About Us") {
        navigate("/about");
        window.scrollTo({ top: 0, behavior: "smooth" });
      } else if (page.name === "Contact Us") {
        navigate("/contact");
        window.scrollTo({ top: 0, behavior: "smooth" });
      } else {
        navigate("/");
        window.scrollTo({ top: 0, behavior: "smooth" });
        const element = document.getElementById("offeringProduct");
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      }
    }
  };

  return (
    <Box component="footer" className={classes.footer}>
      <Grid container spacing={1}>
        <div className={classes.logoImg}>
          <img src="logo.png" height={"50px"} alt="Logo" />
        </div>
        <Grid item xs={12} md={3} lg={5}>
          <div className={classes.logo}>
            <div className={classes.logoText}>
              <p className={classes.logoHeading}>
                THE FINLAND CO. OP. CREDIT SOCIETY LIMITED
              </p>
              <p className={classes.regText}>
                Reg. No. : REG/AHD/C/SE(SH)/46749/2022
              </p>
              <div className={classes.addressPart}>
                <p variant="h6" className={classes.section}>
                  Head Office
                </p>
                <p variant="body2" className={classes.address}>
                  410 , Central Business Space, Opp. Fortune Landmark,
                  Usmanpura, Ahmedabad - 380013 , Gujarat, India
                </p>
                <p variant="h6" className={classes.section}>
                  Nava-Vadaj Branch
                </p>
                <p variant="body2" className={classes.address}>
                  C-9 Mangla Gauri Residency, Opp. Panchal Wadi, V.N. Greens
                  Road, Nava-Vadaj, Ahmedabad - 380013
                </p>
                <p variant="h6" className={classes.section}>
                  Need Help?
                </p>
                <p variant="body2">
                  Mail us at: Info@finlandhub.com
                  <br />
                  Call us on: 7201000851 / 2 / 3 / 4
                </p>
              </div>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} md={2} lg={1.5}>
          <Typography variant="h6" className={classes.section}>
            {/* Company */}
          </Typography>
          {pages.map((page) => (
            <Typography
              key={page.name}
              className={classes.p}
              onClick={() => handleTabClick(page)}
            >
              {page.name}
            </Typography>
          ))}
        </Grid>
        <Grid item xs={12} md={2} lg={1.5}>
          <Typography variant="h6" className={classes.section}>
            {/* Documentation */}
          </Typography>
          {products.map(
            (text, index) => (
              <Typography
                key={index}
                className={classes.p}
                onClick={() => navigate(`/${text?.split(" ")?.join("-")?.toLowerCase()}`)}
              >
                {text}
              </Typography>
            )
          )}
        </Grid>
        <Grid item xs={12} md={2} lg={1.5}>
          <Typography variant="h6" className={classes.section}>
            {/* Social */}
          </Typography>
          {["Facebook", "Instagram", "LinkedIn"].map(
            (text, index) => (
              <Typography key={index} className={classes.p}>
                {text}
              </Typography>
            )
          )}
        </Grid>
        <Grid item xs={12} md={2} lg={2}>
          <div className={classes.qrCode}>
            <img
              src="qrcode.png"
              height={130}
            />
          </div>
          <div className={classes.button}>
            <DownloadAppStore />
          </div>
        </Grid>
      </Grid>
      <Typography
        variant="body2"
        color="textSecondary"
        className={classes.copyright}
      >
        © finlandccs Inc. All Rights Reserved 2024
      </Typography>
    </Box>
  );
};

export default Footer;
