import React from "react";
import { makeStyles } from "@mui/styles";
import BannerInfo from "./BannerInfo";
import BannerProductInfo from "./BannerProductInfo";

// Create a useStyles hook using makeStyles
const useStyles = makeStyles((theme) => ({
  banner: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.primary,
    height: "fit-content",
    minHeight: "70vh",
    display: "flex",
    gap: theme.spacing(4),
    justifyContent: "space-between",
    alignItems: "center",
    position: "relative",
    zIndex: 11,
    [theme.breakpoints.down("lg")]: {
      flexDirection: "column",
      gap: theme.spacing(2),
    },
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(1),
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      gap: theme.spacing(1),
      padding: theme.spacing(1),
    },
  },
}));

const Banner = ({ setIsDepositClicked }) => {
  const classes = useStyles();

  return (
    <div>
      <div className={classes.banner}>
        <BannerInfo />
        <BannerProductInfo setIsDepositClicked={setIsDepositClicked} />
      </div>
    </div>
  );
};

export default Banner;
