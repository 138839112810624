import React from "react";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  cta: {
    border: "none",
    background: "none",
    fontWeight: "bold",
    cursor: "pointer",
    position: "relative",
    "& span": {
      paddingBottom: "7px",
      letterSpacing: "2px",
      fontSize: "14px",
      paddingRight: "15px",
      textTransform: "uppercase",
    },
    "& svg": {
      transform: "translateX(-8px)",
      transition: "all 0.3s ease",
    },
    "&:hover svg": {
      transform: "translateX(0)",
    },
    "&:active svg": {
      transform: "scale(0.9)",
    },
    "&:after": {
      content: '""',
      position: "absolute",
      width: "100%",
      transform: "scaleX(0)",
      height: "2px",
      bottom: "-10px",
      left: "0",
      backgroundColor: "#000000",
      transformOrigin: "bottom right",
      transition: "transform 0.25s ease-out",
    },
    "&:hover:after": {
      transform: "scaleX(1)",
      transformOrigin: "bottom left",
    },
  },
});

const CustomButton = ({ children, onClick }) => {
  const classes = useStyles();

  return (
    <button className={classes.cta} onClick={onClick}>
      <span>{children}</span>
      <svg
        id="arrow-horizontal"
        xmlns="http://www.w3.org/2000/svg"
        width="30"
        height="10"
        viewBox="0 0 46 16"
      >
        <path
          id="Path_10"
          data-name="Path 10"
          d="M8,0,6.545,1.455l5.506,5.506H-30V9.039H12.052L6.545,14.545,8,16l8-8Z"
          transform="translate(30)"
        ></path>
      </svg>
    </button>
  );
};

export default CustomButton;
