import React from "react";
import { makeStyles } from "@mui/styles";
import { Typography, Grid, Box } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  container: {
    textAlign: "center",
    maxWidth: "1200px",
    margin: "0 auto",
  },
  sectionOne: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "80%",
    margin: "30px auto",
    gap: 20,
    "@media (max-width: 500px)": {
      width: "90vw",
    },
    "@media (max-width: 1200px)": {
      width: "90vw",
    },
  },
  sectionTwo: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "80%",
    margin: "40px auto",
    gap: 20,
    "@media (max-width: 500px)": {
      width: "90vw",
    },
    "@media (max-width: 1200px)": {
      width: "90vw",
    },
  },
  sectionThree: {
    padding: theme.spacing(2), // Add padding for spacing
  },
  featuresContainer: {
    flexGrow: 1,
  },
  featureItem: {
    padding: theme.spacing(2), // Add padding for spacing between grid items
  },
  featureBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    gap: theme.spacing(2), // Add gap between icon and text
    height: "100%", // Ensure the feature box fills the grid item's height
    border: "1px solid #ccc", // Add border for visualization (optional)
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(2), // Add padding for inner content
    boxShadow: theme.shadows[2], // Add shadow for depth (optional)
  },
  featureIcon: {
    marginBottom: theme.spacing(1), // Add margin bottom to the icon
    fontSize: "20px", // Set icon size
  },
  featureTitle: {
    fontFamily: "sans-serif",
    margin: 0, // Remove default margin
    marginBottom: theme.spacing(1), // Add margin bottom for spacing
  },
  featureDescription: {
    
    fontFamily: "sans-serif",
    margin: 0, // Remove default margin
  },
  subHeading: {
    fontFamily: "sans-serif",
  },
  heading: {
    color: '#000000de',
    fontSize: '1.2rem',
    
    fontFamily: "sans-serif",
  }

}));

const Overview = ({overview}) => {
  const classes = useStyles();

  const features = [
    {
      title: "Earn as high as 9.10%* p.a.",
      description:
        "Unlock the potential to earn interest rates up to 9.10%* p.a. with our Fixed Investment Plan. Get attractive returns on your investment.",
      icon: "💰", // Replace with actual icon component
    },
    {
      title: "Flexible Instalment Options",
      description:
        "Choose from flexible instalment options from 12 to 48 months to suit your financial goals and aspirations.",
      icon: "📊", // Replace with actual icon component
    },
    {
      title: "Diverse Tenure Options",
      description:
        "Fixed Investment Plan tenure options ranges from 23 to 59 months, offering diverse investment durations.",
      icon: "📅", // Replace with actual icon component
    },
    {
      title: "Flexibility to Convert into FD",
      description:
        "Investors have the option to either convert their FIP into an FD (Fixed Deposit) or redeem the full amount on maturity.",
      icon: "🔄", // Replace with actual icon component
    },
    {
      title: "Security and Credibility",
      description:
        'Rest assured with a higher credit rating of "ICRA]AA+ (Stable)" by ICRA and Rated "IND AA+/Stable" by India Ratings and Research.',
      icon: "🔒", // Replace with actual icon component
    },
  ];

  return (
    <div className={classes.container}>
      <div className={classes.sectionOne}>
        <h3  className={classes.heading}>
          {overview?.title}
        </h3>
        <p className={classes.subHeading}>
          {overview?.description}
        </p>
      </div>

      <div className={classes.sectionThree}>
    </div>
    </div>
  );
};

export default Overview;
