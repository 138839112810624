import React from "react";
import { makeStyles } from "@mui/styles";
import { Container, Typography, Box, Chip, Grid, List, ListItem } from "@mui/material";
import { useParams } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
    fontFamily: "sans-serif",
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(3),
    },
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
    },
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(1),
    },
  },
  title: {
    color: "#0561AB",
    fontWeight: "bold",
    fontSize: "2.5rem",
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      fontSize: "2.25rem",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "2rem",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.75rem",
    },
  },
  content: {
    marginTop: theme.spacing(4),
  },
  image: {
    width: "100%",
    height: "auto",
    margin: "30px 0",
    objectFit: "cover",
    borderRadius: theme.shape.borderRadius,
  },
  description: {
    marginBottom: theme.spacing(2),
    fontSize: "1rem",
    lineHeight: "1.6",
    textAlign: "justify",  // Justify the text content
    textJustify: "inter-word",  // Adjust spacing between words for full justification
    [theme.breakpoints.down("md")]: {
      fontSize: "0.95rem",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.9rem",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.85rem",
    },
  },
  subheading: {
    fontWeight: "bold",
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
    fontSize: "1.5rem",
    color: "#333",
    textAlign: "justify",  // Justify subheadings as well
    textJustify: "inter-word",  // Adjust spacing between words for full justification
    [theme.breakpoints.down("md")]: {
      fontSize: "1.4rem",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.3rem",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.2rem",
    },
  },
  list: {
    marginBottom: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    textAlign: "justify",  // Justify list items
    textJustify: "inter-word",  // Adjust spacing between words for full justification
    [theme.breakpoints.down("sm")]: {
      paddingLeft: theme.spacing(2),
    },
  },
  highlightedText: {
    backgroundColor: "#f4f4f4",
    padding: theme.spacing(1),
    borderLeft: "4px solid #0561AB",
    marginBottom: theme.spacing(2),
    fontSize: "1rem",
    textAlign: "justify",  // Justify highlighted text
    textJustify: "inter-word",  // Adjust spacing between words for full justification
    [theme.breakpoints.down("md")]: {
      fontSize: "0.95rem",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.9rem",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.85rem",
    },
  },
  quote: {
    fontStyle: "italic",
    backgroundColor: "#f4f4f4",
    padding: theme.spacing(2),
    margin: theme.spacing(2, 0),
    borderLeft: "5px solid #0561AB",
    fontSize: "1rem",
    textAlign: "justify",  // Justify quotes
    textJustify: "inter-word",  // Adjust spacing between words for full justification
    [theme.breakpoints.down("md")]: {
      fontSize: "0.95rem",
      padding: theme.spacing(1.5),
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.9rem",
      padding: theme.spacing(1),
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.85rem",
      padding: theme.spacing(0.75),
    },
  },
  video: {
    width: "100%",
    height: "auto",
    margin: "30px 0",
    [theme.breakpoints.down("sm")]: {
      margin: "20px 0",
    },
  },
  tags: {
    display: "flex",
    flexWrap: "wrap",
    gap: theme.spacing(1),
    marginTop: theme.spacing(2),
    justifyContent: "flex-start",
  },
}));


const blogs = [{
  id : 1,
  title: "7 Reasons to Invest in Fixed Deposits",
  content: [
    { type: "text", value: "A fixed deposit is one of the safest and surest investment instruments provided by banks. It provides investors with a good interest rate, guaranteed returns, and other benefits like liquidity, safety, etc. Read on to discover all the benefits of an FD." },
    { type: "image", value: "/blog/Fixed Deposit.jpg" },
    { type: "subheading", value: "Why Invest in Fixed Deposits?" },
    { type: "text", value: "A fixed deposit is one of the safest and surest investment instruments provided by banks. While there are many other investment routes out there, this traditional instrument still stands as one of the best choices among most investors. Here are a few reasons why:" },
    
    { type: "heading", value: "1. Guaranteed Returns" },
    { type: "text", value: "Unlike other forms of investments, FD accounts are not influenced by market dynamics. This is the primary reason why they are considered a safe investment option. Investors can be rest assured that their hard-earned money will yield good returns, depending upon the rate of interest offered by the bank. Moreover, it proves to be of great help when you have set financial goals since you know how much your money will grow within a certain period of time." },

    { type: "heading", value: "2. Good Interest Rate" },
    { type: "text", value: "Another prime reason why a fixed deposit is so popular is that it offers good interest rates. The FD interest rate is usually better than savings accounts. The FD rate of interest for senior citizens is also higher as compared to other investors." },

    { type: "heading", value: "3. Easy to Invest In FD" },
    { type: "text", value: "You can invest in a fixed deposit without any hassles. All you need to do is fill in and submit an application form along with the payment. You can open an FD account online from the comfort of your home or simply visit your nearest branch. You even have the option to invest in multiple FDs at once, by opting for the multi-deposit facility." },

    { type: "heading", value: "4. Easy to Monitor" },
    { type: "text", value: "Since the FD interest rate remains constant throughout the tenure, you need not monitor its performance regularly. However, you need to keep an eye on its maturity date. Some banks also offer an auto-renewal facility. With this facility, the bank will automatically renew the FD when it matures. Note: If you have opted for auto-renewal, you can modify the command before the deposit matures." },

    { type: "heading", value: "5. Allows Premature Withdrawal" },
    { type: "text", value: "FDs can be withdrawn as and when needed. Although you will be charged a penalty, you will always have a sum of money to depend upon." },

    { type: "heading", value: "6. Offers Nomination and Sweep-in facility" },
    { type: "text", value: "Fixed deposits offer a nomination facility. You can provide the nomination at the initial stage itself while opening the FD account. However, as an account holder, you have the right to change the nomination at any time as per your convenience." },
    { type: "text", value: "You also avail the sweep-in facility. With a sweep-in facility, you don't have to worry about having insufficient funds in your account for a transaction or face the embarrassment of a cheque bouncing." },

    { type: "text", value: "Since ages, fixed deposits have been the go-to investment product for most Indians. Highly versatile and flexible – this financial product helps you create a habit of dedicated savings." },

    { type: "text", value: "Want to know more about The Finland Co. Op. Credit Society Fixed Deposits? Click here." },

    { type: "text", value: "Learn more about how to fill a cheque and (Fixed Deposits) FD interest calculator." }
  ],
  tags: ["Investment", "Fixed Deposit", "Finance"],
},
{
  id:2,
  title: "Understanding Financial Products: Fixed Deposit, Recurring Deposit, and Daily Deposit",
  content: [
    {
      type: "text",
      value:
        "In today's fast-paced world, managing finances effectively is crucial for achieving both short-term and long-term financial goals. Among the various financial products available, Fixed Deposits (FDs), Recurring Deposits (RDs), and Daily Deposits are popular choices for individuals seeking to grow their savings with minimal risk. Each of these products offers unique benefits tailored to different financial needs. Let’s explore these options in detail.",
    },
    { type: "image", value: "/blog/Current Account.jpg" },
    { type: "heading", value: "1. Fixed Deposit (FD)" },
    {
      type: "text",
      value:
        "Fixed Deposits are one of the most traditional and popular investment options in India. They offer a guaranteed return on investment, making them a preferred choice for conservative investors.",
    },
    { type: "subheading", value: "How it works ?" },
    {
      type: "text",
      value:
        "When you invest in an FD, you deposit a lump sum amount with a bank or financial institution for a fixed tenure, ranging from a few months to several years. In return, the institution offers a fixed interest rate, which is higher than that of a regular savings account.",
    },
    { type: "subheading", value: "Benefits" },
    {
      type: "list",
      value: [
        "Guaranteed Returns: The interest rate is fixed at the time of investment, ensuring predictable and secure returns.",
        "Safety: FDs are considered low-risk investments as they are not subject to market fluctuations.",
        "Flexible Tenure: You can choose a tenure that suits your financial goals, from a few months to up to 10 years.",
        "Loan Facility: Many banks offer loans against FDs, providing liquidity without breaking the deposit.",
      ],
    },
    { type: "subheading", value: "Who Should Invest ?" },
    {
      type: "text",
      value:
        "Fixed Deposits are ideal for individuals who prefer a low-risk investment with assured returns, such as retirees or those looking to save for a specific future expense.",
    },
    { type: "heading", value: "2. Recurring Deposit (RD)" },
    {
      type: "text",
      value:
        "Recurring Deposits offer a disciplined approach to saving by allowing you to deposit a fixed amount every month for a predetermined period.",
    },
    { type: "subheading", value: "How it works ?" },
    {
      type: "text",
      value:
        "You commit to depositing a fixed sum of money each month into your RD account for a set tenure, typically ranging from 6 months to 10 years. The bank offers an interest rate similar to FDs, and the interest is compounded quarterly.",
    },
    { type: "subheading", value: "Benefits" },
    {
      type: "list",
      value: [
        "Disciplined Savings: RDs encourage regular savings, making them an excellent option for salaried individuals or those with a steady income.",
        "Guaranteed Returns: Like FDs, the returns on RDs are fixed, offering safety and predictability.",
        "Flexible Tenure: The tenure of an RD can be chosen based on your financial goals.",
        "No Lump Sum Requirement: Unlike FDs, RDs do not require a large initial investment, making them accessible to a broader audience.",
      ],
    },
    { type: "subheading", value: "Who Should Invest ?" },
    {
      type: "text",
      value:
        "Recurring Deposits are perfect for individuals who wish to cultivate a habit of regular saving without needing to invest a large amount upfront. They are particularly suitable for young professionals and those saving for medium-term goals, like a vacation or education expenses.",
    },
    { type: "heading", value: "3. Daily Deposit" },
    {
      type: "text",
      value:
        "Daily Deposits, also known as Pigmy Deposits, are a unique financial product primarily targeted at small business owners, daily wage earners, and individuals with irregular income streams.",
    },
    { type: "subheading", value: "How it works ?" },
    {
      type: "text",
      value:
        "In a Daily Deposit scheme, a small amount is deposited daily into the account. This scheme is typically operated by agents who collect the deposits every day. At the end of the tenure, the accumulated amount, along with interest, is returned to the depositor.",
    },
    { type: "subheading", value: "Benefits" },
    {
      type: "list",
      value: [
        "Encourages Saving: Daily Deposits are designed for those who may find it challenging to save large amounts at once, offering an easy way to save regularly.",
        "Low Minimum Deposit: The daily deposit amount is usually very small, making it accessible to everyone.",
        "Convenient Collection: The daily collection system is convenient for individuals who may not have regular access to banking facilities.",
        "Secure Investment: Like other deposit schemes, Daily Deposits offer a secure and predictable return.",
      ],
    },
    { type: "subheading", value: "Who Should Invest ?" },
    {
      type: "text",
      value:
        "Daily Deposits are ideal for small business owners, shopkeepers, or individuals with irregular income who wish to save regularly without impacting their daily cash flow.",
    },
    { type: "heading", value: "Conclusion" },
    {
      type: "text",
      value:
        "Choosing the right financial product depends on your financial goals, income pattern, and risk appetite. Fixed Deposits offer safety and high returns for those looking for a secure investment option. Recurring Deposits provide a disciplined approach to savings, making them suitable for those with a steady income. Daily Deposits are perfect for individuals with irregular incomes who need a convenient saving mechanism. Understanding these products will help you make informed decisions and ensure your savings grow steadily, providing financial security for the future.",
    },
    {
      type: "text",
      value:
        "Whether you are planning for a significant life event, aiming to build an emergency fund, or simply seeking to grow your wealth, these deposit schemes can serve as reliable tools in your financial planning toolkit.",
    },
  ],
  tags: ["Finance", "Investment", "Fixed Deposit", "Recurring Deposit", "Daily Deposit"],
},
{
  id:3,
  title: "The Power of Saving ₹100 Daily: Transforming Small Habits into a Secure Future",
  content: [
    {
      type: "text",
      value:
        "In the journey toward financial stability and achieving long-term goals, the significance of consistent saving cannot be overstated. Often, the idea of setting aside a substantial amount each month seems daunting, leading many to delay or avoid saving altogether. However, saving just ₹100 daily can make a remarkable difference over time. Let’s delve into how this simple habit can transform your financial future.",
    },
    { type: "image", value: "/blog/Daily Deposit.jpg" },

    { type: "heading", value: "1. The Impact of Saving ₹100 Every Day" },
    {
      type: "text",
      value:
        "Saving ₹100 daily may appear modest, but its true potential lies in the cumulative effect over time. Consistent daily savings harness the power of compounding, where the interest earned on your savings also generates additional earnings.",
    },
    {
      type: "text",
      value:
        "Here's how it works:\n\nDaily Savings: ₹100\nMonthly Savings: Approximately ₹3,000\nAnnual Savings: Approximately ₹36,500",
    },
    {
      type: "text",
      value:
        "By committing to save ₹100 each day, you establish a disciplined approach to building wealth, paving the way for significant financial growth in the years to come.",
    },
    { type: "heading", value: "2. Understanding the Magic of Compounding" },
    {
      type: "text",
      value:
        "Compounding is a powerful financial principle where the interest earned on your savings is reinvested, generating its own interest. This snowball effect can substantially increase your savings over time.",
    },
    {
      type: "text",
      value:
        "Let’s explore the growth of ₹100 daily savings with an average annual interest rate of 7%, compounded annually:\n\nAfter 5 Years:\n\nTotal Savings: ₹1,82,500\nInterest Earned: ₹39,928\nTotal Amount: ₹2,22,428\n\nAfter 10 Years:\n\nTotal Savings: ₹3,65,000\nInterest Earned: ₹1,05,294\nTotal Amount: ₹4,70,294\n\nAfter 20 Years:\n\nTotal Savings: ₹7,30,000\nInterest Earned: ₹2,57,765\nTotal Amount: ₹9,87,765",
    },
    {
      type: "text",
      value:
        "These projections illustrate how daily savings, combined with compound interest, can significantly enhance your financial portfolio over time.",
    },
    { type: "heading", value: "3. Benefits of Consistent Daily Savings" },
    {
      type: "list",
      value: [
        "Financial Discipline: Regularly setting aside ₹100 fosters a disciplined saving habit, encouraging mindful spending and prioritization of financial goals.",
        "Emergency Fund Creation: Accumulating savings over time builds a robust emergency fund, providing a safety net during unforeseen circumstances.",
        "Goal Achievement: Whether it’s purchasing a home, funding education, planning a vacation, or securing retirement, consistent savings facilitate the realization of these objectives.",
        "Reduced Financial Stress: Knowing that you have savings set aside alleviates financial anxiety, promoting peace of mind and security.",
      ],
    },
    { type: "heading", value: "4. Practical Ways to Save ₹100 Daily" },
    {
      type: "list",
      value: [
        "Automated Transfers: Set up automatic daily transfers of ₹100 from your checking account to a savings account, ensuring consistency without manual effort.",
        "Digital Savings Apps: Utilize smartphone applications designed for micro-savings, which round up your purchases or allow daily contributions seamlessly.",
        "Pigmy Deposit Schemes: Consider schemes like Pigmy Deposits, where small daily amounts are collected and invested, often with attractive interest rates.",
        "Cashless Transactions: Embrace digital payments and save the ₹100 daily difference by foregoing small, unnecessary expenses.",
      ],
    },
    { type: "heading", value: "5. Tips to Maintain the Saving Habit" },
    {
      type: "list",
      value: [
        "Set Clear Goals: Define specific financial objectives to stay motivated and track your progress.",
        "Budget Wisely: Create a monthly budget that accommodates daily savings without straining your finances.",
        "Monitor Progress: Regularly review your savings growth to stay encouraged and make necessary adjustments.",
        "Stay Consistent: Even during lean periods, strive to maintain the habit of saving ₹100 daily to ensure long-term benefits.",
      ],
    },
    { type: "heading", value: "6. Real-Life Success Stories" },
    {
      type: "text",
      value:
        "Rajesh’s Retirement Fund: Rajesh started saving ₹100 daily at the age of 25. By the time he turned 60, his disciplined habit, coupled with compound interest, had amassed a substantial retirement corpus, ensuring a comfortable and worry-free retirement.",
    },
    {
      type: "text",
      value:
        "Anita’s Education Fund: Anita committed to saving ₹100 each day for her daughter’s higher education. Over 18 years, the savings grew significantly, providing a financial cushion that covered a significant portion of her daughter’s tuition fees.",
    },
    { type: "heading", value: "7. Conclusion" },
    {
      type: "text",
      value:
        "The journey to financial security and achieving your dreams begins with small, consistent steps. Saving ₹100 daily might seem insignificant initially, but over time, it accumulates into a substantial sum, empowered by the magic of compounding. This simple habit not only cultivates financial discipline but also lays a strong foundation for a secure and prosperous future.",
    },
    {
      type: "text",
      value:
        "Start today by committing to save ₹100 each day. Embrace the power of consistency, and watch as your small daily savings transform into significant financial milestones, bringing your dreams within reach and ensuring a stable and secure tomorrow.",
    },
  ],
  tags: ["Savings", "Finance", "Compounding", "Daily Habits", "Financial Security"],
},
 {
  id: 4,
  title: "Why Recurring Deposit (RD) is Better Than Mutual Fund SIP for Certain Investors",
  content: [
    { type: "text", value: "When it comes to choosing a savings or investment plan, the decision often boils down to your risk tolerance, financial goals, and time horizon. While both Recurring Deposits (RDs) and Mutual Fund Systematic Investment Plans (SIPs) are popular options, RDs offer several advantages that might make them a better choice for certain types of investors. Here’s why a Recurring Deposit might be better than a Mutual Fund SIP for you:" },
    
    { type: "image", value: "/blog/Recurring Deposit.jpg" }, 

    { type: "heading", value: "1. Guaranteed Returns" },
    { type: "text", value: "One of the biggest advantages of RDs is the guarantee of returns. When you open an RD, the interest rate is fixed at the beginning of the tenure, meaning you know exactly how much you will earn by the end of the period. This makes RDs ideal for those who prioritize stability and certainty in their savings." },

    { type: "heading", value: "2. Zero Risk" },
    { type: "text", value: "RDs are considered one of the safest investment options available. Your principal amount is secure, and the interest rate is fixed, so there’s no risk of losing your money. This makes RDs an excellent choice for risk-averse individuals, such as retirees, those saving for specific short-term goals, or anyone who cannot afford to take financial risks." },

    { type: "heading", value: "3. Simple and Hassle-Free" },
    { type: "text", value: "RDs are straightforward and easy to understand. You deposit a fixed amount every month, and at the end of the tenure, you receive the principal along with the accumulated interest. There’s no need to monitor market trends or worry about portfolio management. This simplicity makes RDs especially appealing to new investors or those who prefer a hands-off approach." },

    { type: "heading", value: "4. No Market Timing Concerns" },
    { type: "text", value: "With RDs, you don’t need to worry about timing the market or making investment decisions based on market conditions. The fixed interest rate ensures that your returns are unaffected by market volatility. This makes RDs a stress-free option for those who prefer not to deal with market timing or investment strategies." },

    { type: "heading", value: "5. Predictable Financial Planning" },
    { type: "text", value: "Since the returns on RDs are fixed and guaranteed, they are perfect for predictable financial planning. Whether you’re saving for a specific goal like a vacation, a wedding, or a large purchase, RDs allow you to calculate exactly how much you’ll have at the end of the tenure. This predictability is a key advantage for those who prefer a clear, defined path to their financial goals." },

    { type: "heading", value: "6. Lower Tax Complexity" },
    { type: "text", value: "The interest earned on RDs is subject to income tax, but the tax process is straightforward. The interest is added to your taxable income and taxed according to your income tax slab. There are no additional complications like short-term or long-term capital gains tax." },

    { type: "heading", value: "Conclusion" },
    { type: "text", value: "A Recurring Deposit is better than a Mutual Fund SIP for certain types of investors due to its safety, simplicity, and predictability. If you are a risk-averse individual looking for guaranteed returns, prefer a straightforward investment process, and want a clear path to achieving your financial goals, an RD is an excellent choice. While SIPs offer the potential for higher returns, they come with higher risks and complexities that may not suit everyone, particularly those who prioritize capital preservation and stability." },
  ],
  tags: ["Finance", "Investment", "Recurring Deposit", "SIP", "Savings"],
}];

const BlogDetail = () => {
  const classes = useStyles();
  const { id } = useParams();

  const blog = blogs?.find((b)=>b.id == id);
  
  return (
    <Container className={classes.root}>
      <Typography variant="h3" mb={3} className={classes.title}>
        {blog.title}
      </Typography>

      <Grid container spacing={4} className={classes.content}>
        {blog.content.map((item, idx) => {
          switch (item.type) {
            case "heading":
              return (
                <Grid item xs={12} key={idx}>
                  <Typography variant="h4" className={classes.title}>
                    {item.value}
                  </Typography>
                </Grid>
              );
            case "subheading":
              return (
                <Grid item xs={12} key={idx}>
                  <Typography variant="h5" className={classes.subheading}>
                    {item.value}
                  </Typography>
                </Grid>
              );
            case "text":
              return (
                <Grid item xs={12} key={idx}>
                  <Typography variant="body1" className={classes.description}>
                    {item.value}
                  </Typography>
                </Grid>
              );
            case "image":
              return (
                <Grid item xs={12} key={idx}>
                  <img
                    src={item.value}
                    alt={``}
                    className={classes.image}
                  />
                </Grid>
              );
            case "highlight":
              return (
                <Grid item xs={12} key={idx}>
                  <Typography variant="body1" className={classes.highlightedText}>
                    {item.value}
                  </Typography>
                </Grid>
              );
            case "list":
              return (
                <Grid item xs={12} key={idx}>
                  <List className={classes.list}>
                    {item.value.map((listItem, i) => (
                      <ListItem key={i}>
                        <Typography variant="body1" className={classes.description}>
                          {listItem}
                        </Typography>
                      </ListItem>
                    ))}
                  </List>
                </Grid>
              );
            case "quote":
              return (
                <Grid item xs={12} key={idx}>
                  <Typography variant="body1" className={classes.quote}>
                    {item.value}
                  </Typography>
                </Grid>
              );
            case "video":
              return (
                <Grid item xs={12} key={idx}>
                  <Box className={classes.video}>
                    <iframe
                      width="100%"
                      height="100%"
                      src={item.value}
                      title={`Video ${idx}`}
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </Box>
                </Grid>
              );
            default:
              return null;
          }
        })}
      </Grid>

      <Box className={classes.tags}>
        {blog.tags.map((tag, idx) => (
          <Chip key={idx} label={tag} variant="outlined" size="small" />
        ))}
      </Box>
    </Container>
  );
};

export default BlogDetail;
