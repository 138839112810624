// ProtectedRoutes.js
import React from "react";
import { connect } from "react-redux";
import { Navigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

const ProtectedRoutes = ({ children, auth, requiredRole }) => {
  // Debugging log to check auth state

  // Check if the auth state is still loading or not yet populated
  if (auth === undefined || auth.data === undefined) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  const token = localStorage.getItem("token");
  const role = localStorage.getItem("role");

  // Check if the user is authenticated and has the correct role
  if (
    (auth.data?.token && auth.data?.role === requiredRole) ||
    (token && role === requiredRole)
  ) {

    return children;
  } else {
    return requiredRole === "User" ? <Navigate to="/login" /> : <Navigate to="/admin/login" />;
  }
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth.auth,
  };
};

export default connect(mapStateToProps)(ProtectedRoutes);
