import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useMemo, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import * as AuthActions from "../redux/actions/auth";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { useNavigate, useParams } from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InfoIcon from "@mui/icons-material/Info";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import moment from "moment";
import { doGet, doPost } from "../request";
import Loader from "../components/comman/Loader";
import ImageModel from "../components/comman/ImageModel";
import { renderFilePreview } from "../components/Form/BankingForm";
import toast from "react-hot-toast";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    paddingBottom: "30px",
    [theme.breakpoints.down("md")]: {
      display: "block",
      height: "100%",
    },
  },
  heightFull: {
    height: "100%",
    padding: "30px",
    overflowY: "auto",
  },
  leftSideSection: {
    width: "25vw",
    display: "flex",

    flexDirection: "column",
    borderRight: "1px solid gray",

    [theme.breakpoints.down("md")]: {
      width: "100%",
      flexDirection: "row",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      flexDirection: "column",
      border: "none",
    },

    "--sb-track-color": "#ffffff",
    "--sb-thumb-color": "#0561AB",
    "--sb-size": "6px",

    "&::-webkit-scrollbar": {
      width: "var(--sb-size)",
    },
    "&::-webkit-scrollbar-track": {
      background: "var(--sb-track-color)",
      borderRadius: "3px",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "var(--sb-thumb-color)",
      borderRadius: "3px",
    },
    "@supports not selector(::-webkit-scrollbar)": {
      scrollbarColor: "var(--sb-thumb-color) var(--sb-track-color)",
    },
  },
  profilePhotoSection: {
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    [theme.breakpoints.down("md")]: {
      width: "40%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  centerSection: {
    width: "40vw",
    borderRight: "1px solid gray",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
    [theme.breakpoints.down("sm")]: {
      border: "none",
    },
    "--sb-track-color": "#ffffff",
    "--sb-thumb-color": "#0561AB",
    "--sb-size": "6px",

    "&::-webkit-scrollbar": {
      width: "var(--sb-size)",
    },
    "&::-webkit-scrollbar-track": {
      background: "var(--sb-track-color)",
      borderRadius: "3px",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "var(--sb-thumb-color)",
      borderRadius: "3px",
    },
    "@supports not selector(::-webkit-scrollbar)": {
      scrollbarColor: "var(--sb-thumb-color) var(--sb-track-color)",
    },
  },
  rightSideSection: {
    width: "25vw",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
    "--sb-track-color": "#ffffff",
    "--sb-thumb-color": "#0561AB",
    "--sb-size": "6px",

    "&::-webkit-scrollbar": {
      width: "var(--sb-size)",
    },
    "&::-webkit-scrollbar-track": {
      background: "var(--sb-track-color)",
      borderRadius: "3px",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "var(--sb-thumb-color)",
      borderRadius: "3px",
    },
    "@supports not selector(::-webkit-scrollbar)": {
      scrollbarColor: "var(--sb-thumb-color) var(--sb-track-color)",
    },
  },
  errorMessage: {
    text: "13px",
    color: "red",
  },
  screenLoader: {
    position: "fixed",
    height: "100vh",
    width: "100vw",
    backdropFilter: "blur(1.5px)",
    zIndex: "2147483647",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  documentNameBox: {
    display: "flex",
    width: "100%",
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center"
  },
  dialogContent: {
    padding: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
    },
  },
  title: {
    fontWeight: 'bold',
    fontSize: '1.5rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.2rem',
    },
  },
  list: {
    marginTop: theme.spacing(2),
    '& li': {
      marginBottom: theme.spacing(1),
      marginLeft:20,
      fontSize: '1rem',
      [theme.breakpoints.down('sm')]: {
        fontSize: '0.9rem',
      },
    },
  },
  button: {
    margin: theme.spacing(1),
  },
}));

const ProfilePage = ({ auth: userDetails }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [sameAsCurrent, setSameAsCurrent] = useState(false);
  const [isApproved, setIsAprroved] = useState(false);
  const role = localStorage.getItem("role");
  const [data, setData] = useState();

  let params = useParams();

  const {
    register,
    formState: { errors },
    getValues,
    setValue,
    handleSubmit,
    watch,
    reset,
    control,
  } = useForm();
  const {
    fields: bankDetailsFields,
    remove: removeBankDetail,
    append: appendBankDetails,
  } = useFieldArray({
    name: "BankDetails",
    control,
  });
  const {
    fields: employmentDetailsFields,
    remove: removeEmployementDetials,
    append: appendEmploymentDetails,
  } = useFieldArray({
    name: "EmploymentDetails",
    control,
  });
  const {
    fields: businessDetailsFields,
    remove: removeBusinessDetails,
    append: appendBusinessDetails,
  } = useFieldArray({
    name: "BusinessDetails",
    control,
  });

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    fetchDefaultValue();
  }, [])

  const fetchDefaultValue = async () => {
    try {
      setIsLoading(true);
      const userId = params?.id ? params.id : userDetails?.id;

      const response = await doGet(`/user/${userId}`);
      if (response?.data) {
        setData(response?.data);

        if (!params.id) {
          if (response?.data.status === "approved") {
            setIsAprroved(true);
          } else {
            setIsAprroved(false);
          }
        }

      } else {
        throw new Error("User Data Not Find");
      }
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if (data) {
      const initialValues = {
        id: data?.id || null,
        profile: data?.Documents?.find((item) => item.document_type === "profile")?.document_url || null,
        name: data?.name || "",
        surname: data?.surname || "",
        fatherName: data?.fatherName || "",
        dob: moment(data?.dob).format('YYYY-MM-DD') || "",
        gender: data?.gender || "",
        maritualStatus: data?.maritualStatus || "",
        profession: data?.profession || "",
        email: data?.email || "",
        phoneNumber: data?.phoneNumber || "",
        countryCode: data?.countryCode || "",
        panNumber: data?.panNumber || "",
        aadharNumber: data?.aadharNumber || "",
        occupation: data?.occupation || "",
        current_address_id: data?.Addresses?.find((ads) => ads?.type === "current")?.id || undefined,
        current_address: data?.Addresses?.find((ads) => ads?.type === "current")?.address || "",
        current_district: data?.Addresses?.find((ads) => ads?.type === "current")?.district || "",
        current_state: data?.Addresses?.find((ads) => ads?.type === "current")?.state || "",
        current_pincode: data?.Addresses?.find((ads) => ads?.type === "current")?.pinCode || "",
        permanent_address_id: data?.Addresses?.find((ads) => ads?.type === "permanent")?.id || undefined,
        permanent_address: data?.Addresses?.find((ads) => ads?.type === "permanent")?.address || "",
        permanent_district: data?.Addresses?.find((ads) => ads?.type === "permanent")?.district || "",
        permanent_state: data?.Addresses?.find((ads) => ads?.type === "permanent")?.state || "",
        permanent_pincode: data?.Addresses?.find((ads) => ads?.type === "permanent")?.pinCode || "",
        office_address_id: data?.Addresses?.find((ads) => ads?.type === "office")?.id || undefined,
        office_address: data?.Addresses?.find((ads) => ads?.type === "office")?.address || "",
        office_district: data?.Addresses?.find((ads) => ads?.type === "office")?.district || "",
        office_state: data?.Addresses?.find((ads) => ads?.type === "office")?.state || "",
        office_pincode: data?.Addresses?.find((ads) => ads?.type === "office")?.pinCode || "",
        nominees_name: data?.Nominees?.[0]?.name || "",
        nominees_relation: data?.Nominees?.[0]?.relation || "",
        nominees_age: data?.Nominees?.[0]?.age || "",
        nominees_id: data?.Nominees?.[0]?.id || undefined,
        nominees_userId: data?.Nominees?.[0]?.userId || "",
        nominees_surname: data?.Nominees?.[0]?.surname || "",
        nominees_fatherName: data?.Nominees?.[0]?.fatherName || "",
        nominees_address: data?.Nominees?.[0]?.address || "",
        nominees_city: data?.Nominees?.[0]?.city || "",
        nominees_state: data?.Nominees?.[0]?.state || "",
        nominees_pincode: data?.Nominees?.[0]?.pinCode || "",
        BankDetails: data?.BankDetails || [],
        BusinessDetails: data?.BusinessDetails || [],
        EmploymentDetails: data?.EmploymentDetails || [],
        kycDocument: data?.Documents?.find((doc) => doc.document_type === "kycDocument")?.document_url,
        panCard: data?.Documents?.find((doc) => doc.document_type === "panCard")?.document_url,
        drivingLicence: data?.Documents?.find((doc) => doc.document_type === "drivingLicence")?.document_url,
        voterId: data?.Documents?.find((doc) => doc.document_type === "voterId")?.document_url,
        adharCard: data?.Documents?.find((doc) => doc.document_type === "adharCard")?.document_url,
        telephoneBill: data?.Documents?.find((doc) => doc.document_type === "telephoneBill")?.document_url,
        signature: data?.Documents?.find((doc) => doc.document_type === "signature")?.document_url,
        electricityBill: data?.Documents?.find((doc) => doc.document_type === "electricityBill")?.document_url,
        rationCard: data?.Documents?.find((doc) => doc.document_type === "rationCard")?.document_url,
        bankStatement: data?.Documents?.find((doc) => doc.document_type === "bankStatement")?.document_url,
        rentAgreement: data?.Documents?.find((doc) => doc.document_type === "rentAgreement")?.document_url,
        admissionFee: data?.admissionFee || "",
        applicationFee: data?.applicationFee || "",
        numberOfShares: data?.numberOfShares || "",
      };

      reset(initialValues);
    }
  }, [data]);

  const submit = async (formDataPayload) => {
    setIsLoading(true);

    try {
      const formData = new FormData();

      // Helper function to append to formData only if value is not undefined or null
      const appendIfExists = (key, value) => {
        if (value !== undefined && value !== null && value !== "") {
          formData.append(key, value);
        }
      };

      // Basic Details
      appendIfExists("id", data?.id);
      appendIfExists("name", formDataPayload?.name);
      appendIfExists("surname", formDataPayload?.surname);
      appendIfExists("fatherName", formDataPayload?.fatherName);
      appendIfExists("dob", formDataPayload?.dob);
      appendIfExists("gender", formDataPayload?.gender);
      appendIfExists("maritualStatus", formDataPayload?.maritualStatus);
      appendIfExists("profession", formDataPayload?.profession);
      appendIfExists("email", formDataPayload?.email);
      appendIfExists("phoneNumber", formDataPayload?.phoneNumber);
      appendIfExists("panNumber", formDataPayload?.panNumber);
      appendIfExists("aadharNumber", formDataPayload?.aadharNumber);
      appendIfExists("occupation", formDataPayload?.occupation);
      appendIfExists("admissionFee", formDataPayload?.admissionFee);
      appendIfExists("applicationFee", formDataPayload?.applicationFee);
      appendIfExists("numberOfShares", formDataPayload?.numberOfShares);

      // Documents
      appendIfExists("profile", formDataPayload?.profile);
      appendIfExists("kycDocument", formDataPayload?.kycDocument);
      appendIfExists("panCard", formDataPayload?.panCard);
      appendIfExists("drivingLicence", formDataPayload?.drivingLicence);
      appendIfExists("voterId", formDataPayload?.voterId);
      appendIfExists("adharCard", formDataPayload?.adharCard);
      appendIfExists("telephoneBill", formDataPayload?.telephoneBill);
      appendIfExists("electricityBill", formDataPayload?.electricityBill);
      appendIfExists("rationCard", formDataPayload?.rationCard);
      appendIfExists("bankStatement", formDataPayload?.bankStatement);
      appendIfExists("rentAgreement", formDataPayload?.rentAgreement);
      appendIfExists("signature", formDataPayload?.signature);

      // Nominees
      const nominees = [
        {
          name: formDataPayload?.nominees_name,
          relation: formDataPayload?.nominees_relation,
          address: formDataPayload?.nominees_address,
          pinCode: formDataPayload?.nominees_pincode,
          city: formDataPayload?.nominees_city,
          state: formDataPayload?.nominees_state,
          surname: formDataPayload?.nominees_surname,
          userId: formDataPayload?.nominees_userId,
          id: formDataPayload?.nominees_id,
          fatherName: formDataPayload?.nominees_fatherName,
          age: formDataPayload?.nominees_age,
        },
      ];

      appendIfExists("nominees", JSON.stringify(nominees));

      // Address
      const addresses = [
        {
          id: formDataPayload?.current_address_id,
          address: formDataPayload?.current_address,
          district: formDataPayload?.current_district,
          state: formDataPayload?.current_state,
          pinCode: formDataPayload?.current_pincode,
          type: "current",
        },
        {
          id: formDataPayload?.permanent_address_id,
          address: formDataPayload?.permanent_address,
          district: formDataPayload?.permanent_district,
          state: formDataPayload?.permanent_state,
          pinCode: formDataPayload?.permanent_pincode,
          type: "permanent",
        },
        {
          id: formDataPayload?.office_address_id,
          address: formDataPayload?.office_address,
          district: formDataPayload?.office_district,
          state: formDataPayload?.office_state,
          pinCode: formDataPayload?.office_pincode,
          type: "office",
        },
      ];
      appendIfExists("addresses", JSON.stringify(addresses));

      appendIfExists("bankDetails", JSON.stringify(formDataPayload?.bankDetails));
      appendIfExists("employmentDetails", JSON.stringify(formDataPayload?.employmentDetails));
      appendIfExists("businessDetails", JSON.stringify(formDataPayload?.BusinessDetails));

      const response = await doPost("user", formData, {
        contentType: 'multipart/form-data"',
      });

      toast.success(response.message, {
        duration: 4000
      });
    } catch (error) {
      console.log("Error")
      const message = typeof error === "string" ? error : error.message;
      toast.error(message, {
        duration: 4000
      });
      console.log("Error", message);
    } finally {
      setIsLoading(false);
    }
  };


  const handleSameAsCurrentChange = () => {
    setSameAsCurrent(!sameAsCurrent);
    if (!sameAsCurrent) {
      setValue("permanent_address", getValues("current_address"));
      setValue("permanent_district", getValues("current_district"));
      setValue("permanent_state", getValues("current_state"));
      setValue("permanent_pincode", getValues("current_pincode"));
    } else {
      setValue("permanent_address", undefined);
      setValue("permanent_district", undefined);
      setValue("permanent_state", undefined);
      setValue("permanent_pincode", undefined);
    }
  };

  const validateIndianPhoneNumber = (value) => {
    const indianPhoneNumberRegex = /^[6-9]\d{9}$/;
    return indianPhoneNumberRegex.test(value) || "Invalid phone number";
  };


  return (
    <>
      {isLoading && (
        <div className={classes.screenLoader}>
          <Loader />
        </div>
      )}
      <Box className={classes.root}>
        {/* User Left Side Section For Personal Details */}
        <Box
          display={"flex"}
          gap={2}
          className={`${classes.heightFull} ${classes.leftSideSection}`}
        >
          {/* Image Section */}
          <Box display={"flex"} gap={2} className={classes.profilePhotoSection}>
            <ImageModel
              style={{
                height: "200px",
                width: "200px",
                borderRadius: "9999px",
                objectFit: "contain",
                boxShadow: "0px 0px 50px #0003",
              }}
              alt={"User Profile"}
              file={watch("profile")}
            />
            {!isApproved &&
              <Button
                variant="contained"
                component="label"
                startIcon={<UploadFileIcon />}
                fullWidth
              >
                Upload Profile Photo
                <TextField
                  fullWidth
                  type="file"
                  style={{ display: "none" }}
                  variant="outlined"
                  name="profile"
                  {...register("profile")}
                  onChange={(e) => {
                    setValue("profile", e?.target?.files?.[0]);
                  }}
                />
              </Button>
            }
            {errors?.profile?.message && (
              <span className={classes.errorMessage}>
                {errors?.profile?.message}
              </span>
            )}
            <p style={{ fontWeight: "700", fontSize: "1rem" }}>
              Membership No : {data?.memberId}
            </p>
          </Box>
          {/* Details Section */}
          <Box
            display={{ sm: "flex", md: "block" }}
            justifyContent={"space-between"}
            mt={2}
            gap={4}
          >
            <Box display={"flex"} flexDirection={"column"} gap={2}>
              <Box display={"flex"} flexDirection={"column"}>
                <TextField
                  fullWidth
                  disabled={isApproved}
                  placeholder="First Name"
                  label="First Name"
                  variant="outlined"
                  name="name"
                  {...register("name", {
                    required: "First Name Is Required",
                  })}
                  defaultValue={getValues("name")}
                  error={!!errors?.name?.message}
                  helperText={errors?.name?.message}
                  InputLabelProps={{ shrink: true }}
                />
              </Box>
              <Box display={"flex"} flexDirection={"column"}>
                <TextField
                  fullWidth
                  disabled={isApproved}
                  label="Father Name"
                  placeholder="Father Or Husband Name"
                  variant="outlined"
                  name="fatherName"
                  {...register("fatherName", {
                    required: "Father Name Is Required",
                  })}
                  defaultValue={getValues("fatherName")}
                  error={!!errors?.fatherName?.message}
                  helperText={errors?.fatherName?.message}
                  InputLabelProps={{ shrink: true }}
                />
              </Box>
              <Box display={"flex"} flexDirection={"column"}>
                <TextField
                  fullWidth
                  placeholder="Surname"
                  label="Surname"
                  disabled={isApproved}
                  variant="outlined"
                  name="surname"
                  {...register("surname", {
                    required: "Surname Name Is Required",
                  })}
                  defaultValue={getValues("surname")}
                  error={!!errors?.surname?.message}
                  helperText={errors?.surname?.message}
                  InputLabelProps={{ shrink: true }}
                />
              </Box>
              <Box display={"flex"} flexDirection={"column"}>
                <TextField
                  fullWidth
                  disabled={isApproved}
                  type="date"
                  label="Date Of Birth"
                  placeholder="Date Of Birth"
                  variant="outlined"
                  name="dob"
                  {...register("dob", {
                    required: "Date Of Birth Is Required",
                  })}
                  value={getValues('dobValue')}
                  error={!!errors?.dob?.message}
                  helperText={errors?.dob?.message}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Box>
              <Box display={"flex"} flexDirection={"column"}>
                <TextField
                  fullWidth
                  disabled={isApproved}
                  label="Profession"
                  variant="outlined"
                  name="profession"
                  {...register("profession", {
                    required: "Profession Is Required",
                  })}
                  defaultValue={getValues("profession")}
                  error={!!errors?.profession?.message}
                  helperText={errors?.profession?.message}
                  InputLabelProps={{ shrink: true }}
                />
              </Box>
              <Box display={"flex"} flexDirection={"column"}>
                <FormControl fullWidth error={!!errors?.gender?.message}>
                  <InputLabel id="gender-label">Gender</InputLabel>
                  <Controller
                    name="gender"
                    control={control}
                    defaultValue=""
                    rules={{ required: "Gender is required" }}
                    render={({ field }) => (
                      <Select
                        labelId="gender-label"
                        label="Gender"
                        disabled={isApproved}
                        {...field}
                      >
                        <MenuItem value="Male">Male</MenuItem>
                        <MenuItem value="Female">Female</MenuItem>
                      </Select>
                    )}
                  />
                  {errors?.gender?.message && (
                    <FormHelperText>{errors.gender.message}</FormHelperText>
                  )}
                </FormControl>
              </Box>
            </Box>
            <Box mt={1} display={"flex"} flexDirection={"column"} gap={2}>
              <Box display={"flex"} flexDirection={"column"}>
                <TextField
                  fullWidth
                  disabled={isApproved}
                  label="Pan Number"
                  variant="outlined"
                  name="panNumber"
                  defaultValue={getValues("panNumber")}
                  {...register("panNumber", {
                    required: "Pan Number Is Required",
                    pattern: {
                      value: /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/, // Valid PAN number format
                      message: "Invalid PAN Number format",
                    },
                    validate: (value) => {
                      if (value.length !== 10) {
                        return "PAN Number must be exactly 10 characters long";
                      }
                      return true;
                    },
                  })}
                  error={!!errors?.panNumber?.message}
                  helperText={errors?.panNumber?.message}
                  InputLabelProps={{ shrink: true }}
                  inputProps={{
                    maxLength: 10, // Limit the length of input to 10 characters
                    pattern: "[A-Z0-9]*", // Allow only uppercase letters and digits
                    title: "Enter a valid PAN number (10 characters, uppercase letters and digits only)"
                  }}
                  onInput={(e) => {
                    // Allow only uppercase letters and digits and limit to 10 characters
                    e.target.value = e.target.value
                      .toUpperCase() // Convert input to uppercase
                      .replace(/[^A-Z0-9]/g, '') // Allow only uppercase letters and digits
                      .slice(0, 10); // Limit length to 10 characters
                  }}
                />


              </Box> <Box display={"flex"} flexDirection={"column"}>
                <TextField
                  fullWidth
                  disabled={isApproved}

                  label="Maritual Status"
                  variant="outlined"
                  name="maritualStatus"
                  {...register("maritualStatus", {
                    required: "Maritual Status Is Required",
                  })}
                  defaultValue={getValues("maritualStatus")}
                  error={!!errors?.maritualStatus?.message}
                  helperText={errors?.maritualStatus?.message}
                  InputLabelProps={{ shrink: true }}
                />
              </Box>
              <Box display={"flex"} flexDirection={"column"}>
              <TextField
                  fullWidth
                  disabled={role === "User"}
                  label="Phone Number"
                  variant="outlined"
                  name="phoneNumber"
                  {...register("phoneNumber", {
                    required: "Phone Number is required",
                    validate: validateIndianPhoneNumber,
                    maxLength: {
                      value: 10,
                      message: "Phone Number must be 10 digits long",
                    },
                  })}
                  defaultValue={getValues("phoneNumber")}
                  error={!!errors?.phoneNumber?.message}
                  helperText={errors?.phoneNumber?.message}
                  InputLabelProps={{ shrink: true }}
                  inputProps={{
                    maxLength: 10, // limits the input to 10 digits
                    inputMode: "numeric", // ensures only numbers can be typed
                    pattern: "[0-9]*", // restricts input to digits only
                  }}
                />
              </Box>

              <Box display={"flex"} flexDirection={"column"}>
                <TextField
                  fullWidth
                  label="Email"
                  variant="outlined"
                  name="email"
                  disabled={isApproved}
                  {...register("email", {
                    required: "Email is required",
                    pattern: {
                      value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                      message: "Invalid email address",
                    },
                  })}
                  defaultValue={getValues("email")}
                  error={!!errors?.email?.message}
                  helperText={errors?.email?.message}
                  InputLabelProps={{ shrink: true }}
                  inputProps={{
                    autoComplete: "email",
                  }}
                />
              </Box>
              <Box display={"flex"} flexDirection={"column"}>
                <TextField
                  fullWidth
                  disabled={isApproved}
                  label="Aadhar Number"
                  variant="outlined"
                  name="aadharNumber"
                  {...register("aadharNumber", {
                    required: "Aadhar Number Is Required",
                    pattern: {
                      value: /^[0-9]+$/,
                      message: "Only digits are allowed for Aadhar Number",
                    },
                    minLength: {
                      value: 12,
                      message: "Aadhar Number must be 12 digits long",
                    },
                    maxLength: {
                      value: 12,
                      message: "Aadhar Number must be 12 digits long",
                    },
                  })}
                  defaultValue={getValues("aadharNumber")}
                  error={!!errors?.aadharNumber?.message}
                  helperText={errors?.aadharNumber?.message}
                  InputLabelProps={{ shrink: true }}
                  inputProps={{
                    maxLength: 12,
                    inputMode: "numeric",
                    pattern: "[0-9]*",
                  }}
                  onInput={(e) => {
                    e.target.value = e.target.value.replace(/[^0-9]/g, "");
                  }}
                />
              </Box>

            </Box>
          </Box>
        </Box>

        {/* Center Section For Detailed Information About User */}
        <Box className={`${classes.heightFull} ${classes.centerSection}`}>
          {/* Communication Details */}
          <Accordion
            defaultExpanded
            style={{
              boxShadow: "0px 0px 50px #0000",
              border: "1px solid gray",
              marginBottom: "18px",
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="communication-details"
              id="communication-details"
            >
              <Typography fontWeight={"bold"}>Communication Details</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box display={"flex"} flexDirection={"column"} gap={2}>
                <TextField
                  fullWidth
                  label="Current Residential Address"
                  variant="outlined"
                  disabled={isApproved}
                  name="current_address"
                  defaultValue={getValues("current_address")}
                  {...register("current_address", {
                    required: "Current Address Is Required",
                  })}
                  error={!!errors?.current_address?.message}
                  helperText={errors?.current_address?.message}
                  InputLabelProps={{ shrink: true }}
                />
                <Box
                  flexDirection={{ md: "column", xs: "column" }}
                  display={"flex"}
                  gap={2}
                >
                  <TextField
                    fullWidth
                    label="City"
                    variant="outlined"
                    disabled={isApproved}
                    name="current_district"
                    defaultValue={getValues("current_district")}
                    {...register("current_district", {
                      required: "Current District Is Required",
                    })}
                    error={!!errors?.current_district?.message}
                    helperText={errors?.current_district?.message}
                    InputLabelProps={{ shrink: true }}
                  />
                  <TextField
                    fullWidth
                    label="State"
                    disabled={isApproved}
                    variant="outlined"
                    name="current_state"
                    defaultValue={getValues("current_state")}
                    {...register("current_state", {
                      required: "Current State Is Required",
                    })}
                    error={!!errors?.current_state?.message}
                    helperText={errors?.current_state?.message}
                    InputLabelProps={{ shrink: true }}
                  />
                  <TextField
                    fullWidth
                    label="Postal Code"
                    variant="outlined"
                    disabled={isApproved}
                    name="current_pincode"
                    defaultValue={getValues("current_pincode")}
                    {...register("current_pincode", {
                      required: "Current Pincode Is Required",
                    })}
                    error={!!errors?.current_pincode?.message}
                    helperText={errors?.current_pincode?.message}
                    InputLabelProps={{ shrink: true }}
                  />
                </Box>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={isApproved}
                      checked={sameAsCurrent}
                      onChange={handleSameAsCurrentChange}
                      name="same_as_current"
                    />
                  }
                  label="Permanent Residential Address Same as Current"
                />
                {!sameAsCurrent && (
                  <>
                    <TextField
                      fullWidth
                      disabled={isApproved}
                      label="Permanent Residential Address"
                      variant="outlined"
                      name="permanent_address"
                      defaultValue={getValues("permanent_address")}
                      {...register("permanent_address", {
                        required: "Permanenet Address Is Required",
                      })}
                      error={!!errors?.permanent_address?.message}
                      helperText={errors?.permanent_address?.message}
                      InputLabelProps={{ shrink: true }}
                    />
                    <Box
                      flexDirection={{ md: "column", xs: "column" }}
                      display={"flex"}
                      gap={2}
                    >
                      <TextField
                        fullWidth
                        disabled={isApproved}
                        label="City"
                        variant="outlined"
                        name="permanent_district"
                        defaultValue={getValues("permanent_district")}
                        {...register("permanent_district", {
                          required: "Permanent District Is Required",
                        })}
                        error={!!errors?.permanent_district?.message}
                        helperText={errors?.permanent_district?.message}
                        InputLabelProps={{ shrink: true }}
                      />
                      <TextField
                        fullWidth
                        disabled={isApproved}
                        label="State"
                        variant="outlined"
                        name="permanent_state"
                        defaultValue={getValues("permanent_state")}
                        {...register("permanent_state", {
                          required: "Permanent State Is Required",
                        })}
                        error={!!errors?.permanent_state?.message}
                        helperText={errors?.permanent_state?.message}
                        InputLabelProps={{ shrink: true }}
                      />
                      <TextField
                        fullWidth
                        disabled={isApproved}
                        label="Postal Code"
                        variant="outlined"
                        name="permanent_pincode"
                        defaultValue={getValues("permanent_pincode")}
                        {...register("permanent_pincode", {
                          required: "Permanent Pincode Is Required",
                        })}
                        error={!!errors?.permanent_pincode?.message}
                        helperText={errors?.permanent_pincode?.message}
                        InputLabelProps={{ shrink: true }}
                      />
                    </Box>
                  </>
                )}
                <TextField
                  fullWidth
                  label="Office Address"
                  variant="outlined"
                  disabled={isApproved}
                  name="office_address"
                  defaultValue={getValues("office_address")}
                  InputLabelProps={{ shrink: true }}
                />
                <Box
                  flexDirection={{ md: "column", xs: "column" }}
                  display={"flex"}
                  gap={2}
                >
                  <TextField
                    fullWidth
                    label="City"
                    disabled={isApproved}
                    variant="outlined"
                    name="office_district"
                    defaultValue={getValues("office_district")}
                    InputLabelProps={{ shrink: true }}
                  />
                  <TextField
                    fullWidth
                    disabled={isApproved}
                    label="State"
                    variant="outlined"
                    name="office_state"
                    defaultValue={getValues("office_state")}
                    InputLabelProps={{ shrink: true }}
                  />
                  <TextField
                    fullWidth
                    disabled={isApproved}
                    label="Postal Code"
                    variant="outlined"
                    name="office_pincode"
                    defaultValue={getValues("office_pincode")}
                    InputLabelProps={{ shrink: true }}
                  />
                </Box>
              </Box>
            </AccordionDetails>
          </Accordion>
          {/* Nominee Details */}
          <Accordion
            defaultExpanded
            style={{
              boxShadow: "0px 0px 50px #0000",
              border: "1px solid gray",
              marginBottom: "18px",
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="nominee-details"
              id="nominee-details"
            >
              <Typography fontWeight={"bold"}>Nominee Details</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box display={"flex"} flexDirection={"column"} gap={2}>
                <TextField
                  fullWidth
                  disabled={isApproved}
                  label="Nominee's Name"
                  variant="outlined"
                  name="nominees_name"
                  defaultValue={getValues("nominees_name")}
                  {...register("nominees_name", {
                    required: "Nominee Name Is Required",
                  })}
                  error={!!errors?.nominees_name?.message}
                  helperText={errors?.nominees_name?.message}
                  InputLabelProps={{ shrink: true }}
                />
                <TextField
                  fullWidth
                  disabled={isApproved}
                  label="Nominee's Father Name"
                  variant="outlined"
                  name="nominees_fatherName"
                  defaultValue={getValues("nominees_fatherName")}
                  {...register("nominees_fatherName", {
                    required: "Nominee Father Name Is Required",
                  })}
                  error={!!errors?.nominees_fatherName?.message}
                  helperText={errors?.nominees_fatherName?.message}
                  InputLabelProps={{ shrink: true }}
                />
                <TextField
                  fullWidth
                  disabled={isApproved}
                  label="Nominee's Surname"
                  variant="outlined"
                  name="nominees_surname"
                  defaultValue={getValues("nominees_surname")}
                  {...register("nominees_surname", {
                    required: "Nominee Surname Is Required",
                  })}
                  error={!!errors?.nominees_surname?.message}
                  helperText={errors?.nominees_surname?.message}
                  InputLabelProps={{ shrink: true }}
                />
                <TextField
                  fullWidth
                  disabled={isApproved}
                  label="Relation"
                  variant="outlined"
                  name="nominees_relation"
                  defaultValue={getValues("nominees_relation")}
                  {...register("nominees_relation", {
                    required: "Nominee Relation Is Required",
                  })}
                  error={!!errors?.nominees_relation?.message}
                  helperText={errors?.nominees_relation?.message}
                  InputLabelProps={{ shrink: true }}
                />
                <TextField
                  fullWidth
                  disabled={isApproved}
                  label="Age"
                  variant="outlined"
                  name="nominees_age"
                  defaultValue={getValues("nominees_age")}
                  {...register("nominees_age", {
                    required: "Nominee Age Is Required",
                    pattern: {
                      value: /^[1-9][0-9]?|100$/, // Ensures value is between 1 and 100
                      message: "Age must be between 1 and 100 and only digits are allowed",
                    },

                    validate: (value) => {
                      const numberValue = Number(value);
                      if (numberValue < 1 || numberValue > 100) {
                        return "Age must be between 1 and 100";
                      }
                      return true;
                    },
                  })}
                  inputProps={{
                    maxLength: 3, // limits the input to 10 digits
                    inputMode: "numeric", // ensures only numbers can be typed
                    pattern: "[0-9]*", // restricts input to digits only
                  }}
                  error={!!errors?.nominees_age?.message}
                  helperText={errors?.nominees_age?.message}
                  InputLabelProps={{ shrink: true }}
                  onInput={(e) => {
                    // Allow only digits
                    e.target.value = e.target.value.replace(/[^0-9]/g, '');
                  }}
                />

                <TextField
                  fullWidth
                  disabled={isApproved}
                  label="Address"
                  variant="outlined"
                  name="nominees_address"
                  defaultValue={getValues("nominees_address")}
                  {...register("nominees_address", {
                    required: "Nominee Address Is Required",
                  })}
                  error={!!errors?.nominees_address?.message}
                  helperText={errors?.nominees_address?.message}
                  InputLabelProps={{ shrink: true }}
                />
                <Box
                  flexDirection={{ md: "column", xs: "column" }}
                  display="flex"
                  gap={2}
                >
                  <TextField
                    fullWidth
                    disabled={isApproved}
                    label="City"
                    variant="outlined"
                    name="nominees_city"
                    defaultValue={getValues("nominees_city")}
                    {...register("nominees_city", {
                      required: "Nominee City Is Required",
                    })}
                    error={!!errors?.nominees_city?.message}
                    helperText={errors?.nominees_city?.message}
                    InputLabelProps={{ shrink: true }}
                  />
                  <TextField
                    fullWidth
                    disabled={isApproved}
                    label="State"
                    variant="outlined"
                    name="nominees_state"
                    defaultValue={getValues("nominees_state")}
                    {...register("nominees_state", {
                      required: "Nominee State Is Required",
                    })}
                    error={!!errors?.nominees_state?.message}
                    helperText={errors?.nominees_state?.message}
                    InputLabelProps={{ shrink: true }}
                  />
                  <TextField
                    fullWidth
                    disabled={isApproved}
                    label="Postal Code"
                    variant="outlined"
                    name="nominees_pincode"
                    defaultValue={getValues("nominees_pincode")}
                    {...register("nominees_pincode", {
                      required: "Nominee Pincode Is Required",
                    })}
                    error={!!errors?.nominees_pincode?.message}
                    helperText={errors?.nominees_pincode?.message}
                    InputLabelProps={{ shrink: true }}
                  />
                </Box>
              </Box>
            </AccordionDetails>
          </Accordion>
          {/* Occupation Details */}
          <Accordion
            defaultExpanded
            style={{
              boxShadow: "0px 0px 50px #0000",
              border: "1px solid gray",
              marginBottom: "18px",
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="occupation-details"
              id="occupation-details"
            >
              <Typography fontWeight={"bold"}>Occupation Details</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box display={"flex"} flexDirection={"column"} gap={2}>
                <FormControl fullWidth error={!!errors?.occupation?.message} variant="outlined">
                  <InputLabel id="occupation-label">Occupation</InputLabel>
                  <Controller
                    name="occupation"
                    control={control}
                    defaultValue=""
                    rules={{ required: "Occupation is required" }}
                    render={({ field }) => (
                      <Select
                        labelId="occupation-label"
                        label="Occupation"
                        disabled={isApproved}
                        {...field}
                      >
                        <MenuItem value="Service">Service</MenuItem>
                        <MenuItem value="Retired">Retired</MenuItem>
                        <MenuItem value="Student">Student</MenuItem>
                        <MenuItem value="Business">Business</MenuItem>
                        <MenuItem value="Housewife">Housewife</MenuItem>
                        <MenuItem value="Consultant">Consultant</MenuItem>
                        <MenuItem value="IT">IT</MenuItem>
                        <MenuItem value="Others">Others</MenuItem>
                      </Select>
                    )}
                  />
                  {errors?.occupation?.message && (
                    <FormHelperText>{errors.occupation.message}</FormHelperText>
                  )}
                </FormControl>     </Box>
            </AccordionDetails>
          </Accordion>
          {/* Employment Details */}
          <Accordion
            defaultExpanded
            style={{
              boxShadow: "0px 0px 50px #0000",
              border: "1px solid gray",
              marginBottom: "18px",
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="employment-details"
              id="employment-details"
            >
              <Typography fontWeight={"bold"}>Employment Details</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {employmentDetailsFields.map((employmentDetails, index) => {
                return (
                  <Box display={"flex"} flexDirection={"column"} gap={2}>
                    <TextField
                      fullWidth
                      disabled={isApproved}
                      label="Company Name"
                      variant="outlined"
                      name={`EmploymentDetails.${index}.companyName`}
                      defaultValue={getValues(
                        `BusinessDetails.${index}.companyName`
                      )}
                      {...register(`EmploymentDetails.${index}.companyName`, {
                        required: "Company Name Is Required",
                      })}
                      error={
                        !!errors.EmploymentDetails?.[index]?.companyName
                          ?.message
                      }
                      helperText={
                        errors.EmploymentDetails?.[index]?.companyName?.message
                      }
                      InputLabelProps={{ shrink: true }}
                    />
                    <TextField
                      fullWidth
                      disabled={isApproved}
                      label="Business Nature"
                      variant="outlined"
                      name={`EmploymentDetails.${index}.sector`}
                      defaultValue={getValues(
                        `EmploymentDetails.${index}.sector`
                      )}
                      {...register(`EmploymentDetails.${index}.sector`, {
                        required: "Sector Is Required",
                      })}
                      error={
                        !!errors.EmploymentDetails?.[index]?.sector?.message
                      }
                      helperText={
                        errors.EmploymentDetails?.[index]?.sector?.message
                      }
                      InputLabelProps={{ shrink: true }}
                    />
                    <TextField
                      fullWidth
                      disabled={isApproved}
                      label="Monthly Income"
                      variant="outlined"
                      name={`EmploymentDetails.${index}.monthlyIncome`}
                      defaultValue={getValues(
                        `EmploymentDetails.${index}.monthlyIncome`
                      )}
                      {...register(`EmploymentDetails.${index}.monthlyIncome`, {
                        required: "Monthaly Is Required",
                      })}
                      error={
                        !!errors.EmploymentDetails?.[index]?.monthlyIncome
                          ?.message
                      }
                      helperText={
                        errors.EmploymentDetails?.[index]?.monthlyIncome
                          ?.message
                      }
                      InputLabelProps={{ shrink: true }}
                    />
                    {!isApproved &&
                      <IconButton onClick={() => removeEmployementDetials(index)}>
                        <DeleteIcon />

                      </IconButton>
                    }
                  </Box>
                );
              })}
              {!isApproved &&
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    appendEmploymentDetails({
                      isNew: true,
                    });
                  }}
                  startIcon={<AddIcon />}
                >
                  Add Employement Detail
                </Button>
              }
            </AccordionDetails>
          </Accordion>
          {/* Business Details */}
          <Accordion
            defaultExpanded
            style={{
              boxShadow: "0px 0px 50px #0000",
              border: "1px solid gray",
              marginBottom: "18px",
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="business-details"
              id="business-details"
            >
              <Typography fontWeight={"bold"}>Business Details</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {businessDetailsFields.map((businessDetails, index) => {
                return (
                  <Box display={"flex"} flexDirection={"column"} gap={2}>
                    <TextField
                      fullWidth
                      disabled={isApproved}
                      label="Firm Name"
                      variant="outlined"
                      name={`BusinessDetails.${index}.firmName`}
                      defaultValue={getValues(
                        `BusinessDetails.${index}.firmName`
                      )}
                      {...register(`BusinessDetails.${index}.firmName`, {
                        required: "Firm Name Is Required",
                      })}
                      error={
                        !!errors.BusinessDetails?.[index]?.firmName?.message
                      }
                      helperText={
                        errors.BusinessDetails?.[index]?.firmName?.message
                      }
                      InputLabelProps={{ shrink: true }}
                    />
                    <TextField
                      fullWidth
                      disabled={isApproved}
                      label="Business Nature"
                      variant="outlined"
                      name={`BusinessDetails.${index}.businessNature`}
                      defaultValue={getValues(
                        `BusinessDetails.${index}.businessNature`
                      )}
                      {...register(`BusinessDetails.${index}.businessNature`, {
                        required: "Business Nature Is Required",
                      })}
                      error={
                        !!errors.BusinessDetails?.[index]?.businessNature
                          ?.message
                      }
                      helperText={
                        errors.BusinessDetails?.[index]?.businessNature?.message
                      }
                      InputLabelProps={{ shrink: true }}
                    />
                    <TextField
                      fullWidth
                      disabled={isApproved}
                      label="Monthly Income"
                      variant="outlined"
                      name={`BusinessDetails.${index}.monthlyIncome`}
                      defaultValue={getValues(
                        `BusinessDetails.${index}.monthlyIncome`
                      )}
                      {...register(`BusinessDetails.${index}.monthlyIncome`, {
                        required: "Monthaly Is Required",
                      })}
                      error={
                        !!errors.BusinessDetails?.[index]?.monthlyIncome
                          ?.message
                      }
                      helperText={
                        errors.BusinessDetails?.[index]?.monthlyIncome?.message
                      }
                      InputLabelProps={{ shrink: true }}
                    />
                    {!isApproved &&
                      <IconButton onClick={() => removeBusinessDetails(index)}>
                        <DeleteIcon />
                      </IconButton>
                    }
                  </Box>
                );
              })}
              {!isApproved &&
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    appendBusinessDetails({
                      isNew: true,
                    });
                  }}
                  startIcon={<AddIcon />}
                >
                  Add Business Detail
                </Button>
              }
            </AccordionDetails>
          </Accordion>
          {/* Banking Relation with Other Bank / Society */}
          <Accordion
            defaultExpanded
            style={{
              boxShadow: "0px 0px 50px #0000",
              border: "1px solid gray",
              marginBottom: "18px",
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="bank-details"
              id="bank-details"
            >
              <Typography fontWeight={"bold"}>
                Banking Relation with Other Bank / Society
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box display={"flex"} flexDirection={"column"} gap={2}>
                {bankDetailsFields.map((bankDetail, index) => {
                  return (
                    <Box
                      key={index}
                      flexDirection={{ md: "column", xs: "column" }}
                      display="flex"
                      gap={2}
                      alignItems="center"
                    >
                      <TextField
                        disabled={isApproved}
                        fullWidth
                        label="Name of Bank / Society"
                        variant="outlined"
                        name={`BankDetails.${index}.accountHolderName`}
                        {...register(`BankDetails.${index}.accountHolderName`, {
                          required: "Account Holder Name Is Required",
                        })}
                        defaultValue={getValues(
                          `BankDetails.${index}.accountHolderName`
                        )}
                        error={
                          !!errors.BankDetails?.[index]?.accountHolderName
                            ?.message
                        }
                        helperText={
                          errors.BankDetails?.[index]?.accountHolderName
                            ?.message
                        }
                        InputLabelProps={{ shrink: true }}
                      />
                      <TextField
                        fullWidth
                        disabled={isApproved}
                        label="Account No"
                        variant="outlined"
                        name={`BankDetails.${index}.accountNo`}
                        {...register(`BankDetails.${index}.accountNo`, {
                          required: "Account No Is Required",
                        })}
                        defaultValue={getValues(
                          `BankDetails.${index}.accountNo`
                        )}
                        error={
                          !!errors.BankDetails?.[index]?.accountNo?.message
                        }
                        helperText={
                          errors.BankDetails?.[index]?.accountNo?.message
                        }
                        InputLabelProps={{ shrink: true }}
                      />
                      <TextField
                        fullWidth
                        disabled={isApproved}
                        label="IFSC Code"
                        variant="outlined"
                        name={`BankDetails.${index}.ifscCode`}
                        {...register(`BankDetails.${index}.ifscCode`, {
                          required: "IFSC Code Is Required",
                        })}
                        defaultValue={getValues(
                          `BankDetails.${index}.ifscCode`
                        )}
                        error={!!errors.BankDetails?.[index]?.ifscCode?.message}
                        helperText={
                          errors.BankDetails?.[index]?.ifscCode?.message
                        }
                        InputLabelProps={{ shrink: true }}
                      />
                      <TextField
                        fullWidth
                        disabled={isApproved}
                        label="Branch"
                        variant="outlined"
                        name={`BankDetails.${index}.branch`}
                        {...register(`BankDetails.${index}.branch`, {
                          required: "Branch Name is Required",
                        })}
                        defaultValue={getValues(`BankDetails.${index}.branch`)}
                        error={!!errors.BankDetails?.[index]?.branch?.message}
                        helperText={
                          errors.BankDetails?.[index]?.branch?.message
                        }
                        InputLabelProps={{ shrink: true }}
                      />
                      <FormControl
                        fullWidth
                        error={!!errors?.BankDetails?.[index]?.accountType?.message}
                        variant="outlined"
                      >
                        <InputLabel id={`account-type-label-${index}`}>Account Type</InputLabel>
                        <Controller
                          name={`BankDetails.${index}.accountType`}
                          control={control}
                          defaultValue=""
                          rules={{ required: "Account Type is required" }}
                          render={({ field }) => (
                            <Select
                              labelId={`account-type-label-${index}`}
                              label="Account Type"
                              disabled={isApproved}
                              {...field}
                            >
                              <MenuItem value="Savings">Savings</MenuItem>
                              <MenuItem value="Current">Current</MenuItem>
                              <MenuItem value="Salary">Salary</MenuItem>
                              <MenuItem value="Recurring">Recurring</MenuItem>
                            </Select>
                          )}
                        />
                        {errors?.BankDetails?.[index]?.accountType?.message && (
                          <FormHelperText>
                            {errors.BankDetails[index].accountType.message}
                          </FormHelperText>
                        )}
                      </FormControl>
                      {!isApproved &&
                        <IconButton onClick={() => removeBankDetail(index)}>
                          <DeleteIcon />
                        </IconButton>
                      }
                    </Box>
                  );
                })}
                {!isApproved &&
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      appendBankDetails({
                        isNew: true,
                      });
                    }}
                    startIcon={<AddIcon />}
                  >
                    Add Bank Detail
                  </Button>
                }
              </Box>
            </AccordionDetails>
          </Accordion>
          {/* KYC Documents */}
          <Accordion
            defaultExpanded
            style={{
              boxShadow: "0px 0px 50px #0000",
              border: "1px solid gray",
              marginBottom: "18px",
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="kyc-documents"
              id="kyc-documents"
            >
              <Typography fontWeight={"bold"}>KYC Documents</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box display={"flex"} flexDirection={"column"} gap={2}>
                <Box
                  display={"flex"}
                  flexDirection={{ md: "column", xs: "column" }}
                  gap={2}
                >

                  <Box display={'flex'} flexDirection={'column'} gap={1} border={'2px solid black'} padding={2}>
                    <h1 style={{ fontSize: '18px', fontWeight: 'bold' }}>Required Documents</h1>
                    <Box display={"flex"} gap={1}>
                      {watch("kycDocument") && (
                        <ImageModel
                          style={{ height: "40px", width: "40px" }}
                          file={watch("kycDocument")}
                        />
                      )}
                      {!isApproved ?
                        <Button
                          variant="contained"
                          component="label"
                          startIcon={<UploadFileIcon />}
                          fullWidth
                        >
                          Upload KYC Document
                          <TextField
                            fullWidth
                            hidden
                            disabled={isApproved}
                            style={{ display: "none" }}
                            type="file"
                            label="KYC Document"
                            variant="outlined"
                            name="kycDocument"
                            onChange={(e) => {
                              setValue("kycDocument", e?.target?.files?.[0]);
                            }}
                          />
                        </Button> : <div className={classes.documentNameBox}><h4 className={classes.documentName}>KYC Document</h4></div>
                      }
                    </Box>
                    <Box display={"flex"} gap={1}>
                      {watch("panCard") && (
                        <ImageModel
                          style={{ height: "40px", width: "40px" }}
                          file={watch("panCard")}
                        />
                      )}
                      {!isApproved ?
                        <Button
                          variant="contained"
                          component="label"
                          startIcon={<UploadFileIcon />}
                          fullWidth
                        >
                          Upload Pan Card
                          <TextField
                            fullWidth
                            hidden
                            disabled={isApproved}
                            style={{ display: "none" }}
                            type="file"
                            label="PAN Card"
                            variant="outlined"
                            name="panCard"
                            onChange={(e) => {
                              setValue("panCard", e?.target?.files?.[0]);
                            }}
                          />
                        </Button> : <div className={classes.documentNameBox}><h4 className={classes.documentName}>Pan Card</h4></div>
                      }
                    </Box>
                    <Box display={"flex"} gap={1}>
                      {watch("adharCard") && (
                        <ImageModel
                          style={{ height: "40px", width: "40px" }}
                          file={watch("adharCard")}
                        />
                      )}
                      {!isApproved ?
                        <Button
                          variant="contained"
                          component="label"
                          startIcon={<UploadFileIcon />}
                          fullWidth
                        >
                          Upload Aadhar Card
                          <TextField
                            fullWidth
                            disabled={isApproved}
                            type="file"
                            label="Aadhar Card"
                            style={{ display: "none" }}
                            variant="outlined"
                            name="adharCard"
                            onChange={(e) => {
                              setValue("adharCard", e?.target?.files?.[0]);
                            }}
                          />
                        </Button> : <div className={classes.documentNameBox}><h4 className={classes.documentName}>Aadhar Card</h4></div>
                      }
                    </Box>
                    <Box display={"flex"} gap={1}>
                      {watch("signature") && (
                        <ImageModel
                          style={{ height: "40px", width: "40px" }}
                          file={watch("signature")}
                        />
                      )}
                      {!isApproved ?
                        <Button
                          variant="contained"
                          component="label"
                          startIcon={<UploadFileIcon />}
                          fullWidth
                        >
                          Upload Signature
                          <TextField
                            fullWidth
                            type="file"
                            disabled={isApproved}
                            style={{ display: "none" }}
                            label="Signature"
                            variant="outlined"
                            name="signature"
                            onChange={(e) => {
                              setValue("signature", e?.target?.files?.[0]);
                            }}
                          />
                        </Button> : <div className={classes.documentNameBox}><h4 className={classes.documentName}>Signature</h4>
                        </div>}
                    </Box>
                  </Box>
                  <Box display={'flex'} flexDirection={'column'} gap={1} border={'2px solid black'} padding={2}>
                    <h1 style={{ fontSize: '18px', fontWeight: 'bold' }}>Additional Documents</h1>
                    <Box display={"flex"} gap={1}>
                      {watch("drivingLicence") && (
                        <ImageModel
                          style={{ height: "40px", width: "40px" }}
                          file={watch("drivingLicence")}
                        />
                      )}
                      {!isApproved ?
                        <Button
                          variant="contained"
                          component="label"
                          startIcon={<UploadFileIcon />}
                          fullWidth
                        >
                          Upload Driving Licence
                          <TextField
                            fullWidth
                            type="file"
                            disabled={isApproved}
                            label="Driving Licence"
                            style={{ display: "none" }}
                            variant="outlined"
                            name="drivingLicence"
                            onChange={(e) => {
                              setValue("drivingLicence", e?.target?.files?.[0]);
                            }}
                          />
                        </Button>
                        : <div className={classes.documentNameBox}><h4 className={classes.documentName}>Driving Licence</h4></div>}
                    </Box>
                    <Box display={"flex"} gap={1}>
                      {watch("voterId") && (
                        <ImageModel
                          style={{ height: "40px", width: "40px" }}
                          file={watch("voterId")}
                        />
                      )}
                      {!isApproved ?
                        <Button
                          variant="contained"
                          component="label"
                          startIcon={<UploadFileIcon />}
                          fullWidth
                        >
                          Upload Voter ID
                          <TextField
                            fullWidth
                            disabled={isApproved}
                            type="file"
                            style={{ display: "none" }}
                            label="Voter ID"
                            variant="outlined"
                            name="voterId"
                            onChange={(e) => {
                              setValue("voterId", e?.target?.files?.[0]);
                            }}
                          />
                        </Button>
                        : <div className={classes.documentNameBox}><h4 className={classes.documentName}>Voter ID</h4></div>}
                    </Box>
                    <Box display={"flex"} gap={1}>
                      {watch("telephoneBill") && (
                        <ImageModel
                          style={{ height: "40px", width: "40px" }}
                          file={watch("telephoneBill")}
                        />
                      )}
                      {!isApproved ?
                        <Button
                          variant="contained"
                          component="label"
                          startIcon={<UploadFileIcon />}
                          fullWidth
                        >
                          Upload Telephone Bill
                          <TextField
                            fullWidth
                            type="file"
                            disabled={isApproved}
                            style={{ display: "none" }}
                            label="Telephone Bill"
                            variant="outlined"
                            name="telephoneBill"
                            onChange={(e) => {
                              setValue("telephoneBill", e?.target?.files?.[0]);
                            }}
                          />
                        </Button>
                        : <div className={classes.documentNameBox}><h4 className={classes.documentName}>Telephone Bill</h4></div>}
                    </Box>
                    <Box display={"flex"} gap={1}>
                      {watch("electricityBill") && (
                        <ImageModel
                          style={{ height: "40px", width: "40px" }}
                          file={watch("electricityBill")}
                        />
                      )}
                      {!isApproved ?
                        <Button
                          variant="contained"
                          component="label"
                          startIcon={<UploadFileIcon />}
                          fullWidth
                        >
                          Upload Electricity Bill
                          <TextField
                            fullWidth
                            type="file"
                            disabled={isApproved}
                            label="Electricity Bill"
                            style={{ display: "none" }}
                            variant="outlined"
                            name="electricityBill"
                            onChange={(e) => {
                              setValue("electricityBill", e?.target?.files?.[0]);
                            }}
                          />
                        </Button>
                        : <div className={classes.documentNameBox}><h4 className={classes.documentName}>Telephone Bill</h4></div>}
                    </Box>
                    <Box display={"flex"} gap={1}>
                      {watch("rationCard") && (
                        <ImageModel
                          style={{ height: "40px", width: "40px" }}
                          file={watch("rationCard")}
                        />
                      )}
                      {!isApproved ?
                        <Button
                          variant="contained"
                          component="label"
                          startIcon={<UploadFileIcon />}
                          fullWidth
                        >
                          Upload Ration Card
                          <TextField
                            fullWidth
                            type="file"
                            disabled={isApproved}
                            label="Ration Card"
                            variant="outlined"
                            style={{ display: "none" }}
                            name="rationCard"
                            onChange={(e) => {
                              setValue("rationCard", e?.target?.files?.[0]);
                            }}
                          />
                        </Button>
                        : <div className={classes.documentNameBox}><h4 className={classes.documentName}>Ration Card</h4></div>}
                    </Box>
                    <Box display={"flex"} gap={1}>
                      {watch("bankStatement") && (
                        <ImageModel
                          style={{ height: "40px", width: "40px" }}
                          file={watch("bankStatement")}
                        />
                      )}
                      {!isApproved ?
                        <Button
                          variant="contained"
                          component="label"
                          startIcon={<UploadFileIcon />}
                          fullWidth
                        >
                          Upload Bank Statement
                          <TextField
                            fullWidth
                            disabled={isApproved}
                            type="file"
                            label="Bank Statement"
                            style={{ display: "none" }}
                            variant="outlined"
                            name="bankStatement"
                            onChange={(e) => {
                              setValue("bankStatement", e?.target?.files?.[0]);
                            }}
                          />
                        </Button>
                        : <div className={classes.documentNameBox}><h4 className={classes.documentName}>Bank Statement</h4></div>}
                    </Box>
                    <Box display={"flex"} gap={1}>
                      {watch("rentAgreement") && (
                        <ImageModel
                          style={{ height: "40px", width: "40px" }}
                          file={watch("rentAgreement")}
                        />
                      )}
                      {!isApproved ?
                        <Button
                          variant="contained"
                          component="label"
                          startIcon={<UploadFileIcon />}
                          fullWidth
                        >
                          Upload Rent Agreement
                          <TextField
                            fullWidth
                            type="file"
                            disabled={isApproved}
                            style={{ display: "none" }}
                            label="Rent Agreement"
                            variant="outlined"
                            name="rentAgreement"
                            onChange={(e) => {
                              setValue("rentAgreement", e?.target?.files?.[0]);
                            }}
                          />
                        </Button>
                        : <div className={classes.documentNameBox}><h4 className={classes.documentName}>Bank Statement</h4></div>}
                    </Box>
                  </Box>
                </Box>
              </Box>
            </AccordionDetails>
          </Accordion>
        </Box>

        {/* User Right Side Section For KYC Process Details */}
        <Box className={`${classes.heightFull} ${classes.rightSideSection}`}>
          {!isApproved &&
            <div style={{ marginBottom: "30px", display: "flex", justifyContent: "space-between" }}>
              <div></div>
              <Button
                variant="contained"
                type="submit"
                onClick={handleSubmit(submit)}
              >
                Submit
              </Button>
            </div>
          }
          <Typography fontWeight={"bold"}>Apply for New Membership </Typography>
          <br />
          <Box display={"flex"} justifyContent={"center"} gap={1} >
            <InfoIcon
              fontSize="12px"
              style={{ marginTop: "2px" }}
              color="success"
            />
            <Typography fontWeight={600}>
              Before Application of Membership You Must Complete All KYC Details
            </Typography>
          </Box>
          <br />
          <Typography fontSize={"14px"}>
            Kindly enroll me as Member of the Finland Cooperative Credit Society
            Ltd. I am here with paying you in Cash / by Cheque / By Draft vide
            no
            <br />
            <br />
            For Total Amount of Rs{" "}
            <input
              placeholder="Admission Fee"
              disabled={isApproved}
              {...register('admissionFee')}
              defaultValue={getValues('admissionFee')}
              style={{
                paddingLeft: "4px",
                border: "none",
                borderBottom: "1px solid black",
                outline: "none",
              }}
            />{" "}
            ( Towards the admission fee ) and For Total Amount of Rs{" "}
            <input
              placeholder="Application Fee"
              {...register('applicationFee')}
              disabled={isApproved}
              defaultValue={getValues('applicationFee')}
              style={{
                paddingLeft: "4px",
                border: "none",
                borderBottom: "1px solid black",
                outline: "none",
              }}
            />
            ( Towards the share application fee )
            <input
              {...register('numberOfShares')}
              defaultValue={getValues('numberOfShares')}
              placeholder="Number Of Share"
              disabled={isApproved}
              style={{
                paddingLeft: "4px",
                border: "none",
                borderBottom: "1px solid black",
                outline: "none",
              }}
            />{" "}
            for Number of Shares.
            <br />
            <br />
            I have read the rules and declare that I am eligible to become a
            member. I promise
            <br />
            to abide by bye-laws and rules of the society in force or as may be
            amended from
            <br />
            time to time. All details provided in profile is true and correct.
            <br />
          </Typography>
          <br />
          {!isApproved &&
            <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
              <Checkbox defaultChecked {...register('checked', { required: "Terms & Condition Check Is Required" })}
              />
              <Typography fontSize={"12px"} style={{cursor:"pointer",color:"blue"}} onClick={handleClickOpen}>
                Declaration Terms & Condition of Membership Application
              </Typography>
              <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
                <DialogTitle className={classes.title}>Terms & Conditions</DialogTitle>
                <DialogContent className={classes.dialogContent}>
                  <Typography>
                    * The Credit Co. Op. Societies are functioning as autonomous cooperative organizations, accountable to their members, and not under the
                    administrative control of the District Registrar.
                  </Typography>
                  <Typography>
                    * No person shall be eligible for being a member of a Credit Co. Op. Society if:
                  </Typography>
                  <ul className={classes.list}>
                    <li>
                      a) His business is in conflict or competitive with the business of such Credit Co. Op. Society, or
                    </li>
                    <li>
                      b) He used for two consecutive years the services below the minimum level specified in the by-Laws, or
                    </li>
                    <li>
                      c) He has not attended three consecutive general meetings of the Credit Co. Op. Society, and such absence has not been condoned by the
                      members in the general meeting, or
                    </li>
                    <li>
                      d) He has made any default in payment of any amount to be paid to the Credit Co. Op. Society under the bye-laws of such society.
                    </li>
                  </ul>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose} color="primary" variant="contained" className={classes.button}>
                    Close
                  </Button>
                </DialogActions>
              </Dialog>
            </Box>


          }
          {errors?.checked?.message &&
            <Typography fontSize={"10px"} color={'red'}>
              {errors.checked.message}
            </Typography>
          }


        </Box>
      </Box>
    </>
  );
};

ProfilePage.propTypes = {
  getUserDetails: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return { auth: state.auth?.auth?.data }
};

const mapDispatchToProps = (dispatch) => ({
  getUserDetails: bindActionCreators(AuthActions.loginWithOtp, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfilePage);
