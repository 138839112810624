import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  comicButton: {
    display: "inline-block",
    padding: "10px 20px",
    fontSize: "18px",
    fontWeight: "bold",
    textAlign: "center",
    textDecoration: "none",
    color: "#fff",
    backgroundColor: "#ff5252",
    border: "2px solid #000",
    borderRadius: "10px",
    boxShadow: "5px 5px 0px #000",
    transition: "all 0.3s ease",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#fff",
      color: "#ff5252",
      border: "2px solid #ff5252",
      boxShadow: "5px 5px 0px #ff5252",
    },
    "&:active": {
      backgroundColor: "#0561AB",
      boxShadow: "none",
      transform: "translateY(4px)",
    },
  },
});

const ButtonOrangeShadow = ({ name, color, handleClick }) => {
  const classes = useStyles();
  return (
    <button
      className={classes.comicButton}
      style={
        color && {
          backgroundColor: "white",
          color: "black",
        }
      }
      onClick={() => handleClick(true)}
    >
      {name}
    </button>
  );
};

export default ButtonOrangeShadow;
