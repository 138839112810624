import React from "react";
import FAQ from "../Custom/FAQ";

const Faqs = ({ faqs }) => {
  return (
    <div style={{ padding: "20px 10%" }}>
      <FAQ faqs={faqs} />
    </div>
  );
};

export default Faqs;
