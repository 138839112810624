import { Box, TextField, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import * as authActions from "../redux/actions/auth";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    padding: "16px",
    boxSizing: "border-box",
  },
  formContainer: {
    width: "100%",
    maxWidth: "400px",
    padding: "32px",
    borderRadius: "12px",
    border: "1px solid #0561AB",
    boxShadow: theme.shadows[10],
    backgroundColor: "#fff",
    [theme.breakpoints.down("sm")]: {
      padding: "24px",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "16px",
    },
  },
  title: {
    textAlign: "center",
    fontWeight: "bold",
    fontSize: "1.5rem",
    marginBottom: theme.spacing(10),
    color: "#0561AB",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.2rem",
      marginBottom: theme.spacing(8),
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "1rem",
      marginBottom: theme.spacing(6),
    },
  },
  form: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
  },
  link: {
    textAlign: "center",
    marginTop: theme.spacing(1),
  },
  btnContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

const AdminLogin = ({ adminLogin, auth, resetAuth }) => {
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    if (auth?.success) {
      toast.success("Admin login successfully. Redirecting to dashboard..", {
        duration: 4000,
      });

      localStorage.setItem("token", auth?.data?.token);
      localStorage.setItem("role", auth?.data?.role);

      window.location.href = "/#/admin/dashboard";
      window.scrollTo({ top: 0, behavior: "smooth" });
    }

    if (auth?.error) {
      toast.error(auth?.error?.message);
    }

    resetAuth();
  }, [auth?.success, auth?.error]);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    adminLogin({ email, password });
  };

  return (
    <Box className={classes.root}>
      <Box className={classes.formContainer}>
        <p className={classes.title}>Admin Login</p>
        {error && <Typography color="error">{error}</Typography>}
        <form className={classes.form} onSubmit={handleSubmit}>
          <TextField
            variant="outlined"
            label="Email"
            type="email"
            fullWidth
            required
            value={email}
            onChange={handleEmailChange}
          />
          <TextField
            variant="outlined"
            label="Password"
            type="password"
            fullWidth
            required
            value={password}
            onChange={handlePasswordChange}
          />
          <Box className={classes.btnContainer}>
            <button
              style={{
                backgroundColor: "#0561AB",
                color: "#fff",
                border: "none",
                fontWeight: "bold",
                borderRadius: "4px",
                padding: "10px 18px",
                cursor: "pointer",
                outline: "none",
              }}
              type="submit"
              disabled={loading}
            >
              Sign in
            </button>
            {/* <LoadMoreButton name="Sign in" type="submit" disabled={loading} /> */}
          </Box>
        </form>
      </Box>
    </Box>
  );
};

const mapStateToProps = ({ auth: { auth } }) => {
  return {
    auth,
  };
};

const mapDispatchToProps = (dispatch) => ({
  adminLogin: bindActionCreators(authActions.adminLogin, dispatch),
  resetAuth: bindActionCreators(authActions.resetAuth, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminLogin);
