import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import React, { useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Container,
  Grid,
  Box,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import Slider from "react-slick";
import PropTypes from "prop-types";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <ArrowForwardIosIcon
      className={className}
      style={{
        ...style,
        color: "black",
        fontSize: "1.4rem",
        marginRight: "15px",
      }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <ArrowBackIosIcon
      className={className}
      style={{
        ...style,
        color: "black",
        fontSize: "1.4rem",
        marginLeft: "15px",
      }}
      onClick={onClick}
    />
  );
}

const useStyles = makeStyles((theme) => ({
  section: {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
    overflow: "hidden",
    position: "relative",
    padding: "60px 0",
    [theme.breakpoints.up("md")]: {
      padding: "100px 0",
    },
  },
  heading: {
    fontWeight: "bold",
    fontSize: "48px !important",
    margin:0,
    [theme.breakpoints.down("md")]: {
      fontSize: "32px !important",
      textAlign: "center",
      margin:0,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "24px !important",
      textAlign: "center",
      margin:0,
    },
  },
  subHeading: {
    opacity: 0.8,
    fontSize: "18px",
    lineHeight: 1.7,
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
      textAlign: "center",
      marginTop: theme.spacing(2),
    },
  },
  shapeOne: {
    position: "absolute",
    bottom: 0,
    left: 0,
    width: "100%",
    height: "auto",
    [theme.breakpoints.down("sm")]: {
      width: "80%",
    },
  },
  shapeTwo: {
    position: "absolute",
    top: 0,
    right: 0,
    width: "100%",
    height: "auto",
    [theme.breakpoints.down("sm")]: {
      width: "80%",
    },
  },
  card: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[3],
    padding: theme.spacing(4),
    marginTop: theme.spacing(4),
    minHeight: 250,
    transition: "transform 0.3s ease, box-shadow 0.3s ease",
    cursor: "pointer",
    "&:hover": {
      transform: "scale(1.02)",
      boxShadow: "0 10px 20px rgba(0, 0, 0, 0.2)",
    },
  },
  cardContent: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(4),
  },
  cardMedia: {
    borderRadius: "50%",
    border: `2px solid ${theme.palette.divider}`,
    width: 65,
    height: 65,
    marginRight: theme.spacing(3),
  },
  carouselIndicator: {
    margin: 0,
    bottom: -50,
    "& button": {
      width: 10,
      height: 10,
      borderRadius: "50%",
      backgroundColor: theme.palette.text.primary,
      opacity: 0.2,
    },
    "& .Mui-selected": {
      backgroundColor: theme.palette.primary.main,
      opacity: 1,
    },
  },
}));

const TestimonialItem = ({ testimonial }) => {
  const classes = useStyles();
  // Declare an array to hold the state for each testimonial item
  const [expandedStates, setExpandedStates] = useState(
    testimonial.map(() => false)
  );

  const handleToggle = (index) => {
    // Toggle the state of the specific testimonial item
    setExpandedStates((prev) =>
      prev.map((state, i) => (i === index ? !state : state))
    );
  };

  return (
    <Grid container spacing={4} padding={3}>
      {testimonial.map((item, i) => {
        const { name, feedback } = item;
        const isExpanded = expandedStates[i];
        const trimmedFeedback =
          feedback.length > 100 && !isExpanded
            ? feedback.substring(0, 100) + "..."
            : feedback;

        return (
          <Grid item xs={12} lg={4} key={i}>
            <Card className={classes.card}>
              <CardContent>
                <Box className={classes.cardContent}>
                  <Box>
                    <Typography color={"#0561AB"} variant="h5" component="h4">
                      {name}
                    </Typography>
                  </Box>
                </Box>
                <p
                  style={{
                    fontFamily: "sans-serif",
                    color: "black",
                  }}
                >
                  {trimmedFeedback}
                  {feedback.length > 100 && (
                    <span
                      className={classes.readMoreButton}
                      onClick={() => handleToggle(i)}
                      style={{color: "#2B5CAE"}}
                    >
                      {isExpanded ? " Read Less" : " Read More"}
                    </span>
                  )}
                </p>
              </CardContent>
            </Card>
          </Grid>
        );
      })}
    </Grid>
  );
};

TestimonialItem.propTypes = {
  testimonial: PropTypes.array.isRequired,
};

const Shapes = () => {
  const classes = useStyles();
  return (
    <>
      <svg
        className={classes.shapeOne}
        width="404"
        height="472"
        viewBox="0 0 404 572"
        fill="none"
      >
        <circle
          cx="118"
          cy="286"
          r="265.5"
          stroke="#F35425"
          strokeOpacity="0.2"
          strokeWidth="41"
        />
      </svg>
      <svg
        className={classes.shapeTwo}
        width="269"
        height="379"
        viewBox="0 0 269 479"
        fill="none"
      >
        <circle
          cx="239.5"
          cy="239.5"
          r="239.5"
          fill="#1976D2"
          fillOpacity="0.25"
        />
      </svg>
    </>
  );
};

const Testimonial = ({ testimonials }) => {
  const classes = useStyles();
  const [index, setIndex] = useState(0);

  // testimonials = [
  //   [
  //     {
  //       name: "Deep Prajapati",
  //       feedback: "React Hook useEffect has missing dependencies",
  //     },
  //     {
  //       name: "Deep Prajapati",
  //       feedback: "React Hook useEffect has missing dependencies",
  //     },
  //     {
  //       name: "Deep Prajapati",
  //       feedback: "React Hook useEffect has missing dependencies",
  //     },
  //   ],
  //   [
  //     {
  //       name: "Deep Prajapati",
  //       feedback: "React Hook useEffect has missing dependencies",
  //     },
  //     {
  //       name: "Deep Prajapati",
  //       feedback: "React Hook useEffect has missing dependencies",
  //     },
  //     {
  //       name: "Deep Prajapati",
  //       feedback: "React Hook useEffect has missing dependencies",
  //     },
  //   ],
  // ];

  const handleSelect = (selectedIndex) => setIndex(selectedIndex);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange: (current) => setIndex(current),
    appendDots: (dots) => (
      <div
        style={{
          margin: "0",
          bottom: "-50px",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <ul style={{ padding: 0, margin: 0 }}> {dots} </ul>
      </div>
    ),
    customPaging: (i) => (
      <div
        style={{
          width: "10px",
          height: "10px",
          borderRadius: "50%",
          background: i === index ? "#0d6efd" : "rgba(40, 48, 59, 0.2)",
        }}
      />
    ),
  };

  return (
    <section className={classes.section}>
      <Shapes />
      <Container>
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          sx={{ marginBottom: { xs: 2, md: 5 } }}
        >
          <Grid item xs={12} md={6} lg={8}>
            <Typography variant="h2" mb={1} className={classes.heading}>
              What Our Members Say: Real Stories, Real Savings!
            </Typography>
          </Grid>
        </Grid>

        <Slider {...settings}>
          {testimonials?.map((testimonial, i) => (
            <div key={i}>
              <TestimonialItem testimonial={testimonial} />
            </div>
          ))}
        </Slider>
      </Container>
    </section>
  );
};

Testimonial.propTypes = {
  testimonials: PropTypes.array.isRequired,
};

export default Testimonial;
