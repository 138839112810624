import {all} from "redux-saga/effects";
import userWatcher from "./users"
import faqsWatcher from "./faqs";
import blogsWatcher from "./blogs";
import testimonialsWatcher from "./testimonials";
import authWatcher from "./auth"
import productDetailWatcher from "./productDetail"
import leadsWatcher from "./leads";
import enquiryWatcher from "./enquiry";

export default function* rootSaga() {
  yield all([
    userWatcher(),
    faqsWatcher(),
    blogsWatcher(),
    testimonialsWatcher(),
    authWatcher(),
    productDetailWatcher(),
    leadsWatcher(),
    enquiryWatcher()
  ])
}

