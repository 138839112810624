import React from "react";
import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import HowToInvestStepComponent from "../Custom/HowToInvestStepComponent";

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: "center",
    padding: "20px",
    fontFamily: "sans-serif",
  },
  title: {
    fontSize: "2rem",
    fontWeight: "bold",
    marginBottom: "10px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.5rem",
    },
  },
  subtitle: {
    fontSize: "1.2rem",
    marginBottom: "30px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
    },
  },
  container: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "40px 0",
    maxWidth: "100%",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      margin: "20px 0",
    },
  },
  sectionImage: {
    width: "100%",
    maxWidth: "500px",
    height: "auto",
    [theme.breakpoints.down("md")]: {
      marginBottom: "20px",
    },
  },
}));

const HowToInvest = ({ steps,title, category }) => {
  const classes = useStyles();

  return (
    <Container className={classes.root}>
      <Typography variant="h4" className={classes.title}>
        {["Current Account","Saving Account"].includes(title) ? "Opening" : category === "investment" ? "Investing" :  "Applying"} {  !["Current Account","Saving Account"].includes(title) &&  "in"} Finland {title} online is easy
      </Typography>
      <Typography variant="body1" className={classes.subtitle}>
        {["Current Account","Saving Account"].includes(title) ? "Open" : category === "investment" ? "Invest" : "Apply"} your Finland {title} in four easy steps.
      </Typography>
      <div className={classes.container}>
        <img
          className={classes.sectionImage}
          src="Steps Image.svg"
          alt="Checklist"
        />
        <HowToInvestStepComponent steps={steps} />
      </div>
    </Container>
  );
};

export default HowToInvest;
