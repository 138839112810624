export const GET_ENQUIRIES = 'GET_ENQUIRIES';
export const ENQUIRIES_FETCH_SUCCESS = 'ENQUIRIES_FETCH_SUCCESS';
export const ENQUIRIES_FETCH_FAIL = 'ENQUIRIES_FETCH_FAIL';
export const ENQUIRIES_RESET = 'ENQUIRIES_RESET';

export const getEnquiries = (payload) => ({
  type: GET_ENQUIRIES,
  payload
});

export const getEnquiriesSuccess = (payload) => ({
  type: ENQUIRIES_FETCH_SUCCESS,
  payload
});

export const getEnquiriesFail = (payload) => ({
  type: ENQUIRIES_FETCH_FAIL,
  payload
});

export const resetEnquiries = () => ({
  type: ENQUIRIES_RESET
});
