import {GET_TESTIMONIALS, GET_TESTIMONIALS_SUCCESS, GET_TESTIMONIALS_FAIL, RESET_TESTIMONIALS } from "../actions/testimonials"

const initialState = {
  testimonials: {
    data: [],
    loading: false,
    error: null,
    success: false
  }
}

const testimonialsReducer = (state = initialState, action) => {
  switch(action.type) {
    case GET_TESTIMONIALS:
      return {
        ...state,
        testimonials: {
          ...state.testimonials,
          loading: true,
          success: false,
          error: null
        }
      }
    case GET_TESTIMONIALS_SUCCESS:
      return {
        ...state,
        testimonials: {
          ...state.testimonials,
          data: action.payload?.data || [],
          loading: false,
          success: true,
          error: null
        }
      }
    case GET_TESTIMONIALS_FAIL:
      return {
        ...state,
        testimonials: {
          ...state.testimonials,
          loading: false,
          success: false,
          error: action.payload
        }
      }
    case RESET_TESTIMONIALS:
      return {
        ...state,
        testimonials: {
          ...state.testimonials,
          loading: false,
          success: false,
          error: null
        }
      }
    default:
      return state;
  }
}

export default testimonialsReducer;