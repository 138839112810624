export const GET_USERS = 'GET_USERS'
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS'
export const GET_USERS_FAIL = 'GET_USERS_FAIL'
export const RESET_USERS = 'RESET_USERS'

export const getUsers = (payload) => ({
  type: GET_USERS,
  payload
})

export const getUsersSuccess = (payload) => ({
  type: GET_USERS_SUCCESS,
  payload
})

export const getUsersFail = (payload) => ({
  type: GET_USERS_FAIL,
  payload
})

export const resetUsers = () => ({
  type: RESET_USERS,
})