import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Box, Typography, Tabs, Tab, Button } from "@mui/material";
import Investment from "../Calculators/Investment";
import ProductCard from "../Custom/ProductCard";
import PieChart from "../Custom/PieChart";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import LoanCalculator from "../Calculators/Loan";
import Slider from "react-slick";
import MonthlyInvestmentReport from "../Calculators/MonthlyInvestmentReport";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <ArrowForwardIosIcon
      className={className}
      style={{ ...style, color: "#1798DE" }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <ArrowBackIosIcon
      className={className}
      style={{ ...style, color: "#1798DE" }}
      onClick={onClick}
    />
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    margin: "10px 0",
    borderRadius: "50px",
    color: "#333132",
    padding: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
    },
    background: "#ffffff",
    background:
      "linear-gradient(135deg, rgba(255, 255, 255, 1) 25%, rgba(230, 242, 255, 0.1) 25%, rgba(230, 242, 255, 0.1) 50%, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 1) 75%, rgba(230, 242, 255, 0.2) 75%, rgba(230, 242, 255, 0.1))",
    backgroundSize: "40px 40px",
    animation: "$move 4s linear infinite",
  },
  "@keyframes move": {
    "0%": {
      backgroundPosition: "0 0",
    },
    "100%": {
      backgroundPosition: "40px 40px",
    },
  },
  title: {
    color: "#0561AB",
    fontFamily: "sans-serif",
    fontSize: "2.6rem",
    fontWeight: "bold",
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.8rem",
    },
  },
  usecase: {
    display: "flex",
    flexDirection: "column",
    marginTop: theme.spacing(4),
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
    },
  },
  left: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    flex: 2,
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "60%",
    },
  },
  right: {
    width: "100%",
    flex: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      width: "40%",
    },
  },
  tabs: {
    marginBottom: theme.spacing(2),
    width: "fit-content",
    padding: "0px 10px",
    display: 'flex',
    flexDirection: 'column !important',
    [theme.breakpoints.down("sm")]:{
      display:"none !important"
    }
  },
  tabsButtonForMobileScreen:{
    display:"flex",
    gap:"10px",
    flexDirection:"column",
    [theme.breakpoints.up("sm")]:{
      display:"none !important"
    }
  },
  CalculatorTabSection: {
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
    },
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      alignItems: "center",
    },
  },
  tab: {
    color: "#0561AB",
  },
  tabSelected: {
    color: "#76ff03",
    border: "1px solid #76ff03",
  },
  sliderContainer: {
    width: "100%",
    display: "flex",
    position: "relative",
    color: "black",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  arrow: {
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    zIndex: 1,
    cursor: "pointer",
    color: "#000",
    backgroundColor: "rgba(255, 255, 255, 0.7)",
    borderRadius: "50%",
    padding: theme.spacing(1),
  },
  prevArrow: {
    left: "-35px",
  },
  nextArrow: {
    right: "-35px",
  },
  // pieChartContainer: {
  //   width: "100%",
  //   height: "300px",
  //   marginTop: theme.spacing(3),
  //   [theme.breakpoints.up("md")]: {
  //     height: "200px",
  //   },
  // },
  svgShape: {
    position: "absolute",
    zIndex: 1,
    opacity: 0.2,
  },
  shapeOne: {
    bottom: 0,
    left: 0,
    width: "50%",
    height: "auto",
  },
  shapeTwo: {
    top: 0,
    right: 0,
    width: "50%",
    height: "auto",
  },
}));

const LoanProducts = [
  "Personal Loan",
  "Gold Loan",
  "Vehicle Loan",
  "Business Loan",
];

const InvestmentProducts = [
  "Fixed Deposit",
  "Monthly Income Scheme",
  "Recurring Deposit",
  "Daily Deposit",
];

const Usecase = () => {
  const classes = useStyles();
  const [selectedTab, setSelectedTab] = useState("loan");
  const [selectedProduct, setSelectedProduct] = useState("");
  const [principal, setPrincipal] = useState(0);
  const [interest, setInterest] = useState(0);
  const [pieFirst, setPieFirst] = useState(0);
  const [pieSecond, setPieSecond] = useState("");

  useEffect(() => {
    if (selectedTab === "loan") {
      setSelectedProduct(LoanProducts[0]);
      setPieFirst("Principal Amount");
      setPieSecond("Interest Amount");
    } else if (selectedTab === "investment") {
      setSelectedProduct(InvestmentProducts[0]);
      setPieFirst("Principal Amount");
      setPieSecond("Interest Earned");
    }
  }, [selectedTab]);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleProductChange = (product) => {
    setSelectedProduct(product);
  };

  const handleCalculation = (principal, interest) => {
    setPrincipal(principal);
    setInterest(interest);
  };

  const renderProducts = (products) => {
    return products.map((product) => (
      <div key={product}>
        
        <ProductCard
          product={product}
          selectedProduct={selectedProduct}
          onClick={() => handleProductChange(product)}
        />
      </div>
    ));
  };

  return (
    <Box className={classes.root}>
      <div className={classes.CalculatorTabSection}>
        <p className={classes.title}>Calculators</p>

        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          TabIndicatorProps={{
            style: {
              backgroundColor: "transparent",
              fontSize: "14px"
            },
          }}
          // aria-label="calculator tabs"
          className={classes.tabs}
          variant="standard"
          scrollButtons="auto"
        >
          <Tab
            label="Deposit Calculator"
            value="investment"
            classes={{
              root: classes.tab,
              selected: selectedTab === "investment" ? classes.tabSelected : "",
            }}
            style={{
              borderRadius: "9999px",
              backgroundColor:
                selectedTab === "investment" ? "#F35425" : "#fff",
              color: selectedTab === "investment" ? "#fff" : "#F35425",
              minWidth: "150px", // Ensure tabs have minimum width
              margin: "0 5px", // Add margin for spacing
            }}
          />
          <Tab
            label="Loan Calculator"
            value="loan"
            classes={{
              root: classes.tab,
              selected: selectedTab === "loan" ? classes.tabSelected : "",
            }}
            style={{
              backgroundColor: selectedTab === "loan" ? "#F35425" : "#fff",
              color: selectedTab === "loan" ? "#fff" : "#F35425",
              borderRadius: "9999px",
              minWidth: "150px", // Ensure tabs have minimum width
              margin: "0 5px", // Add margin for spacing
            }}
          />
        </Tabs>
        <div className={classes.tabsButtonForMobileScreen}>

          <Button onClick={(e) => { handleTabChange(e,"investment") }} style={{
            borderRadius: "9999px",
            paddingInline:'8px',
            paddingBlock:'4px',
            backgroundColor:
              selectedTab === "investment" ? "#F35425" : "#fff",
            border:"2px solid #F35425",
            color: selectedTab === "investment" ? "#fff" : "#F35425",
            minWidth: "150px", // Ensure tabs have minimum width
            margin: "0 5px", // Add margin for spacing
          }}>Deposit Calculator</Button>
          <Button onClick={(e) => { handleTabChange(e,"loan") }} style={{
            borderRadius: "9999px",
            paddingInline:'8px',
            paddingBlock:'4px',
            border:"2px solid #F35425",
            backgroundColor:
              selectedTab === "loan" ? "#F35425" : "#fff",
            color: selectedTab === "loan" ? "#fff" : "#F35425",
            minWidth: "150px", // Ensure tabs have minimum width
            margin: "0 5px", // Add margin for spacing
          }}>Loan Calculator</Button>
        </div>
      </div>

      <div className={classes.usecase}>
        <Box className={classes.left}>
          <div className={classes.sliderContainer}>
            {selectedTab === "loan" && renderProducts(LoanProducts)}
            {selectedTab === "investment" && renderProducts(InvestmentProducts)}
          </div>
          <div style={{ width: '100%' }}>
            {InvestmentProducts.includes(selectedProduct) && (
              <Investment
                productType={selectedProduct}
                product={selectedProduct}
                onCalculate={handleCalculation}
              ></Investment>
            )}

            {LoanProducts.includes(selectedProduct) && (
              <LoanCalculator
                initialInterestRate={10}
                initialLoanAmount={1000000}
                initialLoanTerm={6}
                product={selectedProduct}
                productType={selectedProduct}
                onCalculate={handleCalculation}
              ></LoanCalculator>
            )}
          </div>
        </Box>
        <Box className={classes.right}>
          <PieChart
            pieFirst={pieFirst}
            pieSecond={pieSecond}
            principal={principal}
            interest={interest}
          />
        </Box>
      </div>
    </Box>
  );
};

export default Usecase;
