import React, { useEffect, useRef } from "react";
import { Card, Typography, makeStyles } from "@material-ui/core";
import gsap from "gsap";

const useStyles = makeStyles((theme) => ({
  card: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    alignItems: "center",
    padding: theme.spacing(1),
    backgroundColor: "#fff",
    border: "2px solid #F35425",
    textAlign: "center",
    color: "#0561AB",
    position: "relative",
    overflow: "hidden",
    cursor: "pointer",
    minWidth: 240, // Ensure consistent width
    margin: theme.spacing(1), // Add margin for spacing between cards
  },
  icon: {
    fontSize: "48px",
    marginBottom: theme.spacing(1),
  },
  productText: {
    fontFamily: "sans-serif", // Change font to "sans-serif
    fontSize: "16px",
    fontWeight: "bold",
  },
}));

const ProductCard = ({ selectedProduct, product, onClick, isActive }) => {
  const classes = useStyles();
  const cardRef = useRef(null);

  useEffect(() => {
    gsap.fromTo(cardRef.current, { opacity: 0 }, { opacity: 1, duration: 1 });
  }, []);

  return (
    <Card
      className={`${classes.card}`}
      ref={cardRef}
      style={{
        ...(selectedProduct === product && {
          backgroundColor: "#F35425",
          color: "#fff",
        }),
      }}
      onClick={onClick}
    >
      <p className={classes.productText}>{product}</p>
    </Card>
  );
};

export default ProductCard;
